import { Pipe, PipeTransform } from '@angular/core';
import { addMonths, isSameDay, isSameMonth } from 'date-fns';

@Pipe({
  standalone: true,
  name: 'isNextMonth'
})
export class IsNextMonthPipe implements PipeTransform {
  public transform(date: Date, compareDate: Date): boolean {
    return isSameMonth(addMonths(date, 1), compareDate) && !isSameDay(date, compareDate);
  }
}
