import * as R from 'rambdax';
import { OperatorFunction, map } from 'rxjs';
import { SportsGroup } from '../models/sports-group';

export const mapToDisciplines: OperatorFunction<SportsGroup[], SportsGroup[]> = map((sportsGroup: SportsGroup[]) =>
  sportsGroup.filter((sportGroup: SportsGroup) => sportGroup.parentId === null)
);

export const mapSportGroupSortByName: OperatorFunction<SportsGroup[], SportsGroup[]> = map(
  R.sort((a: SportsGroup, b: SportsGroup) => a.name.localeCompare(b.name))
);

function sortSportsByFavoriteIds(
  sportsGroups: SportsGroup[],
  favoriteSportsIds: Record<string, boolean>
): SportsGroup[] {
  const favoriteSportsIdsAsNumber: number[] = Object.keys(favoriteSportsIds).map(Number);
  const favoriteSports: SportsGroup[] = [];
  const restSports: SportsGroup[] = [];

  for (const sport of sportsGroups) {
    favoriteSportsIdsAsNumber.includes(sport.id) ? favoriteSports.push(sport) : restSports.push(sport);
  }
  return [...R.sortByPath('id', favoriteSports), ...restSports];
}

export const mapSportGroupSortByFavoriteIds: OperatorFunction<[SportsGroup[], Record<string, boolean>], SportsGroup[]> =
  map(([sports, favoriteSportsIds]: [SportsGroup[], Record<string, boolean>]) => {
    if (!R.isEmpty(favoriteSportsIds)) {
      return sortSportsByFavoriteIds(sports, favoriteSportsIds);
    }
    return sports;
  });

export const filterSportListByNameAndBrands: OperatorFunction<
  [SportsGroup[], string | undefined, number[], boolean],
  SportsGroup[]
> = map(
  ([sportsGroups, filterByName, disciplinesIdsWithUnassigned, filterByUnassignedBrands]: [
    SportsGroup[],
    string | undefined,
    number[],
    boolean
  ]) => {
    if (filterByName) {
      sportsGroups = sportsGroups.filter((sportGroup: SportsGroup) =>
        sportGroup.name.toLowerCase().includes(filterByName.toLowerCase())
      );
    }
    if (!filterByUnassignedBrands) {
      sportsGroups = sportsGroups.filter(
        (sportsGroup: SportsGroup) => !disciplinesIdsWithUnassigned.includes(sportsGroup.id)
      );
    }
    return sportsGroups;
  }
);
