import { Params } from '@angular/router';

export function parseArrayQueryParams<T>(arrayKeys: string[], params: Params, arrayAsUndefined: boolean = false): T {
  // when you choose only one query param, it wouldn't be an array but should be
  const copyParams: T = { ...params } as T;
  for (const key of arrayKeys) {
    if (params[key] && !Array.isArray(params[key])) {
      copyParams[key] = [params[key]];
    } else if (!params[key]) {
      copyParams[key] = arrayAsUndefined ? [] : undefined;
    }
  }
  return copyParams;
}

export function parseSportsGroupsIdsArrayParams<
  T extends {
    sportId: number | undefined;
    regionId: number | undefined;
    competitionId: number | undefined;
  }
>(params: Params, key: string = 'sportsGroupsIds'): T {
  const copyParams: T = { ...params } as T;
  if (Array.isArray(params[key])) {
    const [sportId, regionId, competitionId]: string | undefined[] = params[key];
    copyParams.sportId = sportId ? Number(sportId) : undefined;
    copyParams.regionId = regionId ? Number(regionId) : undefined;
    copyParams.competitionId = competitionId ? Number(competitionId) : undefined;
  } else {
    copyParams.sportId = params[key] ? Number(params[key]) : undefined;
    copyParams.regionId = undefined;
    copyParams.competitionId = undefined;
  }
  return copyParams;
}
