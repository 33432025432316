import { SportsGroup } from '@sap/logic/shared/models/sports-group';
import * as R from 'rambdax';

export function mapToObjDisciplinesRegionsAndLeagues(sportsGroups: SportsGroup[]): {
  disciplines: SportsGroup[];
  regions: SportsGroup[];
  leagues: SportsGroup[];
} {
  const reducedSportsGroups: {
    disciplines: SportsGroup[];
    notDisciplines: SportsGroup[];
  } = R.piped(
    sportsGroups,
    R.reduce(
      (acc: { disciplines: SportsGroup[]; notDisciplines: SportsGroup[] }, value: SportsGroup) => {
        !value.parentId ? acc.disciplines.push(value) : acc.notDisciplines.push(value);
        return acc;
      },
      { disciplines: [], notDisciplines: [] }
    )
  );
  const disciplinesIds: number[] = reducedSportsGroups.disciplines.map(R.prop('id'));
  const reducedSportGroupToRegionsAndLeagues: {
    regions: SportsGroup[];
    leagues: SportsGroup[];
  } = R.piped(
    reducedSportsGroups.notDisciplines,
    R.reduce(
      (acc: { regions: SportsGroup[]; leagues: SportsGroup[] }, value: SportsGroup) => {
        disciplinesIds.includes(value.parentId!) ? acc.regions.push(value) : acc.leagues.push(value);
        return acc;
      },
      { regions: [], leagues: [] }
    )
  );
  return {
    disciplines: reducedSportsGroups.disciplines,
    regions: reducedSportGroupToRegionsAndLeagues.regions,
    leagues: reducedSportGroupToRegionsAndLeagues.leagues
  };
}
