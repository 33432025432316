import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { mapAs } from '@sap/logic/shared/rxjs/operators';
import { Unsubscribable } from '@sap/shared/classes/unsubscribable';
import { debounceTime, filter, fromEvent, map, merge } from 'rxjs';

@Directive({
  selector: '[keyupTargetValue]',
  standalone: true
})
export class KeyupTargetValueDirective extends Unsubscribable implements AfterViewInit {
  @Input() debounceTime: number = 0;
  @Input() preventNegativeNumbers: boolean = false;
  @Output() keyupTargetValue: EventEmitter<string> = new EventEmitter<string>();

  constructor(private _elementRef: ElementRef<HTMLInputElement>) {
    super();
  }

  public ngAfterViewInit(): void {
    this._sub = merge(
      fromEvent(this._elementRef.nativeElement, 'keyup'),
      fromEvent(this._elementRef.nativeElement, 'paste')
    )
      .pipe(
        mapAs<KeyboardEvent>(),
        filter((event: KeyboardEvent) => !(event.key === ',')),
        debounceTime(this.debounceTime),
        map(() => this._elementRef.nativeElement.value),
        map((value: string) => (this.preventNegativeNumbers ? Math.abs(Number(value)).toString() : value))
      )
      .subscribe((value: string) => {
        if (value !== '0') {
          this._elementRef.nativeElement.value = value;
        }
        this.keyupTargetValue.emit(value);
      });
  }
}
