export class MatchInternalComment {
  constructor(
    public readonly createdAt: Date,
    public readonly author: string,
    public readonly message: string
  ) {}

  public static fromJson(json: Record<string, any>): MatchInternalComment {
    return new MatchInternalComment(new Date(json['date']), json['user'], json['message']);
  }

  public static fromJsonArray(jsons: Record<string, any>[]): MatchInternalComment[] {
    return jsons.map(MatchInternalComment.fromJson);
  }
}
