import { UserDatabaseSettings, UserTheme } from '@sap/logic/shared/models/user-database-settings';
import { Theme } from '@sap/ui/shared/services/set-theme.service';

const baseUrl: string = '/';

export function mapToSelectedThemeByUrl([settings, url]: [UserDatabaseSettings, string]): Theme {
  const modulePath: string = url.split(baseUrl)[1];
  return settings.uiTheme[modulePath] ?? settings.uiTheme[baseUrl] ?? Theme.dark;
}

export function mapToUpdatedUiTheme([theme, url, storedUserTheme]: [Theme, string, UserTheme]): UserTheme {
  if (url === baseUrl) {
    return { [baseUrl]: theme };
  }
  const modulePath: string = url.split(baseUrl)[1];
  return {
    ...storedUserTheme,
    [modulePath]: theme
  };
}
