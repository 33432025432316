import { betTracerIndexInstances } from '@sap/ui/pages/bet-tracer-page/bet-tracer-page-routing.module';
import { NavigationItem } from './left-navigation-drawer.component';
import { betTracerMain, maxOpenedTabsForBetTracer } from './navigation-items/bet-tracer';

export function countBetTracerTabs(navItems: NavigationItem[]): number {
  const betTracerTabs: number[] = navItems
    .filter((item: NavigationItem) => item.url?.startsWith(betTracerMain.url!) && item.url?.split('/').length <= 3)
    .map((item: NavigationItem) => Number(item.url?.split('/')[2] ?? 0));
  if (betTracerTabs.length >= maxOpenedTabsForBetTracer) {
    return betTracerTabs.at(-1)!;
  }
  for (const instance of [0, ...betTracerIndexInstances]) {
    if (betTracerTabs.indexOf(instance) === -1) {
      return instance;
    }
  }
  return 0;
}
