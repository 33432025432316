import { Injectable } from '@angular/core';
import { Filters } from '@sap/logic/api-access/api/bet-tracer-api';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

@Injectable()
export class BetTracerStreams {
  public isLoading$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public isLoadingBettingLocations$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public betsNotFound$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public filtersStateCached$: ReplaySubject<Filters> = new ReplaySubject<Filters>(1);
  public isLoadMoreAvailableFromApi$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public doLoadMoreBetsFromApi$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public doDisplayMoreBets$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public currentLoadMoreBetsOffset$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public currentDisplayedBets$: Subject<number> = new Subject();
  public viewInitiated$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public doResetLoadMore$: Subject<void> = new Subject<void>();
}
