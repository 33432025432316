import { EventEmitter } from '@angular/core';
import { Params } from '@angular/router';
import { BrandType } from '@sap/shared/enums';
import { getDateRangeString } from '@sap/ui/shared/components/date-range-picker/pure-utils/get-date-range-string';
import {
  parseArrayQueryParams,
  parseSportsGroupsIdsArrayParams
} from '@sap/ui/shared/helpers/impure-array-query-params-helper';
import { parseQueryParams } from '@sap/ui/shared/helpers/impure-query-params-helper';
import { parseISO } from 'date-fns';
import { OddsFormat } from './enums';
import { NgContextVarsTyped } from './template-var-typed.directive';

export function getBetsFilterFromQueryParams(
  params: Params,
  initialBrand: BrandType,
  doSelectOddFormat: EventEmitter<OddsFormat>
): NgContextVarsTyped {
  let copyParams: NgContextVarsTyped = parseArrayQueryParams(
    [
      'betType',
      'classification',
      'category',
      'betslipStatus',
      'betStatuses',
      'devices',
      'bonusTypes',
      'selectedBrands',
      'sportsIds'
    ],
    params,
    true
  ) as NgContextVarsTyped;

  copyParams.dateTo = params.dateTo ? parseISO(params.dateTo) : undefined;
  copyParams.dateFrom = params.dateFrom ? parseISO(params.dateFrom) : undefined;
  copyParams.dateRange = getDateRangeString([copyParams.dateFrom, copyParams.dateTo], true);

  copyParams.statusUpdatedDateTo = params.statusUpdatedDateTo ? parseISO(params.statusUpdatedDateTo) : undefined;
  copyParams.statusUpdatedDateFrom = params.statusUpdatedDateFrom ? parseISO(params.statusUpdatedDateFrom) : undefined;
  copyParams.statusUpdatedDateRange = getDateRangeString(
    [copyParams.statusUpdatedDateFrom, copyParams.statusUpdatedDateTo],
    true
  );

  copyParams = parseQueryParams(['matchId', 'marketId', 'selectedBrands'], copyParams);
  copyParams = parseSportsGroupsIdsArrayParams(copyParams);

  if (initialBrand && !copyParams.selectedBrands?.length) {
    copyParams.selectedBrands = [initialBrand];
  }
  if (copyParams.oddFormat) {
    doSelectOddFormat.next(copyParams.oddFormat as OddsFormat);
  }
  if (copyParams.sportsIds) {
    copyParams.sportsIds = copyParams.sportsIds.map(Number);
  }
  return copyParams;
}
