import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { ApiMessageSignal } from '@sap/logic/api-access/successes/api-message-success-signal';
import { NAVIGATOR } from '@sap/shared-platform/providers/navigator-provider';
import { Unsubscribable } from '@sap/shared/classes/unsubscribable';
import { fromEvent } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

export enum TextOverflowStrategy {
  'fillContainer',
  'none'
}

export type TextOverflowConfig = {
  strategy?: TextOverflowStrategy;
  copyable?: boolean;
};

@Component({
  selector: 'sap-text-overflow',
  standalone: true,
  template: `
    <div class="d-grid" [title]="isTooltipDisabled ? '' : tooltip ?? text">
      <div
        #textContainerRef
        class="u-text-ellipsis"
        [class.u-copyable]="config.copyable"
        (click)="config.copyable && copyToClipboard()"
      >
        <span #textSpanRef>
          {{ text }}
        </span>
        <ng-content></ng-content>
      </div>
    </div>
  `,
  imports: [CommonModule],
  styleUrls: ['text-overflow.component.scss']
})
export class TextOverflowComponent extends Unsubscribable implements AfterViewInit {
  public readonly defaultConfig: TextOverflowConfig = {
    strategy: TextOverflowStrategy.fillContainer,
    copyable: true
  };

  @ViewChild('textSpanRef')
  private _textSpanRef!: ElementRef;

  @ViewChild('textContainerRef')
  private _textContainerRef!: ElementRef;

  @Input()
  public set config(value: TextOverflowConfig) {
    this._config = { ...this.defaultConfig, ...value };
    this._wasChanged = true;
  }
  public get config(): TextOverflowConfig {
    return this._config;
  }
  @Input() toCopyOnCLick: string | number | undefined;
  @Input()
  public set text(input: string | number | undefined) {
    if (this._text === (input || '-')) {
      return;
    }
    this._text = input ? String(input) : '-';
    this._wasChanged = true;
    this._checkTooltipRequired();
  }
  public get text(): string {
    return this._text;
  }

  /**
   * Optional, use if you want a different tooltip than provided as text
   */
  @Input()
  public set tooltip(input: string | number | undefined) {
    if (this._tooltip === input) {
      return;
    }
    this._tooltip = input ? String(input) : undefined;
  }
  public get tooltip(): string | undefined {
    return this._tooltip;
  }

  private _tooltip: string | undefined;
  private _text: string = '';

  private _config: TextOverflowConfig = this.defaultConfig;
  private _wasChanged: boolean = false;

  public isTooltipDisabled: boolean = false;

  constructor(
    @Inject(NAVIGATOR) private _navigator: Navigator,
    private _element: ElementRef,
    private _toastMessage: ApiMessageSignal,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    this._checkTooltipRequired();
    this._listenToChange();
  }

  public copyToClipboard(): void {
    const copyToClipboardValue: string = String(this.toCopyOnCLick || this._text);
    this._navigator.clipboard.writeText(copyToClipboardValue);
    this._toastMessage.successMessage$.next({ status: 'Copied!', message: copyToClipboardValue });
  }

  private _checkTooltipRequired(): void {
    // Get correct offsetWidth property
    setTimeout(() => {
      if (
        !this._wasChanged ||
        (this.config.strategy === TextOverflowStrategy.fillContainer &&
          (!this._textSpanRef.nativeElement.offsetWidth || !this._textContainerRef.nativeElement.offsetWidth))
      ) {
        return;
      }
      if (this.config.strategy === TextOverflowStrategy.fillContainer) {
        this.isTooltipDisabled =
          this._textSpanRef.nativeElement.offsetWidth <= this._textContainerRef.nativeElement.offsetWidth;
      }
      this._wasChanged = false;
      this._changeDetectorRef.detectChanges();
    });
  }

  private _listenToChange(): void {
    this._sub = fromEvent<MouseEvent>(this._element.nativeElement, 'mouseenter')
      .pipe(throttleTime(500))
      .subscribe(() => this._checkAgainTooltipRequired());
  }

  private _checkAgainTooltipRequired(): void {
    this._wasChanged = true;
    this._checkTooltipRequired();
  }
}
