import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonComponent } from '@sap-design-system/components/button/button.component';
import { DsFormSubmitDirective } from '@sap-design-system/directives/ds-form-submit.directive';
import { InputSetValueDirective } from '@sap/ui/shared/directives/inputs/input-set-value.directive';
import { KeyupTargetValueDirective } from '@sap/ui/shared/directives/inputs/keyup-target-value.directive';
import { FxThemeCssModule } from '@sap/ui/shared/fx-theme-css/fx-theme-css.module';
import { DateObjFormatPipe } from '@sap/ui/shared/pipes/date-obj-format.pipe';
import { CommentCreateComponent } from './comment-create.component';
import { TemplateVarTypedDirective } from './template-var-typed.directive';

@NgModule({
  declarations: [CommentCreateComponent, TemplateVarTypedDirective],
  imports: [
    CommonModule,
    InputSetValueDirective,
    ButtonComponent,
    FxThemeCssModule,
    DateObjFormatPipe,
    DsFormSubmitDirective,
    KeyupTargetValueDirective
  ],
  exports: [CommentCreateComponent]
})
export class CommentCreateModule {}
