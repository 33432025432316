import { Pipe, PipeTransform } from '@angular/core';
import { MultiSelectOption } from '@sap/logic/lower-tax/bloc/lower-tax/types';

@Pipe({ name: 'enumToSelectOptions', standalone: true })
export class EnumToOArrayWithObjectPipe implements PipeTransform {
  public transform<T extends object>(enumeration: T): MultiSelectOption[] {
    const toReturn: MultiSelectOption[] = [];
    for (const elKey of Object.keys(enumeration)) {
      toReturn.push({ id: elKey, name: enumeration[elKey as keyof T] as string });
    }
    return toReturn;
  }
}
