import { BrandInfo } from '@sap/logic/shared/models/brand-info';
import { BrandType } from '@sap/shared/enums';
import * as R from 'rambdax';
import { mappedBrandToApiEnv } from './mapped-brand-to-api-env';

export function mapToFilteredToStage(brandsInfo: BrandInfo[]): BrandInfo[] {
  return R.piped(
    brandsInfo,
    R.filter((brandInfo: BrandInfo) => R.keys(mappedBrandToApiEnv).includes(brandInfo.name))
  );
}

export function mapToBrandsFilteredToStage(brands: BrandType[]): BrandType[] {
  return R.piped(
    brands,
    R.filter((brand: BrandType) => R.keys(mappedBrandToApiEnv).includes(brand))
  );
}

export function mapToAddMappedBrandNameToApiUrl(
  apiUrl: string | undefined,
  brand: BrandType | undefined
): string | undefined {
  return apiUrl?.replace('{brandName}', mappedBrandToApiEnv[brand!]);
}

export function mapToAddBrandNameToApiUrl(
  apiUrl: string | undefined,
  brand: BrandType | undefined
): string | undefined {
  return apiUrl?.replace('{brandName}', brand!);
}

export function getApiUrlBrandByEnv(
  isProduction: boolean,
  envApiUrl: string,
  brandType: BrandType
): string | undefined {
  return isProduction
    ? mapToAddBrandNameToApiUrl(envApiUrl, brandType)
    : mapToAddMappedBrandNameToApiUrl(envApiUrl, brandType);
}
