import { WithRawJson } from '../interfaces';
import { isDefined } from '../utils/pure-utils';

enum BonusInfoTypes {
  multiplier,
  rateBoost,
  lowerTax
}

export class BonusInfo implements WithRawJson {
  constructor(
    public readonly code: string | undefined,
    public readonly category: string | undefined,
    public readonly winMultiplier: number | undefined,
    public readonly rateBoostValue: number | undefined,
    public readonly lowerTaxValue: number | undefined,
    public readonly type: string,
    public readonly hasBetMode: boolean,
    public readonly rawJson: Record<string, any>
  ) {}

  public static fromJson(json: Record<string, any>): BonusInfo {
    const bonuses: (number | undefined)[] = [
      json['bonus_win_multiplier'] ?? undefined,
      json['rate_boost'] ? json['rate_boost']['value'] : undefined,
      json['custom_tax'] ? json['custom_tax']['value'] : undefined
    ];
    const type: string = BonusInfoTypes[bonuses.findIndex(isDefined)];
    return new BonusInfo(
      json['bonus_code'] ?? undefined,
      json['category'] ?? undefined,
      bonuses[0],
      bonuses[1],
      bonuses[2],
      type,
      isDefined(json['bet_mode']),
      json
    );
  }
}
