import { format } from 'date-fns';

export function getDateRangeTextareaString(dates: (Date | undefined)[], enableTimeMode: boolean): string {
  if (dates[0] && dates[1]) {
    return `${format(dates[0], enableTimeMode ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd')}\n${format(
      dates[1],
      enableTimeMode ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'
    )}`;
  } else if (dates[0]) {
    return `${format(dates[0], enableTimeMode ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd')}`;
  }
  return '';
}
