import { Pipe, PipeTransform } from '@angular/core';
import { ModuleScopes } from '@sap/logic/shared/types/permissions';
import * as R from 'rambdax';

export function resolvePath(path: string | string[], obj: ModuleScopes<boolean>, separator: string = '.'): boolean {
  const properties: string[] = Array.isArray(path) ? path : path.split(separator);
  return !!R.path(properties, obj);
}

@Pipe({ name: 'permissionCheck', standalone: true })
export class PermissionCheckPipe implements PipeTransform {
  public transform(scopePath: string | undefined, permissions: ModuleScopes<boolean>): boolean {
    return scopePath && permissions ? resolvePath(scopePath, permissions) : true;
  }
}
