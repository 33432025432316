import { Directive, HostListener, Inject, Input } from '@angular/core';
import { ApiMessageSignal } from '@sap/logic/api-access/successes/api-message-success-signal';
import { NAVIGATOR } from '@sap/shared-platform/providers/navigator-provider';

@Directive({
  selector: '[copyToClipboard]',
  standalone: true
})
export class CopyToClipboardDirective {
  @Input('copyToClipboard') copyToClipboardValue: string | number | undefined;

  constructor(
    @Inject(NAVIGATOR) private _navigator: Navigator,
    private _toastMessage: ApiMessageSignal
  ) {}

  @HostListener('click')
  public click(): void {
    if (this.copyToClipboardValue) {
      this._navigator.clipboard.writeText(this.copyToClipboardValue.toString());
      this._toastMessage.successMessage$.next({ status: 'Copied!', message: this.copyToClipboardValue.toString() });
    }
  }
}
