import { AfterViewInit, Directive, ElementRef, Inject, Renderer2 } from '@angular/core';
import { WINDOW } from '@sap/shared-platform/providers/window-provider';

@Directive({
  selector: '[renderSelectDropdownInViewPort]'
})
export class RenderVisibleDirective implements AfterViewInit {
  private _domElement: HTMLElement | undefined = undefined;
  constructor(
    @Inject(WINDOW) private _window: Window,
    private _elementRef: ElementRef<HTMLElement>,
    private _renderer: Renderer2
  ) {}

  public ngAfterViewInit(): void {
    setTimeout(() => this._updateStyles(), 50); // wait for lazyOptions ready
  }

  private _updateStyles(): void {
    this._domElement = this._elementRef.nativeElement;
    const elementClientRect: DOMRect = this._domElement.getBoundingClientRect();
    const canBeRenderVerticallyInViewport: boolean =
      elementClientRect.bottom <= this._window.scrollY + this._window.innerHeight;
    const stylesForVerticalPosition: Record<string, string> = {
      opacity: '1',
      top: canBeRenderVerticallyInViewport ? '100%' : 'auto',
      bottom: canBeRenderVerticallyInViewport ? 'auto' : '100%'
    };

    for (const style of Object.keys(stylesForVerticalPosition)) {
      this._renderer.setStyle(this._domElement, style, stylesForVerticalPosition[style]);
    }
  }
}
