import { SportsGroup } from '@sap/logic/shared/models/sports-group';
import * as R from 'rambdax';

const PredefinedPopularSports: string[] = [
  'Football',
  'Basketball',
  'Volleyball',
  'Tennis',
  'Counter-Strike: GO (CS:GO)',
  'Ice Hockey',
  'American Football',
  'Baseball'
];

export function mapToPopularSports(sportsGroups: SportsGroup[]): SportsGroup[] {
  return R.piped(
    sportsGroups,
    R.filter((sport: SportsGroup) => R.any(R.equals(sport.name), PredefinedPopularSports)),
    R.sort(
      (a: SportsGroup, b: SportsGroup) =>
        PredefinedPopularSports.indexOf(a.name) - PredefinedPopularSports.indexOf(b.name)
    )
  );
}
