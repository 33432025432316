import { Directive, ElementRef, Input } from '@angular/core';
import { manageableBetTracerColumnsIndexes } from '../../components/bet-tracer-table-tool/bet-tracer-table-tool.component';
import { BetTraceColumNames } from '../../components/bet-tracer-table-tool/enum';

@Directive({
  selector: '[hiddenColumns]'
})
export class HiddenColumnsDirective {
  private _hiddenColumns: BetTraceColumNames[] = [];
  private _displayedColumns: BetTraceColumNames[] = [];

  @Input() set hiddenColumns(displayedColumns: BetTraceColumNames[]) {
    if (displayedColumns.length) {
      this._displayedColumns = displayedColumns;
      this._hiddenColumns = manageableBetTracerColumnsIndexes.filter(
        (index: BetTraceColumNames) => !displayedColumns.includes(index)
      );
      this._setVisibilityColumns();
    }
  }

  constructor(private _elementRef: ElementRef<HTMLElement>) {}

  private _setVisibilityColumns(): void {
    const allColumns: HTMLCollection = this._elementRef.nativeElement.children;

    for (const hiddenColumn of this._hiddenColumns) {
      allColumns[hiddenColumn].setAttribute('hidden', 'true');
    }

    for (const displayedColumn of this._displayedColumns) {
      allColumns[displayedColumn].removeAttribute('hidden');
    }
  }
}
