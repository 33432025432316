import { Observable, ObservableInputTuple, OperatorFunction, Subscriber } from 'rxjs';
import { innerFrom } from 'rxjs/internal/observable/innerFrom';
import { createOperatorSubscriber } from 'rxjs/internal/operators/OperatorSubscriber';
import { popResultSelector } from 'rxjs/internal/util/args';
import { identity } from 'rxjs/internal/util/identity';
import { operate } from 'rxjs/internal/util/lift';
import { noop } from 'rxjs/internal/util/noop';

export function withLatestFromWaitable<T, O extends unknown[]>(
  ...inputs: [...ObservableInputTuple<O>]
): OperatorFunction<T, [T, ...O]>;

export function withLatestFromWaitable<T, O extends unknown[], R>(
  ...inputs: [...ObservableInputTuple<O>, (...value: [T, ...O]) => R]
): OperatorFunction<T, R>;

/**
 * DESCRIPTION BELOW IS FROM BCF:
 * fork of default withLatestFromOperator, in default version the observables inside the operator must first emit something at least once
 * if not, the parent source observable will not emit.
 * In this version, we wait for each observables emits at least once, then we run function sourceSubscribe() which will proceed as default operator.
 *
 * problem example:
 *   public getTableGamesCasinoGamesData() {
      return this.getAllCasinoGamesData().pipe(
        withLatestFromWaitable(this._walletRepository.getCasinoTablesGamesIds())
      );
    }

    in above example:
    1. you go to the view which use this method, and subsribe on int
    2. the this.getAllCasinoGamesData() will emit first
    3. this._walletRepository.getCasinoTablesGamesIds() will emit later because its http call
    4. in default operator we first run source.subscribe() which will check ready variable to be true, but its not. so it will not emit.
 */

export function withLatestFromWaitable<T, R>(...inputs: any[]): OperatorFunction<T, R | any[]> {
  const project: ((...args: any[]) => R) | undefined = popResultSelector(inputs) as ((...args: any[]) => R) | undefined;

  return operate((source: Observable<T>, subscriber: Subscriber<any[] | R>) => {
    const len: number = inputs.length;
    const otherValues: any[] = new Array(len);
    let hasValue: boolean[] = inputs.map(() => false);
    let ready: boolean = false;
    let sourceSubscribeRun: boolean = false;

    function sourceSubscribe(): void {
      source.subscribe(
        createOperatorSubscriber(subscriber, (value: T) => {
          const values: any[] = [value, ...otherValues];
          subscriber.next(project ? project(...values) : values);
        })
      );
    }

    for (let i: number = 0; i < len; i++) {
      innerFrom(inputs[i]).subscribe(
        createOperatorSubscriber(
          subscriber,
          (value: unknown) => {
            otherValues[i] = value;
            if (!ready && !hasValue[i]) {
              hasValue[i] = true;
              (ready = hasValue.every(identity)) && (hasValue = null!);
            }
            /**
             * this is changed compared to default withLatestFrom operator
             */
            if (!sourceSubscribeRun && ready) {
              sourceSubscribe();
              sourceSubscribeRun = true;
            }
          },
          noop
        )
      );
    }
  });
}
