<ng-container *templateVarTyped="let vars = vars; let update = updateVar">
  <div
    class="datepicker"
    (clickOutside)="doClose.emit(undefined)"
    [class.datepicker-on-modal]="onModal"
    [componentsTagRef]="['sap-date-range-picker-panel']"
  >
    <!--    quick menu-->
    <div *ngIf="!hideQuickOptions" class="datepicker-quick" dragableHorizontalScroll>
      <ng-container *ngIf="enableTimeMode">
        <span
          class="datepicker-quick__option"
          [class.is-active]="vars.selectedQuickOption === 'now'"
          (clickDetached)="doQuickOption$.next('now'); update('selectedQuickOption', 'now')"
          >Now</span
        >
        <span
          class="datepicker-quick__option"
          [class.is-active]="vars.selectedQuickOption === 'last1Hour'"
          (clickDetached)="doQuickOption$.next('last1Hour'); update('selectedQuickOption', 'last1Hour')"
          >Last 1 Hour</span
        >
        <span
          class="datepicker-quick__option"
          [class.is-active]="vars.selectedQuickOption === 'last3Hours'"
          (clickDetached)="doQuickOption$.next('last3Hours'); update('selectedQuickOption', 'last3Hours')"
          >Last 3 Hours</span
        ></ng-container
      ><span
        class="datepicker-quick__option"
        [class.is-active]="vars.selectedQuickOption === 'today'"
        (clickDetached)="doQuickOption$.next('today'); update('selectedQuickOption', 'today')"
        >Today</span
      >
      <span
        class="datepicker-quick__option"
        [class.is-active]="vars.selectedQuickOption === 'yesterday'"
        (clickDetached)="doQuickOption$.next('yesterday'); update('selectedQuickOption', 'yesterday')"
        >Yesterday</span
      >
      <span
        class="datepicker-quick__option"
        [class.is-active]="vars.selectedQuickOption === 'thisWeek'"
        (clickDetached)="doQuickOption$.next('thisWeek'); update('selectedQuickOption', 'thisWeek')"
        >This Week</span
      >
      <span
        class="datepicker-quick__option"
        [class.is-active]="vars.selectedQuickOption === 'thisMonth'"
        (clickDetached)="doQuickOption$.next('thisMonth'); update('selectedQuickOption', 'thisMonth')"
        >This Month</span
      >
    </div>

    <div class="datepicker-panels">
      <!--      first panel-->
      <sap-date-range-picker-panel
        type="first"
        [dateFrom]="dateFrom"
        [dateTo]="dateTo"
        (didUpdateDateRange)="updateDateRange$.next($event); update('selectedQuickOption', undefined)"
      ></sap-date-range-picker-panel>

      <!--    second panel-->
      <sap-date-range-picker-panel
        type="second"
        [dateFrom]="dateFrom"
        [dateTo]="dateTo"
        (didUpdateDateRange)="updateDateRange$.next($event); update('selectedQuickOption', undefined)"
      ></sap-date-range-picker-panel>
    </div>

    <!--    line-->
    <div class="datepicker-line"></div>

    <!--      time-->
    <ng-container *ngIf="enableTimeMode">
      <div class="datepicker-time">
        <div class="datepicker-time-panel">
          <div class="datepicker-time-panel-header">FROM</div>
          <div>{{ dateFrom | dateObjFormat: 'yyyy-MM-dd' }}</div>
          <div class="datepicker-time-panel-hours">
            <div class="datepicker-time-panel-hours-input">
              <input
                type="number"
                min="0"
                max="23"
                (keyupTargetNumericValue)="update('hoursFrom', $event)"
                [setValue]="vars.hoursFrom ?? (dateFrom | getHours)"
              />
              <span class="label">hour</span>
            </div>
            <span class="datepicker-time-panel-hours-delimiter">:</span>
            <div class="datepicker-time-panel-hours-input">
              <input
                type="number"
                min="0"
                max="59"
                (keyupTargetNumericValue)="update('minutesFrom', $event)"
                [setValue]="vars.minutesFrom ?? (dateFrom | getMinutes)"
              />
              <span class="label">minute</span>
            </div>
          </div>
        </div>
        <div *ngIf="dateTo" class="datepicker-time-panel">
          <div class="datepicker-time-panel-header">TO</div>
          <div>{{ dateTo | dateObjFormat: 'yyyy-MM-dd' }}</div>
          <div class="datepicker-time-panel-hours">
            <div class="datepicker-time-panel-hours-input">
              <input
                type="number"
                min="0"
                max="23"
                (keyupTargetNumericValue)="update('hoursTo', $event)"
                [setValue]="dateTo | getHours"
              />
              <span class="label">hour</span>
            </div>
            <span class="datepicker-time-panel-hours-delimiter">:</span>
            <div class="datepicker-time-panel-hours-input">
              <input
                type="number"
                min="0"
                max="59"
                (keyupTargetNumericValue)="update('minutesTo', $event)"
                [setValue]="dateTo | getMinutes"
              />
              <span class="label">minute</span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!--    bottom buttons-->
    <div class="datepicker-buttons">
      <button
        type="button"
        [class.u-disabled]="!(dateFrom | isDefined)"
        class="datepicker-btn-choose u-clickable"
        (clickDetached)="
          setHours(vars.hoursFrom!, vars.hoursTo!);
          setMinutes(vars.minutesFrom!, vars.minutesTo!);
          didSelectDateRange.emit([dateFrom, dateTo])
        "
      >
        <ng-container *ngIf="dateTo | isDefined">select range</ng-container>
        <ng-container *ngIf="!(dateTo | isDefined)">select date</ng-container>
      </button>
      <button
        type="button"
        class="datepicker-btn-choose u-clickable"
        (clickDetached)="didSelectDateRange.emit([undefined, undefined])"
      >
        clear
      </button>
    </div>
  </div>
</ng-container>
