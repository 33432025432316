import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { isDefined } from '@sap/logic/shared/utils/pure-utils';
import {
  addMonths,
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfISOWeek,
  endOfMonth,
  setHours,
  setMinutes,
  startOfISOWeek,
  startOfMonth,
  subMonths,
  subYears
} from 'date-fns';
import * as R from 'rambdax';

@Component({
  selector: 'sap-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class DatePickerComponent implements OnInit, OnDestroy {
  @Input() enableTimeMode: boolean = false;
  @Input() showClear: boolean = false;
  @Input() showFromLabel: boolean = true;
  @Input() disabledBelow!: Date | undefined;
  @Input() disabledAbove!: Date | undefined;
  @Input() hideQuickOptions: boolean = true;
  @Input() forceFiltersCss: boolean = false;
  @Input() disableBeforeDate: Date | undefined;
  @Input() disableAfterDate: Date | undefined;
  @Input() onModal: boolean = false;

  private _date!: Date;
  @Input() set date(date: Date | undefined) {
    this._date = date ?? new Date();
  }
  get date(): Date {
    return this._date ?? new Date();
  }

  @Output() didSelectDate: EventEmitter<Date | undefined> = new EventEmitter<Date | undefined>();
  @Output() doClose: EventEmitter<void> = new EventEmitter<void>();

  public get calendarMatrix(): Date[][] {
    return eachWeekOfInterval(
      {
        start: startOfMonth(this.date),
        end: endOfMonth(this.date)
      },
      { weekStartsOn: 1 }
    ).map((weekDay: Date) =>
      eachDayOfInterval({
        start: startOfISOWeek(weekDay),
        end: endOfISOWeek(weekDay)
      })
    );
  }

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _elementRef: ElementRef,
    private _renderer2: Renderer2
  ) {}

  public ngOnInit(): void {
    if (this.forceFiltersCss) {
      this._renderer2.addClass(this._document.body, 'datepicker-opened');
    }
  }

  public get yearMatrix(): any {
    return R.piped(
      R.range(0, 35),
      R.mapArray((year: number) => subYears(this.date, year)),
      R.reverse as any,
      R.reduce((acc: Date[][], current: Date, index: number | undefined) => {
        const chunkIndex: number = Math.floor(index! / 7);
        if (!acc[chunkIndex]) {
          acc[chunkIndex] = [];
        }
        acc[chunkIndex] = R.append(current, acc[chunkIndex]);
        return acc;
      }, [])
    );
  }

  public isRangeConflict(date: Date): boolean {
    const dateWithoutTime: Date | undefined = this._getDateWithoutTime(date);
    const disabledBelowWithoutTime: Date | undefined = this._getDateWithoutTime(this.disabledBelow);
    const disabledAboveWithoutTime: Date | undefined = this._getDateWithoutTime(this.disabledAbove);
    return (
      Boolean(disabledBelowWithoutTime && dateWithoutTime && dateWithoutTime < disabledBelowWithoutTime) ||
      Boolean(disabledAboveWithoutTime && dateWithoutTime && dateWithoutTime > disabledAboveWithoutTime)
    );
  }

  private _getDateWithoutTime(date: Date | undefined): Date | undefined {
    const dateNoTime: Date | undefined = date ? new Date(date) : undefined;
    if (dateNoTime) {
      dateNoTime.setHours(0);
      dateNoTime.setMinutes(0);
      dateNoTime.setSeconds(0);
      dateNoTime.setMilliseconds(0);
    }
    return dateNoTime;
  }

  public switchToNextMonth(): void {
    this.date = addMonths(this.date, 1);
  }

  public switchToPrevMonth(): void {
    this.date = subMonths(this.date, 1);
  }

  public setMinutes(minutes: number | undefined): void {
    if (!isDefined(minutes)) {
      return;
    }
    this.date = setMinutes(this.date, minutes);
  }

  public setHours(hours: number | undefined): void {
    if (!isDefined(hours)) {
      return;
    }
    this.date = setHours(this.date, hours);
  }

  public trackByIndex(index: number): number {
    return index;
  }

  public trackByDay(index: number, day: Date): number {
    return day.getTime();
  }

  public ngOnDestroy(): void {
    this._renderer2.removeClass(this._document.body, 'datepicker-opened');
  }
}
