import { Pipe, PipeTransform } from '@angular/core';
import { BetWithSelections } from '@sap/logic/shared/models/bet';
import { isDefined } from '@sap/logic/shared/utils/pure-utils';

@Pipe({ name: 'betSportGroupsDataToDisplay' })
export class BetSportGroupsDataToDisplayPipe implements PipeTransform {
  public transform(
    bet: BetWithSelections | undefined
  ): { tooltip: string; discipline: string; region: string; competition: string } | undefined {
    if (!bet) {
      return undefined;
    }
    return {
      tooltip: bet.sportGroups.filter(isDefined).join(' / '),
      discipline: bet.sportGroups[0],
      region: bet.sportGroups[1],
      competition: bet.sportGroups[2]
    };
  }
}
