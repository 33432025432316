import { Directive, EventEmitter, Input, Output } from '@angular/core';
import * as R from 'rambdax';

@Directive({
  selector: '[doOnValueChange]',
  standalone: true
})
export class DoOnValueChangeDirective<T> {
  private _value: T | undefined;
  @Input() skipInitialEmit: boolean = false;

  @Input() set doOnValueChange(value: T | undefined) {
    if (this.skipInitialEmit && this._value === undefined) {
      this._value = value;
      return;
    }
    if (!R.equals(value, this._value)) {
      this.didValueChanged.emit(undefined);
      this._value = value;
    }
  }
  @Output() didValueChanged: EventEmitter<void> = new EventEmitter<void>();
}
