import { BrandInfo, Country } from '@sap/logic/shared/models/brand-info';
import * as R from 'rambdax';

export function mapToAllLangs(brandsInfo: BrandInfo[]): string[] {
  return R.piped(
    brandsInfo,
    R.reduce((acc: string[], current: BrandInfo) => {
      acc = acc.concat(current.langs);
      return acc;
    }, [])
  );
}

export function mapToGetSelectedCountriesByCodes([selectedCountryCodes, allCountries]: [string[], Country[]]):
  | Country[]
  | undefined {
  return selectedCountryCodes.map((code: string) => allCountries.find(R.propEq('code', code))!);
}
