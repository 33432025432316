export enum FiltersKeys {
  limit = 'limit',
  offset = 'id_to',
  hasEarlyCashout = 'has_early_cashout',
  manualOdds = 'manual_odd',
  betType = 'betslip_types',
  betslipStatus = 'betslip_statuses',
  couponId = 'betslip_id',
  betStatuses = 'bet_statuses',
  classification = 'classification',
  category = 'category',
  forceStatus = 'force_status',
  dateFrom = 'date_from',
  dateTo = 'date_to',
  statusUpdatedDateFrom = 'status_updated_from',
  statusUpdatedDateTo = 'status_updated_to',
  isTest = 'is_test',
  betId = 'bet_id',
  clientId = 'client_id',
  clientLogin = 'client_login',
  couponRisk = 'coupon_risk',
  marketId = 'market_id',
  matchId = 'match_id',
  quickWonBetTime = 'quick_bet',
  payout = 'payout',
  reachedRisk = 'risk_reached',
  isLive = 'is_live',
  totalStake = 'stake',
  totalStakeCurrency = 'stake_currency',
  betSelectionStatus = 'bet_item_status',
  devices = 'device',
  bonusTypes = 'bonus_types',
  bonusInfo = 'bonus_info',
  locationId = 'location_id',
  sportsGroupsIds = 'sports_groups_ids'
}

export enum FiltersValues {
  gold = 'big_loser',
  red = 'dangerous',
  superVip = 'super_vip',
  reviewUp = 'review_up',
  reviewDown = 'review_down',
  toCheck = 'to_check',
  bonusAbuser = 'bonus_abuser',
  retailShop = 'retail_shop',
  // force status
  forceHalfLost = 'force_half_lost',
  forceHalfWon = 'force_half_won',
  forceLost = 'force_lost',
  forceOpen = 'force_open',
  forceReturn = 'force_return',
  forceWon = 'force_won',
  // Bet Status
  'half won' = 'half_won',
  'half lost' = 'half_lost',
  // Devices
  'POS (Point of Sell)' = 'pos',
  'PWA' = 'pwa',
  'android (mobile-web)' = 'android_mobile_web',
  'android (native)' = 'android_native',
  'android (PWA)' = 'android_pwa',
  'ios (mobile-web)' = 'ios_mobile_web',
  'ios (native)' = 'ios_native',
  'ios (PWA)' = 'ios_pwa',
  'mobile-app' = 'mobile_app',
  'mobile-web' = 'mobile_web',
  'web (PWA)' = 'web_pwa',
  'web (website)' = 'web_website',
  // Bonus
  bonusMoney = 'bonus_money',
  lowerTax = 'lower_tax',
  rateBoost = 'rate_boost'
}
