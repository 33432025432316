import { SportsGroup } from '@sap/logic/shared/models/sports-group';
import * as R from 'rambdax';

export function mapToObjDisciplinesWithChildren(reducedSportsGroups: {
  disciplines: SportsGroup[];
  regions: SportsGroup[];
  leagues: SportsGroup[];
}): Record<number, SportsGroup[]> {
  const regionsToDisciplines: Record<number, SportsGroup[]> = mapToIndexedRegionsByDisciplineId(
    reducedSportsGroups.regions
  );
  const leaguesToRegions: Record<number, SportsGroup[]> = mapToIndexedLeaguesByRegionId(reducedSportsGroups.leagues);
  const disciplinesWithChildren: Record<number, SportsGroup[]> = {};
  const disciplinesIds: number[] = reducedSportsGroups.disciplines.map(R.prop('id'));

  for (const disciplineId of disciplinesIds) {
    const regionsIds: number[] | undefined = regionsToDisciplines[disciplineId]
      ? regionsToDisciplines[disciplineId].map(R.prop('id'))
      : undefined;
    if (regionsIds) {
      disciplinesWithChildren[disciplineId]
        ? disciplinesWithChildren[disciplineId].concat(regionsToDisciplines[disciplineId])
        : (disciplinesWithChildren[disciplineId] = [...(regionsToDisciplines[disciplineId] ?? [])]);

      for (const regionId of regionsIds) {
        if (leaguesToRegions[regionId]) {
          disciplinesWithChildren[disciplineId].push(...leaguesToRegions[regionId]);
        }
      }
    }
  }
  return disciplinesWithChildren;
}

export function mapToIndexedRegionsByDisciplineId(regions: SportsGroup[]): Record<number, SportsGroup[]> {
  const regionsToDisciplines: Record<number, SportsGroup[]> = {};
  for (const region of regions) {
    regionsToDisciplines[region.parentId!]
      ? regionsToDisciplines[region.parentId!].push(region)
      : (regionsToDisciplines[region.parentId!] = [region]);
  }
  return regionsToDisciplines;
}

export function mapToIndexedLeaguesByRegionId(leagues: SportsGroup[]): Record<number, SportsGroup[]> {
  const leaguesToRegions: Record<number, SportsGroup[]> = {};
  for (const league of leagues) {
    leaguesToRegions[league.parentId!]
      ? leaguesToRegions[league.parentId!].push(league)
      : (leaguesToRegions[league.parentId!] = [league]);
  }
  return leaguesToRegions;
}
