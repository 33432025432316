import { of, OperatorFunction } from 'rxjs';
import { catchError } from 'rxjs/operators';

export function predicateNotFoundBetsError<T>(result: T | BetsNotFoundError): boolean {
  return !(result instanceof BetsNotFoundError);
}

export class BetsNotFoundError {
  public message: string = 'bets not found';

  public static fromHttpError<T>(): OperatorFunction<T, BetsNotFoundError | T> {
    return catchError(() => of(new BetsNotFoundError()));
  }
}
