import {
  Directive,
  ElementRef,
  EmbeddedViewRef,
  Inject,
  Input,
  Renderer2,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { mapAs } from '@sap/logic/shared/rxjs/operators';
import { isDefined } from '@sap/logic/shared/utils/pure-utils';
import { WINDOW } from '@sap/shared-platform/providers/window-provider';
import { Unsubscribable } from '@sap/shared/classes/unsubscribable';
import { fromEvent } from 'rxjs';

@Directive({
  selector: '[tooltip]',
  standalone: true
})
// To work properly add '@import 'scss/components/tooltip';' in your scss file.
export class TooltipDirective extends Unsubscribable {
  private _isShow: boolean = false;
  private _tooltip: HTMLElement | undefined;
  private _tooltipContent: HTMLElement | undefined;
  private _templateRef: TemplateRef<any> | undefined;
  private _tooltipText: string | undefined;
  public offset: number = 10;

  @Input() set tooltip(value: string | TemplateRef<any> | undefined) {
    if (value instanceof TemplateRef) {
      this._templateRef = value;
      this.createTooltip();
    } else if (isDefined(value)) {
      this._templateRef = undefined;
      this._tooltipText = value;
      this.createTooltip();
    }
  }

  constructor(
    private _viewContainerRef: ViewContainerRef,
    private _elementRef: ElementRef<HTMLElement>,
    private _renderer: Renderer2,
    @Inject(WINDOW) private _window: Window
  ) {
    super();
  }

  public createTooltip(): void {
    this._tooltip = this._renderer.createElement('span');
    this._tooltipContent = this._renderer.createElement('span');
    this._renderer.addClass(this._tooltip, 'tooltip-wrapper');
    this._renderer.addClass(this._tooltipContent, 'tooltip-content');

    if (this._templateRef) {
      const viewRef: EmbeddedViewRef<any> = this._viewContainerRef.createEmbeddedView(this._templateRef!);
      for (const node of viewRef.rootNodes) {
        this._renderer.appendChild(this._tooltipContent, node);
      }
    } else if (this._tooltipText) {
      this._renderer.appendChild(this._tooltipContent, this._renderer.createText(this._tooltipText));
    }

    this._sub = fromEvent(this._elementRef.nativeElement, 'mouseenter')
      .pipe(mapAs<MouseEvent>())
      .subscribe((event: MouseEvent) => this._show(event));
    this._sub = fromEvent(this._elementRef.nativeElement, 'mouseleave').subscribe(() => this._hide());
  }

  private _show(event: MouseEvent): void {
    if (!this._tooltip || this._isShow) return;
    this._renderer.appendChild(this._tooltip, this._tooltipContent);
    this._renderer.appendChild(this._elementRef.nativeElement, this._tooltip);
    this._updateStyles(event);
    this._isShow = true;
  }

  private _hide(): void {
    if (this._tooltip && this._isShow) {
      this._renderer.removeChild(this._elementRef.nativeElement, this._tooltip);
      this._isShow = false;
    }
  }

  private _updateStyles(event: MouseEvent): void {
    if (!this._tooltip) return;
    const stylesForVerticalPosition: Record<string, string> = {
      opacity: '1',
      top:
        event.pageY + this._tooltip.clientHeight < this._window.innerHeight
          ? event.pageY + 'px'
          : this._window.innerHeight - this._tooltip.clientHeight + 'px',
      left:
        event.pageX + this._tooltip.clientWidth < this._window.innerWidth
          ? event.pageX + 'px'
          : this._window.innerWidth - this._tooltip.clientWidth + 'px'
    };

    for (const style of Object.keys(stylesForVerticalPosition)) {
      this._renderer.setStyle(this._tooltip, style, stylesForVerticalPosition[style]);
    }
  }
}
