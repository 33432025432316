import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { IndexedBetComments, IndexedMatchComments } from '@sap/logic/firestore-database/types';
import { BetType } from '@sap/logic/shared/models/bet';
import { Betslip } from '@sap/logic/shared/models/betslip';
import { BrandType } from '@sap/shared/enums';
import { TextOverflowConfig } from '@sap/ui/shared/components/text-overflow/text-overflow.component';
import { NgForTracker } from '@sap/ui/shared/ng-for-tracker';
import { Observable } from 'rxjs';
import { BetTraceColumNames } from '../../components/bet-tracer-table-tool/enum';
import { OddsFormat } from '../bet-tracer-top-filters/enums';
import { TemplateVarTypedDirective } from './template-var-typed.directive';

@Component({
  selector: 'sap-bet-tracer-main-data-view',
  templateUrl: './bet-tracer-main-data-view.component.html',
  styleUrls: ['./bet-tracer-main-data-view.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class BetTracerMainDataViewComponent {
  @ViewChild(TemplateVarTypedDirective) private _templateVarTypedDirective?: TemplateVarTypedDirective;

  @Input() public isFiltersSticky: boolean = false;
  @Input() public isLoading: boolean = true;
  @Input() public isLoadingMoreBets: boolean = false;
  @Input() public isMoreBetsToDisplay: boolean = true;
  @Input() public noAccess: boolean = false;
  @Input() public betsNotFound: boolean = false;
  @Input() public bets: Betslip[] = [];
  @Input() public selectedOddsFormat!: OddsFormat;
  @Input() public indexedBetComments: IndexedBetComments = {};
  @Input() public indexedMatchComments: IndexedMatchComments = {};
  @Input() public displayedColumns: BetTraceColumNames[] = [];
  @Input() public currentOffset: number = 0;
  // @Input() public expandedBetRowsIds: Record<string, boolean> | undefined;
  @Input() set expandedBetRowsIds(expandedRows: Record<number, boolean> | undefined) {
    if (expandedRows) {
      this._templateVarTypedDirective?.updateInitialContext({ expandedRows });
    }
  }
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('resetColumns') public resetColumns$!: Observable<void>;

  @Output() public displayMoreBets: EventEmitter<number> = new EventEmitter<number>();
  @Output() public doSaveExpandedBetRowsIds: EventEmitter<Record<number, boolean>> = new EventEmitter<
    Record<number, boolean>
  >();
  @Output() doSubmitComment: EventEmitter<void> = new EventEmitter<void>();
  @Output() doUpdateCommentForm: EventEmitter<string | undefined> = new EventEmitter<string | undefined>();
  @Output() doSelectSetIdForComments: EventEmitter<number> = new EventEmitter<number>();
  @Output() doRefreshStatus: EventEmitter<[number, BrandType]> = new EventEmitter<[number, BrandType]>();

  public ngForTracker: NgForTracker = new NgForTracker();
  public textOverflowConfig: TextOverflowConfig = { copyable: false };
  public BetType: typeof BetType = BetType;
  public BetTraceColumNames: typeof BetTraceColumNames = BetTraceColumNames;
}
