import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ApiConfig } from '@sap/logic/api-access/api-config';
import { MessageError } from '@sap/logic/api-access/errors/message-error';
import { getApiUrlBrandByEnv } from '@sap/logic/api-access/pure-utils/pure-utils';
import { LocationsSortBy } from '@sap/logic/locations/bloc/locations/enums';
import { SortMode } from '@sap/logic/shared/enums';
import { BettingLocation } from '@sap/logic/shared/models/betting-location';
import { mapToQueryParamsFilters } from '@sap/logic/shared/utils/pure-utils';
import { SharedSettings } from '@sap/shared-settings/shared-settings';
import { BrandType } from '@sap/shared/enums';
import { lastValueFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiForbiddenDetector, MethodError } from '../detectors/api-forbidden-detector';

export enum FiltersKeys {
  isActive = 'is_active'
}

export type LocationsFiltersFront = {
  sortBy?: LocationsSortBy;
  sortMode?: SortMode;
};

export type LocationsFiltersApi = {
  brand?: BrandType;
  isActive?: boolean | undefined;
};

export type LocationsFilters = LocationsFiltersFront & LocationsFiltersApi;

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class LocationsApi {
  private _isProduction: boolean = this._sharedSettings.environment.production;
  private _envApiUrl: string = this._sharedSettings.environment.clientBOApiUrl;
  private _apiUrl: string = 'client-bo-api/v1/locations/';
  private _envApiUrlWithBrand: string | undefined;

  constructor(
    private _sharedSettings: SharedSettings,
    private _http: HttpClient,
    private _apiConfig: ApiConfig,
    private _apiForbiddenDetector: ApiForbiddenDetector
  ) {}

  public getBettingLocationsByBrand(filters: LocationsFiltersApi): Promise<BettingLocation[] | MessageError> {
    this._envApiUrlWithBrand = this._getEnvApiUrl(filters);

    return lastValueFrom(
      this._http
        .get<Record<string, any>[]>(`${this._envApiUrlWithBrand}${this._apiUrl}`, {
          params: mapToQueryParamsFilters(FiltersKeys, filters, {}),
          headers: this._apiConfig.authHeaders
        })
        .pipe(
          map(BettingLocation.fromJsonArray),
          catchError(this._apiForbiddenDetector.detectOnHttpError()),
          MessageError.fromHttpError()
        )
    );
  }

  public createLocation(form: Record<string, any>): Promise<BettingLocation | MessageError> {
    return lastValueFrom(
      this._http
        .post<Record<string, any>[]>(`${this._envApiUrlWithBrand}${this._apiUrl}`, form, this._apiConfig.authOptions)
        .pipe(
          map(BettingLocation.fromJson),
          catchError(this._apiForbiddenDetector.detectOnHttpError(MethodError.write)),
          MessageError.fromHttpError()
        )
    );
  }

  public editLocation(id: number, form: Record<string, any>): Promise<BettingLocation | MessageError> {
    return lastValueFrom(
      this._http
        .put<Record<string, any>[]>(`${this._envApiUrlWithBrand}${this._apiUrl}${id}`, form)
        .pipe(
          map(BettingLocation.fromJson),
          catchError(this._apiForbiddenDetector.detectOnHttpError(MethodError.write)),
          MessageError.fromHttpError()
        )
    );
  }

  private _getEnvApiUrl(params: Params): string | undefined {
    return getApiUrlBrandByEnv(this._isProduction, this._envApiUrl, params.brand);
  }
}
