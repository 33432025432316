import { Injectable } from '@angular/core';
import { UserDatabaseSettings } from '@sap/logic/shared/models/user-database-settings';
import { FirestoreDatabase } from '@sap/shared-platform/providers/firestore/firestore-database';
import { BrandType } from '@sap/shared/enums';
import { asPromise } from '@sap/shared/helpers/rx-helpers';
import { filter, first, mergeMap, Observable, takeUntil } from 'rxjs';
import { mapToUserSettings } from './rx-operators';

@Injectable({
  providedIn: 'root'
})
export class UserDatabase {
  constructor(private _firestoreDatabase: FirestoreDatabase) {}

  public getIsUserSignedToFirestore(): Observable<boolean> {
    return this._firestoreDatabase.getIsUserSigned().pipe(filter(Boolean));
  }

  public getUserSettings(): Observable<UserDatabaseSettings> {
    return this._firestoreDatabase
      .getIsUserSigned()
      .pipe(
        mergeMap(() =>
          this._firestoreDatabase
            .getObjectData('users', 'settings', this._firestoreDatabase.userEmailSigned!)
            .pipe(mapToUserSettings(), takeUntil(this._firestoreDatabase.userSignOutSignal()))
        )
      );
  }

  public async updateUserSettings(payload: Record<string, any>): Promise<void> {
    if (this._firestoreDatabase.userEmailSigned) {
      await this._firestoreDatabase.setData('users', this._firestoreDatabase.userEmailSigned, 'settings', payload);
    }
  }

  public async updateUserPermissions([modulePermissions, brandsFromScopes, userEmail]: [
    Record<string, Record<string, boolean>> | null,
    BrandType[] | null,
    string
  ]): Promise<void> {
    await asPromise(this.getIsUserSignedToFirestore().pipe(first((isLoaded: boolean) => isLoaded)));
    await this._firestoreDatabase.setData('users', userEmail, 'settings', {
      permissions: modulePermissions,
      brandsFromScopes
    });
  }

  public async cleanUserPermissions(userEmail: string): Promise<void> {
    await this._firestoreDatabase.setData('users', userEmail, 'settings', {
      permissions: null,
      brandsFromScopes: null
    });
  }
}
