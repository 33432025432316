import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toUpdatedArray', standalone: true })
export class ToUpdatedArrayPipe implements PipeTransform {
  public transform<T>(valueToUpdate: T, array: T[] | undefined, leftFirstIfEmpty: boolean = false): T[] {
    if (array && array.includes(valueToUpdate)) {
      const filteredArray: T[] = array.filter((value: T) => value !== valueToUpdate);
      return leftFirstIfEmpty && filteredArray.length === 0 ? [array[0]] : filteredArray;
    }
    return (array ?? []).concat(valueToUpdate) as T[];
  }
}
