import { Pipe, PipeTransform } from '@angular/core';
import { isDefined } from '@sap/logic/shared/utils/pure-utils';

@Pipe({
  standalone: true,
  name: 'isDefined'
})
export class IsDefinedPipe implements PipeTransform {
  public transform<T>(value: T | undefined): boolean {
    return isDefined(value);
  }
}
