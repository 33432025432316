<ng-container fxThemeCss></ng-container>
<ng-container *templateVarTyped="let vars = vars; let update = updateVar">
  <ng-container
    [doOnValueChange]="vars.filtersVisible"
    (didValueChanged)="isFiltersSticky.next(!vars.filtersVisible)"
  ></ng-container>
  <div class="card" [class.sticky]="!vars.filtersVisible">
    <div class="card-body" [class.card-body_collapsed]="!vars.filtersVisible">
      <div
        class="d-flex justify-content-between"
        [attr.title]="isLoading ? 'Not allowed now - bets are loading' : undefined"
      >
        <div class="row row_with-filters">
          <div class="col-auto col-auto_center">
            <ng-container *ngIf="!noAccess; else noAccessTemplate">
              <div class="brands-selector" [class.u-not-allowed]="isLoading">
                <ng-container *ngIf="brands.length > 0; else stillLoadingTemplate">
                  <ng-container *ngFor="let brandName of brands">
                    <sapds-button
                      *ngIf="brandName | toUpdatedArray: vars.selectedBrands : true as updatedArray"
                      class="brand-item"
                      [class.active]="vars.selectedBrands.includes(brandName)"
                      variant="transparent"
                      [isDisabled]="isLoading"
                      (click)="
                        resetOffset.next();
                        update('selectedBrands', updatedArray);
                        updateFilters('selectedBrands', updatedArray);
                        doFilter()
                      "
                    >
                      {{ brandName }}
                    </sapds-button>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </div>

          <ng-container class="" *ngIf="!vars.filtersVisible">
            <div class="col">
              <label [for]="BetInputPropertiesReadable.totalStake" class="form-label text-nowrap">{{
                BetInputPropertiesReadable.totalStake
              }}</label>
              <div class="input-group">
                <input
                  [id]="BetInputPropertiesReadable.totalStake"
                  type="text"
                  class="form-control input-typeable"
                  [inputSetValue]="vars.totalStake"
                  (didSetValue)="updateFilters('totalStake', vars.totalStake)"
                  (keyupTargetNumericValue)="update('totalStake', $event)"
                  (blur)="doFilter()"
                />
                <div class="input-group-text">
                  <sapds-icon
                    iconName="trash"
                    [class.u-disabled]="!vars.totalStake"
                    (click)="update('totalStake', undefined); updateFilters('totalStake', undefined); doFilter()"
                  ></sapds-icon>
                </div>
              </div>
            </div>
            <ng-container *ngIf="ClassificationPropertiesReadable | enumToSelectOptions as filterClassifications">
              <div class="col">
                <label class="form-label">Classification</label>
                <fieldset class="form-group">
                  <multi-select
                    appearance="rounded"
                    placeholderTitle="Classification"
                    displayProperty="name"
                    valueProperty="id"
                    [hideScroll]="true"
                    [hideSearch]="true"
                    [options]="filterClassifications"
                    [setSelectedOptions]="filterClassifications | arrayFilterPipe: 'id' : vars.classification"
                    (didChanged)="update('classification', $event); updateFilters('classification', $event); doFilter()"
                    (selectReset)="update('classification', []); updateFilters('classification', undefined); doFilter()"
                  ></multi-select>
                </fieldset>
              </div>
            </ng-container>
            <div class="col">
              <label [for]="BetInputPropertiesReadable.couponId" class="form-label text-nowrap">{{
                BetInputPropertiesReadable.couponId
              }}</label>
              <div class="input-group">
                <input
                  [id]="BetInputPropertiesReadable.couponId"
                  type="text"
                  class="form-control input-typeable"
                  [inputSetValue]="vars.couponId"
                  (didSetValue)="updateFilters('couponId', vars.couponId)"
                  (keyupTargetNumericValue)="update('couponId', $event)"
                  (blur)="doFilter()"
                />
                <div class="input-group-text">
                  <sapds-icon
                    iconName="trash"
                    [class.u-disabled]="!vars.couponId"
                    (click)="update('couponId', undefined); updateFilters('couponId', undefined); doFilter()"
                  ></sapds-icon>
                </div>
              </div>
            </div>
            <div class="col">
              <label [for]="EventInputPropertiesReadable.clientLogin" class="form-label text-nowrap">{{
                EventInputPropertiesReadable.clientLogin
              }}</label>
              <div class="input-group">
                <input
                  [id]="EventInputPropertiesReadable.clientLogin"
                  type="text"
                  class="form-control input-typeable"
                  [inputSetValue]="vars.clientLogin"
                  (didSetValue)="updateFilters('clientLogin', vars.clientLogin)"
                  (keyupTargetValue)="update('clientLogin', !$event.trim() ? undefined : $event.trim())"
                  (blur)="doFilter()"
                />
                <div class="input-group-text">
                  <sapds-icon
                    iconName="trash"
                    [class.u-disabled]="!vars.clientLogin"
                    (click)="update('clientLogin', undefined); updateFilters('clientLogin', undefined); doFilter()"
                  ></sapds-icon>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="col-auto align-self-center">
          <div class="top-right">
            <ul>
              <li>
                Turnover: <span>{{ calculatedTurnover | number }} EUR</span>
              </li>
              <li>
                Payouts: <span>{{ calculatedPayouts | number }} EUR</span>
              </li>
            </ul>

            <sap-bet-tracer-table-tool
              (updateDisplayedColumns)="updateDisplayedColumns.next($event)"
              (resetColumns)="resetColumns.next($event)"
            ></sap-bet-tracer-table-tool>

            <sapds-button
              class="filters-off"
              variant="danger"
              [isDisabled]="isLoading"
              (click)="
                update('couponId', undefined);
                update('betId', undefined);
                update('totalStake', undefined);
                update('totalStakeCurrency', undefined);
                update('couponRisk', undefined);
                update('payout', undefined);
                update('reachedRisk', undefined);
                update('quickWonBetTime', undefined);
                update('matchId', undefined);
                update('marketId', undefined);
                update('clientId', undefined);
                update('clientLogin', undefined);
                update('hasEarlyCashout', undefined);
                update('isLive', undefined);
                update('classification', []);
                update('category', []);
                update('forceStatus', []);
                update('betslipStatus', []);
                update('betType', []);
                update('dateFrom', undefined);
                update('dateTo', undefined);
                update('dateRange', undefined);
                update('statusUpdatedDateFrom', undefined);
                update('statusUpdatedDateTo', undefined);
                update('statusUpdatedDateRange', undefined);
                update('isTest', undefined);
                update('oddFormat', OddsFormat.decimal);
                update('betSelectionStatus', undefined);
                update('betStatuses', []);
                update('devices', []);
                update('bonusInfo', undefined);
                update('bonusTypes', []);
                update('locationId', undefined);
                update('manualOdds', undefined);
                update('sportId', undefined);
                update('regionId', undefined);
                update('competitionId', undefined);
                update('sportsGroupsIds', []);
                doReset()
              "
            >
              Clear filters
            </sapds-button>
            <sapds-button
              [iconName]="vars.filtersVisible ? 'chevron-circle-up-alt' : 'chevron-circle-down-alt'"
              (click)="
                doToggleFiltersVisibility$.next(!vars.filtersVisible); update('filtersVisible', !vars.filtersVisible)
              "
            >
            </sapds-button>
          </div>
        </div>
      </div>
      <ng-container *ngIf="vars.filtersVisible">
        <div class="row mt-3 mb-3">
          <ng-container *ngIf="BetItemStatusPropertiesReadable | enumValuesToArray as filterBetItemStatuses">
            <div class="col-1 helpers-col-1_wider">
              <label class="form-label">Selection Status</label>
              <sap-simple-select
                class="fullwidth"
                appearance="rounded"
                inputName="Select status"
                [options]="filterBetItemStatuses"
                [selectedOption]="vars.betSelectionStatus"
                (selectChange)="
                  update('betSelectionStatus', $event); updateFilters('betSelectionStatus', $event); doFilter()
                "
                (selectReset)="
                  update('betSelectionStatus', undefined); updateFilters('betSelectionStatus', undefined); doFilter()
                "
              >
              </sap-simple-select>
            </div>
          </ng-container>
          <ng-container *ngIf="BetStatusPropertiesReadable | enumKeysToArray as filterBetStatuses">
            <div class="col-1 helpers-col-1_multi">
              <label class="form-label">Bet Status</label>
              <fieldset class="form-group">
                <multi-select
                  appearance="rounded"
                  placeholderTitle="Bet Status"
                  [hideScroll]="true"
                  [hideSearch]="true"
                  [setSelectedOptions]="vars.betStatuses"
                  [selectAllOption]="true"
                  [options]="filterBetStatuses"
                  (didChanged)="update('betStatuses', $event); updateFilters('betStatuses', $event); doFilter()"
                  (selectReset)="update('betStatuses', []); updateFilters('betStatuses', undefined); doFilter()"
                ></multi-select>
              </fieldset>
            </div>
          </ng-container>

          <div class="col-1 helpers-col-1_multi">
            <label class="form-label">Locations</label>
            <ng-container *ngIf="!isLoadingBettingLocations; else stillLoadingTemplate">
              <sap-search-select
                #locationSelectRef
                class="search-select"
                inputName="Select location"
                displayProperty="label"
                valueProperty="id"
                appearance="rounded"
                [minLettersToSearch]="0"
                [selectedOptionById]="vars.locationId"
                [options]="bettingLocations"
                (didChanged)="update('locationId', $event?.id); updateFilters('locationId', $event?.id); doFilter()"
                (selectReset)="update('locationId', undefined); updateFilters('locationId', undefined); doFilter()"
              >
              </sap-search-select>
            </ng-container>
          </div>

          <ng-container *ngIf="DevicesPropertiesReadable | enumValuesToArray as filterDevice">
            <div class="col-1 helpers-col-1_wider">
              <label class="form-label">Device</label>
              <multi-select
                appearance="rounded"
                placeholderTitle="Select devices"
                [hideScroll]="true"
                [hideSearch]="true"
                [setSelectedOptions]="vars.devices"
                [selectAllOption]="true"
                [options]="filterDevice"
                (didChanged)="update('devices', $event); updateFilters('devices', $event); doFilter()"
                (selectReset)="update('devices', []); updateFilters('devices', undefined); doFilter()"
              ></multi-select>
            </div>
          </ng-container>

          <div class="col-1 helpers-col-1_wider">
            <label for="bonusInfo" class="form-label text-nowrap">Bonus Info</label>
            <div class="input-group">
              <input
                id="bonusInfo"
                type="text"
                class="form-control input-typeable"
                [inputSetValue]="vars.bonusInfo"
                (didSetValue)="updateFilters('bonusInfo', vars.bonusInfo)"
                (keyupTargetValue)="update('bonusInfo', !$event.trim() ? undefined : $event.trim())"
                (blur)="doFilter()"
              />
              <div class="input-group-text">
                <sapds-icon
                  iconName="trash"
                  [class.u-disabled]="!vars.bonusInfo"
                  (click)="update('bonusInfo', undefined); updateFilters('bonusInfo', undefined); doFilter()"
                />
              </div>
            </div>
          </div>

          <!-- <div class="row"> -->
          <div class="categories mb-1">
            <ul *ngIf="BetBonusTypesPropertiesReadable | enumKeysToArray as filterBonusTypes">
              <li class="user-type">Bonus:</li>
              <li
                [class.active]="vars.bonusTypes.length === 0 || vars.bonusTypes.length === filterBonusTypes.length"
                (click)="update('bonusTypes', []); updateFilters('bonusTypes', undefined); doFilter()"
              >
                All
              </li>
              <ng-container *ngFor="let bonusType of filterBonusTypes">
                <li
                  *ngIf="bonusType | toUpdatedArray: vars.bonusTypes as updatedArray"
                  [class.active]="vars.bonusTypes.includes(bonusType)"
                  (click)="update('bonusTypes', updatedArray); updateFilters('bonusTypes', updatedArray); doFilter()"
                >
                  {{ BetBonusTypesPropertiesReadable[bonusType] }}
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
        <!-- </div> -->
        <div class="row">
          <div class="categories">
            <ul *ngIf="ClassificationPropertiesReadable | enumKeysToArray as filterClassificationNames">
              <li class="user-type">Classification:</li>
              <li
                [class.active]="
                  vars.classification.length === 0 || vars.classification.length === filterClassificationNames.length
                "
                (click)="update('classification', []); updateFilters('classification', undefined); doFilter()"
              >
                All
              </li>
              <ng-container *ngFor="let classificationName of filterClassificationNames">
                <li
                  *ngIf="classificationName | toUpdatedArray: vars.classification as updatedArray"
                  [class.active]="vars.classification.includes(classificationName)"
                  (click)="
                    update('classification', updatedArray); updateFilters('classification', updatedArray); doFilter()
                  "
                >
                  {{ ClassificationPropertiesReadable[classificationName] }}
                </li>
              </ng-container>
            </ul>
          </div>
          <div class="col col_dates">
            <div class="d-flex helpers-col-fixed align-items-baseline">
              <label class="form-label u-text-no-wrap">Date:</label>
              <fieldset class="input-group input-selectable datepicker-container">
                <span class="input-group-text icon-container input-round-left">
                  <sapds-icon iconName="calendar" />
                </span>
                <input
                  class="form-control"
                  type="text"
                  placeholder="date range"
                  [inputSetValue]="vars.dateRange"
                  (focus)="update('dateRangePickerOpen', true)"
                />

                <ng-container *ngIf="vars.dateRangePickerOpen">
                  <sap-date-range-picker
                    class="datepicker-bottom"
                    [dateFrom]="vars.dateFrom"
                    [dateTo]="vars.dateTo"
                    [forceFiltersCss]="true"
                    [enableTimeMode]="true"
                    (doClose)="update('dateRangePickerOpen', false)"
                    (didSelectDateRange)="
                      update('dateRangePickerOpen', false);
                      update('dateFrom', $event[0]);
                      update('dateTo', $event[1]);
                      update('dateRange', dateRangeString($event, true));
                      updateFilters('dateFrom', $event[0]?.toISOString());
                      updateFilters('dateTo', $event[1]?.toISOString());
                      doFilter()
                    "
                  >
                  </sap-date-range-picker>
                </ng-container>

                <span class="input-group-text icon-container input-round-right">
                  <sapds-icon
                    iconName="trash"
                    [class.u-disabled]="!vars.dateFrom"
                    (click)="
                      update('dateFrom', undefined);
                      update('dateTo', undefined);
                      update('dateRange', undefined);
                      updateFilters('dateFrom', undefined);
                      updateFilters('dateTo', undefined);
                      doFilter()
                    "
                  ></sapds-icon>
                </span>
              </fieldset>
            </div>

            <div class="d-flex helpers-col-fixed align-items-baseline">
              <label class="form-label u-text-no-wrap">Status updated:</label>
              <fieldset class="input-group input-selectable datepicker-container">
                <span class="input-group-text icon-container input-round-left">
                  <sapds-icon iconName="calendar" />
                </span>
                <input
                  class="form-control"
                  type="text"
                  placeholder="date range"
                  [inputSetValue]="vars.statusUpdatedDateRange"
                  (focus)="update('statusUpdatedDateRangePickerOpen', true)"
                />

                <ng-container *ngIf="vars.statusUpdatedDateRangePickerOpen">
                  <sap-date-range-picker
                    class="datepicker-bottom"
                    [dateFrom]="vars.statusUpdatedDateFrom"
                    [dateTo]="vars.statusUpdatedDateTo"
                    [forceFiltersCss]="true"
                    [enableTimeMode]="true"
                    (doClose)="update('statusUpdatedDateRangePickerOpen', false)"
                    (didSelectDateRange)="
                      update('statusUpdatedDateRangePickerOpen', false);
                      update('statusUpdatedDateFrom', $event[0]);
                      update('statusUpdatedDateTo', $event[1]);
                      update('statusUpdatedDateRange', dateRangeString($event, true));
                      updateFilters('statusUpdatedDateFrom', $event[0]?.toISOString());
                      updateFilters('statusUpdatedDateTo', $event[1]?.toISOString());
                      doFilter()
                    "
                  >
                  </sap-date-range-picker>
                </ng-container>

                <span class="input-group-text icon-container input-round-right">
                  <sapds-icon
                    iconName="trash"
                    [class.u-disabled]="!vars.statusUpdatedDateFrom"
                    (click)="
                      update('statusUpdatedDateFrom', undefined);
                      update('statusUpdatedDateTo', undefined);
                      update('statusUpdatedDateRange', undefined);
                      updateFilters('statusUpdatedDateFrom', undefined);
                      updateFilters('statusUpdatedDateTo', undefined);
                      doFilter()
                    "
                  ></sapds-icon>
                </span>
              </fieldset>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <ng-container *ngIf="BetInputPropertiesReadable | enumKeysToArray as filterBetInputNames">
            <div class="col-1 helpers-col-1_wider" *ngFor="let inputName of filterBetInputNames">
              <label [for]="inputName" class="form-label text-nowrap">{{
                BetInputPropertiesReadable[inputName]
              }}</label>
              <div class="input-group">
                <input
                  [id]="inputName"
                  type="text"
                  class="form-control input-typeable"
                  [inputSetValue]="vars[inputName]"
                  (didSetValue)="updateFilters(inputName, vars[inputName])"
                  (keyupTargetNumericValue)="update(inputName, $event)"
                  (blur)="doFilter()"
                />
                <div class="input-group-text">
                  <sapds-icon
                    iconName="trash"
                    [class.u-disabled]="!vars[inputName]"
                    (click)="update(inputName, undefined); updateFilters(inputName, undefined); doFilter()"
                  ></sapds-icon>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="row mt-3">
          <div
            class="col categories"
            [title]="
              vars.sportsGroupsIds.length ? 'You are using other filters by sports groups. Clear them to activate.' : ''
            "
          >
            <ul [class.u-disabled]="vars.sportsGroupsIds.length">
              <li class="user-type">Sport:</li>
              <li
                [class.active]="vars.sportsIds.length === 0"
                (click)="update('sportsIds', []); updateFilters('sportsIds', undefined); doFilter()"
              >
                All
              </li>
              <ng-container *ngFor="let sportGroup of popularSports">
                <li
                  *ngIf="sportGroup.id | toUpdatedArray: vars.sportsIds as updatedArray"
                  [class.active]="vars.sportsIds.includes(sportGroup.id)"
                  (click)="update('sportsIds', updatedArray); updateFilters('sportsIds', updatedArray); doFilter()"
                >
                  {{ sportGroup.name }}
                </li>
              </ng-container>

              <sapds-button class="more-sports-btn">
                <multi-select
                  appearance="button"
                  valueProperty="id"
                  displayProperty="name"
                  [hideSearch]="true"
                  [options]="sortedSports"
                  [setSelectedOptions]="sortedSports | arrayFilterPipe: 'id' : vars.sportsIds"
                  (didChanged)="updateFilters('sportsIds', $event); update('sportsIds', $event); doFilter()"
                >
                </multi-select>
                <span class="dots"> &#8226; &#8226; &#8226; </span>
              </sapds-button>
            </ul>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col categories">
            <ul *ngIf="CategoryPropertiesReadable | enumKeysToArray as filterCategoryNames">
              <li class="user-type">Category bets:</li>
              <li
                [class.active]="
                  (!vars.hasEarlyCashout && vars.category.length === 0 && vars.forceStatus.length === 0) ||
                  vars.category.length === filterCategoryNames.length
                "
                (click)="
                  update('category', []);
                  update('forceStatus', []);
                  update('hasEarlyCashout', false);
                  updateFilters('category', undefined);
                  updateFilters('forceStatus', undefined);
                  updateFilters('hasEarlyCashout', undefined);
                  doFilter()
                "
              >
                All
              </li>
              <ng-container *ngFor="let categoryName of filterCategoryNames; let index = index">
                <li
                  *ngIf="categoryName | toUpdatedArray: vars.category as updatedArray"
                  [class.active]="vars.category.includes(categoryName)"
                  (click)="update('category', updatedArray); updateFilters('category', updatedArray); doFilter()"
                >
                  {{ CategoryPropertiesReadable[categoryName] }}
                </li>

                <!-- last item of filterCategoryNames should be after hasEarlyCashout and forceStatus filters -->
                <ng-container *ngIf="index === filterCategoryNames.length - 2">
                  <li
                    [class.active]="vars.manualOdds"
                    (click)="
                      update('manualOdds', !vars.manualOdds);
                      updateFilters('manualOdds', !vars.manualOdds ? true : undefined);
                      doFilter()
                    "
                  >
                    Manual Odds
                  </li>
                  <li
                    [class.active]="vars.hasEarlyCashout"
                    (click)="
                      update('hasEarlyCashout', !vars.hasEarlyCashout);
                      updateFilters('hasEarlyCashout', !vars.hasEarlyCashout ? true : undefined);
                      doFilter()
                    "
                  >
                    Early Cashout
                  </li>
                  <ng-container *ngIf="BetForceStatusPropertiesReadable | enumKeysToArray as filterForceStatusNames">
                    <li
                      [class.active]="vars.forceStatus.length"
                      (click)="
                        update('forceStatus', vars.forceStatus.length ? [] : filterForceStatusNames);
                        updateFilters('forceStatus', vars.forceStatus.length ? [] : filterForceStatusNames);
                        doFilter()
                      "
                    >
                      Force Status
                    </li>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ul>
          </div>
        </div>

        <div class="row mt-3">
          <ng-container *ngIf="EventInputPropertiesReadable | enumKeysToArray as filterEventInputNames">
            <div class="col-1 helpers-col-1_wider" *ngFor="let inputName of filterEventInputNames">
              <label [for]="inputName" class="form-label text-nowrap">{{
                EventInputPropertiesReadable[inputName]
              }}</label>
              <div class="input-group">
                <ng-container *ngIf="inputName !== 'clientId'; else numberInputTemplate">
                  <input
                    [id]="inputName"
                    type="text"
                    class="form-control input-typeable"
                    [inputSetValue]="vars[inputName]"
                    (didSetValue)="updateFilters(inputName, vars[inputName])"
                    (keyupTargetValue)="update(inputName, !$event.trim() ? undefined : $event.trim())"
                    (blur)="doFilter()"
                  />
                </ng-container>
                <ng-template #numberInputTemplate>
                  <input
                    [id]="inputName"
                    type="text"
                    class="form-control input-typeable"
                    [inputSetValue]="vars[inputName]"
                    (didSetValue)="updateFilters(inputName, vars[inputName])"
                    (keyupTargetNumericValue)="update(inputName, $event)"
                    (blur)="doFilter()"
                  />
                </ng-template>
                <div class="input-group-text">
                  <sapds-icon
                    iconName="trash"
                    [class.u-disabled]="!vars[inputName]"
                    (click)="update(inputName, undefined); updateFilters(inputName, undefined); doFilter()"
                  />
                </div>
              </div>
            </div>
          </ng-container>

          <div
            class="col-1 helpers-col-1_wider"
            [title]="
              vars.sportsIds.length ? 'You are using other filters by sports groups. Clear them to activate.' : ''
            "
          >
            <span [class.u-disabled]="vars.sportsIds.length">
              <label for="sport" class="form-label">Sport</label>
              <sap-search-select
                #sportsMultiSelectRef
                appearance="rounded"
                inputName="Sport"
                class="search-select"
                [minLettersToSearch]="0"
                [options]="sportsGroups | arrayFilterPipe: 'parentId' : null"
                displayProperty="name"
                valueProperty="id"
                [selectedOptionById]="vars.sportId"
                (didChanged)="updateFilters('sportsGroupsIds', [$event!.id]); update('sportId', $event?.id); doFilter()"
                (selectReset)="
                  update('sportId', undefined);
                  update('regionId', undefined);
                  update('competitionId', undefined);
                  update('sportsGroupsIds', []);
                  updateFilters('sportsGroupsIds', undefined);
                  doFilter()
                "
              >
              </sap-search-select>
            </span>
          </div>

          <div
            class="col-1 helpers-col-1_wider"
            [title]="
              vars.sportsIds.length ? 'You are using other filters by sports groups. Clear them to activate.' : ''
            "
          >
            <span [class.u-disabled]="vars.sportsIds.length">
              <label for="region" class="form-label">Region</label>
              <sap-search-select
                #regionNameSelectRef
                appearance="rounded"
                class="search-select"
                inputName="Region"
                displayProperty="name"
                valueProperty="id"
                [class.u-disabled]="
                  !vars.sportId && !(sportsGroups | arrayFilterPipe: 'parentId' : vars.sportId).length
                "
                [minLettersToSearch]="0"
                [selectedOptionById]="vars.regionId"
                [options]="sportsGroups | arrayFilterPipe: 'parentId' : vars.sportId"
                (didChanged)="
                  updateFilters('sportsGroupsIds', [vars.sportId!, $event!.id]);
                  update('regionId', $event?.id);
                  doFilter()
                "
                (selectReset)="
                  update('regionId', undefined);
                  update('competitionId', undefined);
                  update('sportsGroupsIds', [vars.sportId!]);
                  updateFilters('sportsGroupsIds', [vars.sportId!]);
                  doFilter()
                "
              >
              </sap-search-select>
            </span>
          </div>

          <div
            class="col-1 helpers-col-1_wider"
            [title]="
              vars.sportsIds.length ? 'You are using other filters by sports groups. Clear them to activate.' : ''
            "
          >
            <span [class.u-disabled]="vars.sportsIds.length">
              <label for="competition" class="form-label">Competition</label>
              <sap-search-select
                #competitionNameMultiSelectRef
                appearance="rounded"
                class="search-select"
                inputName="Competition"
                displayProperty="name"
                valueProperty="id"
                [class.u-disabled]="
                  !vars.regionId && !(sportsGroups | arrayFilterPipe: 'parentId' : vars.regionId).length
                "
                [minLettersToSearch]="0"
                [selectedOptionById]="vars.competitionId"
                [options]="sportsGroups | arrayFilterPipe: 'parentId' : vars.regionId"
                (didChanged)="
                  updateFilters('sportsGroupsIds', [vars.sportId!, vars.regionId!, $event!.id]);
                  update('competitionId', $event?.id);
                  doFilter()
                "
                (selectReset)="
                  update('competitionId', undefined);
                  update('sportsGroupsIds', [vars.sportId!, vars.regionId!]);
                  updateFilters('sportsGroupsIds', [vars.sportId!, vars.regionId!]);
                  doFilter()
                "
              >
              </sap-search-select>
            </span>
          </div>

          <div class="col">
            <label for="refresh-rate" class="form-label text-nowrap">Refresh Rate [s]</label>
            <div class="row">
              <div class="col refresh-rate">
                <div class="input-group">
                  <ng-container
                    *ngIf="{
                      interval: refreshState ? refreshState[0] : vars.refreshInterval,
                      isOn: refreshState ? refreshState[1] : vars.refreshRateOn
                    } as refresh"
                  >
                    <input
                      id="refresh-rate"
                      type="number"
                      class="form-control input-typeable"
                      placeholder="Refresh Rate"
                      min="1"
                      [value]="refresh.interval / 1000"
                      (keyupTargetNumericValue)="update('refreshInterval', $event * 1000)"
                      (blur)="vars.refreshInterval >= 1 && doRefresh.next([vars.refreshInterval, vars.refreshRateOn])"
                    />
                    <span class="input-requirements">min value is 1s!</span>
                    <div class="input-group-text">
                      <input
                        class="form-check-input mt-0"
                        type="checkbox"
                        value=""
                        [checked]="refresh.isOn"
                        (click)="
                          vars.refreshInterval >= 1 && doRefresh.next([refresh.interval, !refresh.isOn]);
                          update('refreshRateOn', !refresh.isOn)
                        "
                      />
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="col">
                <div class="categories">Timer: {{ refreshState | refreshCounter }} s</div>
                <a title="Refresh now" class="categories u-clickable" (click)="doForceRefresh.next()">
                  <sapds-icon iconName="loop-circular" />
                  refresh now
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col categories">
            <ul>
              <li class="user-type">Event type:</li>
              <li
                [class.active]="vars.isLive === undefined"
                (click)="update('isLive', undefined); updateFilters('isLive', undefined); doFilter()"
              >
                All
              </li>
              <li
                [class.active]="vars.isLive === false"
                (click)="update('isLive', false); updateFilters('isLive', false); doFilter()"
              >
                Prematch
              </li>
              <li
                [class.active]="vars.isLive"
                (click)="update('isLive', !vars.isLive); updateFilters('isLive', !vars.isLive); doFilter()"
              >
                Live
              </li>
              <!--
            <li
              *ngFor="let eventTypeName of filterEventTypeNames"
              [class.active]="vars.eventType.includes(eventTypeName)"
              (click)="
                update('eventType', getUpdatedArray(eventTypeName, vars.eventType));
                updateFilters('eventType', getUpdatedArray(eventTypeName, vars.eventType));
                doFilter()
              "
            >
              {{ eventTypePropertiesReadable[eventTypeName] }}
            </li>
            <li >

            </li> -->
            </ul>
          </div>

          <div class="col categories">
            <ul *ngIf="BetslipStatusPropertiesReadable | enumKeysToArray as filterBetslipStatusNames">
              <li class="user-type">Betslip status:</li>
              <li
                [class.active]="
                  vars.betslipStatus.length === 0 || vars.betslipStatus.length === filterBetslipStatusNames.length
                "
                (click)="update('betslipStatus', []); updateFilters('betslipStatus', undefined); doFilter()"
              >
                All
              </li>
              <ng-container *ngFor="let betslipStatusName of filterBetslipStatusNames">
                <li
                  *ngIf="betslipStatusName | toUpdatedArray: vars.betslipStatus as updatedArray"
                  [class.active]="vars.betslipStatus.includes(betslipStatusName)"
                  (click)="
                    update('betslipStatus', updatedArray); updateFilters('betslipStatus', updatedArray); doFilter()
                  "
                >
                  {{ BetslipStatusPropertiesReadable[betslipStatusName] }}
                </li>
              </ng-container>
            </ul>
          </div>

          <div class="col categories">
            <ul *ngIf="BetTypePropertiesReadable | enumKeysToArray as filterBetTypeNames">
              <li class="user-type">Bet type:</li>
              <li
                [class.active]="vars.betType.length === 0 || vars.betType.length === filterBetTypeNames.length"
                (click)="update('betType', []); updateFilters('betType', undefined); doFilter()"
              >
                All
              </li>
              <ng-container *ngFor="let betTypeName of filterBetTypeNames">
                <li
                  *ngIf="betTypeName | toUpdatedArray: vars.betType as updatedArray"
                  [class.active]="vars.betType.includes(betTypeName)"
                  (click)="update('betType', updatedArray); updateFilters('betType', updatedArray); doFilter()"
                >
                  {{ BetTypePropertiesReadable[betTypeName] }}
                </li>
              </ng-container>
            </ul>
          </div>

          <div class="col categories">
            <ul *ngIf="OddsFormat | enumKeysToArray as oddsFormat">
              <li class="user-type">Odds type:</li>
              <ng-container *ngFor="let oddFormat of oddsFormat">
                <li
                  [class.active]="vars.oddFormat === oddFormat"
                  (click)="update('oddFormat', oddFormat); updateFilters('oddFormat', oddFormat); doFilter()"
                >
                  {{ oddFormat }}
                </li>
              </ng-container>
            </ul>
          </div>

          <div class="col categories">
            <ul>
              <li class="user-type">Test Bets:</li>
              <li
                [class.active]="vars.isTest === undefined"
                (click)="update('isTest', undefined); updateFilters('isTest', undefined); doFilter()"
              >
                All
              </li>
              <li
                [class.active]="vars.isTest"
                (click)="update('isTest', true); updateFilters('isTest', true); doFilter()"
              >
                test bets
              </li>
              <li
                [class.active]="vars.isTest === false"
                (click)="update('isTest', false); updateFilters('isTest', false); doFilter()"
              >
                real bets
              </li>
            </ul>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<!-- templates -->
<ng-template #noAccessTemplate>
  <span class="u-text-danger"> You don't have access to any required brand. </span>
</ng-template>
<ng-template #stillLoadingTemplate>
  <div class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>
