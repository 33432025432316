import { Pipe, PipeTransform } from '@angular/core';
import { isDefined } from '@sap/logic/shared/utils/pure-utils';
import { decimalOddsToAmericanOdd, decimalToFraction, toDouble } from '@sap/shared/miscs/utils';
import { OddsFormat } from '@sap/ui/bet-tracer/containers/bet-tracer-top-filters/enums';

@Pipe({ name: 'oddsFormat' })
export class OddsFormatPipe implements PipeTransform {
  public transform(value: number | string | undefined, oddsFormat: OddsFormat): number | string | undefined {
    if (!isDefined(value)) {
      return undefined;
    }
    const valueAsNumber: number = Number(value);

    if (oddsFormat === OddsFormat.decimal) {
      return toDouble(valueAsNumber).toFixed(2) || '0.00';
    }
    if (oddsFormat === OddsFormat.american) {
      return decimalOddsToAmericanOdd(valueAsNumber);
    }
    if (oddsFormat === OddsFormat.fractional) {
      return decimalToFraction(toDouble(valueAsNumber));
    }
    return value;
  }
}
