import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { SelectAppearance } from './shared/enum';

@Component({
  selector: 'sap-simple-select',
  template: `
    <div
      fxThemeCss
      class="form-multi-select form-multi-select-simple form-multi-select-selection-tags"
      [class.form-select_rounded]="appearance === SelectAppearance.rounded"
      [class.form-select_table]="appearance === SelectAppearance.table"
      [class.form-select_selected]="selectedOption"
      [class.form-select_capitalized]="textCapitalized"
      [class.has-trash-icon]="showTrash"
      [class.show]="show"
      (click)="doToggle()"
      (clickOutside)="doHide()"
      [triggerWhen]="show"
    >
      <div class="form-multi-select__inner">
        <span *ngIf="selectedOption !== undefined" class="form-multi-select-selection">
          <span class="form-multi-select-tag" *ngIf="selectedOption">
            {{ selectedOption }}
          </span>
        </span>
        <span *ngIf="selectedOption === undefined" class="form-multi-select-placeholder">
          {{ inputPlaceholder }}
        </span>
        <sapds-icon
          *ngIf="appearance === SelectAppearance.rounded && showTrash"
          iconName="trash"
          (click)="selectReset.next()"
        />
      </div>
      <div class="form-multi-select-dropdown" *ngIf="showOptions">
        <div class="form-multi-select-options styled-scrollbar">
          <ng-container *ngFor="let option of options">
            <div
              class="form-multi-select-option"
              [title]="option"
              [class.form-multi-selected]="option === selectedOption"
              (click)="toggleOption(option); $event.stopPropagation()"
            >
              {{ option }}
            </div>
          </ng-container>
          <ng-container *ngIf="options?.length === 0">
            <div class="form-multi-select-option">No results</div>
          </ng-container>
        </div>
      </div>
    </div>
    <sapds-icon
      *ngIf="appearance !== SelectAppearance.rounded && showTrash"
      iconName="trash"
      [class.u-disabled]="disableTrash"
      (click)="reset(); selectReset.next()"
    />
  `,
  styleUrls: ['./shared/select.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class SimpleSelectComponent<T> implements OnInit {
  public SelectAppearance: typeof SelectAppearance = SelectAppearance;

  private _options: T[] = [];

  @Input() set options(values: T[]) {
    this._options = values;
  }
  public get options(): T[] {
    return this._options;
  }

  private _selectedOption: T | undefined;
  private _disableTrash: boolean = false;

  @Input() set selectedOption(value: T | undefined) {
    this._selectedOption = value;
    this._cdRef.detectChanges();
  }
  public get selectedOption(): T | undefined {
    return this._selectedOption;
  }

  @Input() showTrash: boolean = true;
  @Input() appearance: keyof typeof SelectAppearance = SelectAppearance.default;
  @Input() inputName: string = 'Select..';
  @Input() textCapitalized: boolean = false;
  @Input() set disableTrash(value: boolean) {
    this._disableTrash = value;
    this._cdRef.detectChanges();
  }
  public get disableTrash(): boolean {
    return this._disableTrash;
  }
  @Output() selectChange: EventEmitter<T | undefined> = new EventEmitter<T | undefined>();
  @Output() selectReset: EventEmitter<void> = new EventEmitter<void>();

  public show: boolean = false;
  public showOptions: boolean = false;

  constructor(private _cdRef: ChangeDetectorRef) {
    this._cdRef.detach();
  }

  public get inputPlaceholder(): string {
    return this.inputName;
  }

  public ngOnInit(): void {
    this._cdRef.detectChanges();
  }

  public doShow(): void {
    this.showOptions = true;
    this.show = true;
    this._cdRef.detectChanges();
  }

  public doHide(): void {
    this.showOptions = false;
    this.show = false;
    this._cdRef.detectChanges();
  }

  public doToggle(): void {
    this.show ? this.doHide() : this.doShow();
  }

  public toggleOption(optionToToggle: T | undefined): void {
    this.selectedOption = optionToToggle;
    this._emitUpdate();
    this.doHide();
    this._cdRef.detectChanges();
  }

  public reset(restoredValue?: T): void {
    this.selectedOption = restoredValue ?? undefined;
    this._emitUpdate();
    this._cdRef.detectChanges();
  }

  private _emitUpdate(): void {
    this.selectChange.emit(this.selectedOption);
  }
}
