import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'arrayFilterPipe', standalone: true })
export class ArrayFilterPipe implements PipeTransform {
  public transform<T>(
    arr: T[],
    filterByKey: string,
    keyValue: string | string[] | number | number[] | undefined | null, // parentId can be null
    isNegative: boolean = false
  ): T[] {
    if (isNegative) {
      if (Array.isArray(keyValue)) {
        return arr.filter((item: T) => !keyValue.some((key: string | number) => item[filterByKey] === key));
      }
      return arr.filter((item: T) => item[filterByKey] !== keyValue);
    }
    if (Array.isArray(keyValue)) {
      return arr.filter((item: T) => keyValue.some((key: string | number) => item[filterByKey] === key));
    }
    return arr.filter((item: T) => item[filterByKey] === keyValue);
  }
}
