import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IndexedMatchComments } from '@sap/logic/firestore-database/types';
import { ClassificationValuesReadable } from '@sap/logic/shared/enums';
import { BetType, BetWithSelections } from '@sap/logic/shared/models/bet';
import { BetInternalComment } from '@sap/logic/shared/models/bet-internal-comment';
import { Betslip } from '@sap/logic/shared/models/betslip';
import { SelectionOutcome } from '@sap/logic/shared/models/selection-info-status';
import { BrandType } from '@sap/shared/enums';
import { OddsFormat } from '../../containers/bet-tracer-top-filters/enums';

@Component({
  selector: 'sap-bet-tracer-item',
  templateUrl: './bet-tracer-item.component.html',
  styleUrls: ['./bet-tracer-item.component.scss']
})
export class BetTracerItemComponent {
  @Input() betslip!: Betslip;
  @Input() betslipId!: number;
  @Input() bet!: BetWithSelections;
  @Input() isExpanded: boolean = false;
  @Input() commentsLength: number | undefined;
  @Input() isCoupon: boolean = false;
  @Input() isExpendable: boolean = false;
  @Input() oddsFormat!: OddsFormat;
  @Input() indexedMatchComments: IndexedMatchComments = {};
  @Input() indexedBetComments: BetInternalComment[] = [];

  @Output() doToggleBetId: EventEmitter<number> = new EventEmitter<number>();
  @Output() doForceBetStatus: EventEmitter<SelectionOutcome> = new EventEmitter<SelectionOutcome>();
  @Output() addComment: EventEmitter<number> = new EventEmitter<number>();
  @Output() doRefreshStatus: EventEmitter<[number, BrandType]> = new EventEmitter<[number, BrandType]>();

  public BetType: typeof BetType = BetType;
  public ClassificationValuesReadable: typeof ClassificationValuesReadable = ClassificationValuesReadable;
}
