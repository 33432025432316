import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { isDefined } from '@sap/logic/shared/utils/pure-utils';
import * as R from 'rambdax';

@Directive({
  selector: '[inputSetValue]',
  standalone: true,
  exportAs: 'inputSetValueRef'
})
export class InputSetValueDirective {
  private _initialValue: string | number | undefined;

  @Output() didSetValue: EventEmitter<void> = new EventEmitter<void>();

  @Input('inputSetValue') set value(value: string | number | undefined) {
    if (isDefined(value) && !R.equals(this._initialValue, value)) {
      this._initialValue = value;
    }
    this.setValue(value);
  }

  @Input() minLength: number | undefined;

  public get isInvalid(): boolean {
    if (isDefined(this.minLength) && isDefined(this._elementRef.nativeElement.value)) {
      return this._elementRef.nativeElement.value < this.minLength;
    }
    return false;
  }

  public get value(): string | undefined {
    return this._elementRef.nativeElement.value === '' ? undefined : this._elementRef.nativeElement.value;
  }

  public get valueAsNumber(): number {
    return Number(this._elementRef.nativeElement.value);
  }

  constructor(private _elementRef: ElementRef) {}

  public setValue(value: number | string | undefined): void {
    this._elementRef.nativeElement.value = value?.toString() ?? '';

    this.didSetValue.emit(undefined);
  }

  public resetToInitialValue(clearValue: boolean = false): void {
    if (clearValue) {
      this._initialValue = undefined;
    }
    this._elementRef.nativeElement.value = this._initialValue?.toString() ?? '';
  }
}
