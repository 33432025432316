import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@sap/logic/api-access/api-config';
import { MessageError } from '@sap/logic/api-access/errors/message-error';
import { AuthChecker } from '@sap/logic/auth/services/auth-checker';
import { AuthToken } from '@sap/logic/shared/models/auth-tokens';
import { SharedSettings } from '@sap/shared-settings/shared-settings';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

enum HttpTokenErrorStatusCode {
  TOKEN_EXPIRED = 419,
  INVALID_TOKEN = 401,
  MISSING_BEARER_PREFIX = 400,
  FORBIDDEN = 403
}

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private _refreshTokenInProgress: boolean = false;
  private _authUrl: string = `${this._sharedSettings.environment.authApiUrl}auth/v1/authenticate/`;
  private _authRefreshUrl: string = `${this._sharedSettings.environment.authApiUrl}auth/v1/authenticate/refresh/`;

  constructor(
    private _sharedSettings: SharedSettings,
    private _apiConfig: ApiConfig,
    private _authChecker: AuthChecker
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url === this._authUrl) {
      // we don't need token for authenticate
    } else if (request.url === this._authRefreshUrl) {
      request = this._addRefreshTokenToRequest(request);
    } else {
      const [isNotExpiresToken, isNotExpiresRefreshToken]: boolean[] = this._authChecker.checkIfNotTokenExpired();
      if (isNotExpiresToken) {
        request = this._addAccessTokenToRequest(request);
      } else if (isNotExpiresRefreshToken) {
        return this._handlerRefresh(request, next);
      }
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const isRefreshingTokenRequest: boolean = request.url === this._authRefreshUrl;
        const isRefreshingTokenRequestError: boolean = [
          HttpTokenErrorStatusCode.INVALID_TOKEN,
          HttpTokenErrorStatusCode.TOKEN_EXPIRED
        ].includes(error.status);
        if (isRefreshingTokenRequest && isRefreshingTokenRequestError) {
          this._authChecker.logout();
        }
        return throwError(error);
      })
    );
  }

  private _addAccessTokenToRequest(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: this._apiConfig.authHeaders
    });
  }

  private _addRefreshTokenToRequest(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: this._apiConfig.authRefreshHeaders
    });
  }

  private _handlerRefresh(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this._refreshTokenInProgress) {
      this._refreshTokenInProgress = true;
      return this._authChecker.doRefreshToken().pipe(
        mergeMap((result: AuthToken | MessageError) => {
          this._authChecker.doOnRefreshToken(result);
          this._refreshTokenInProgress = false;
          return this._resendRequest(request, next);
        })
      );
    } else {
      return this._resendRequest(request, next);
    }
  }

  private _resendRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this._addAccessTokenToRequest(request));
  }
}
