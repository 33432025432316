import { format } from 'date-fns';

export function getDateRangeString(dates: (Date | undefined)[], enableTimeMode: boolean): string {
  if (dates[0] && dates[1]) {
    return `from ${format(dates[0], enableTimeMode ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd')} to ${format(
      dates[1],
      enableTimeMode ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'
    )}`;
  } else if (dates[0]) {
    return `from ${format(dates[0], enableTimeMode ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd')}`;
  }
  return '';
}
