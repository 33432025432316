import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { isDefined } from '@sap/logic/shared/utils/pure-utils';
import { rxDone } from '@sap/shared/helpers/rx-helpers';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { RefreshCounterCached } from '../../shared/refresh-counter-cached';

@Pipe({
  name: 'refreshCounter',
  pure: false
})
export class RefreshCounterPipe implements PipeTransform, OnDestroy {
  private _subDidChange: Subscription | undefined = undefined;
  private _onDestroy$: Subject<void> = new Subject<void>();

  public transform(sourceState: [number, boolean] | undefined): number | undefined {
    if (!isDefined(sourceState)) {
      return;
    }

    const [refreshInterval, refreshRateOn]: [number, boolean] = sourceState;
    if (
      isDefined(this._refreshCounterCached.sub) &&
      (!refreshRateOn || this._refreshCounterCached.refreshInterval !== refreshInterval)
    ) {
      this._refreshCounterCached.sub.unsubscribe();
      this._refreshCounterCached.sub = undefined;
    }

    if (!isDefined(this._refreshCounterCached.sub) && refreshRateOn) {
      this._refreshCounterCached.refreshInterval = refreshInterval;
      this._refreshCounterCached.refreshRateOn = refreshRateOn;
      this._refreshCounterCached.subscribeRefreshCounterSource('/bet-tracer');
      this._refreshCounterCached.refreshCounterSource$.next([refreshInterval, refreshRateOn]);
    }

    if (!isDefined(this._subDidChange) && refreshRateOn) {
      this._subscribeDetectChanges();
    }
    return this._refreshCounterCached.valueToReturn;
  }

  constructor(
    private _cdRef: ChangeDetectorRef,
    private _refreshCounterCached: RefreshCounterCached
  ) {}

  private _subscribeDetectChanges(): void {
    this._subDidChange = this._refreshCounterCached.didChange$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => this._cdRef.detectChanges());
  }

  public ngOnDestroy(): void {
    rxDone(this._onDestroy$);
  }
}
