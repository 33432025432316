import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[dragableHorizontalScroll]',
  standalone: true
})
export class DragableHorizontalScrollDirective {
  private _isDrag: boolean = false;
  private _startX: number = 0;

  constructor(private _elementRef: ElementRef) {}

  @HostListener('mousedown', ['$event'])
  public onMouseDown(event: MouseEvent): void {
    this._isDrag = true;
    this._startX = event.clientX + this._elementRef.nativeElement.scrollLeft;
  }

  @HostListener('document:mouseup', ['$event'])
  public onMouseUp(): void {
    this._isDrag = false;
  }

  @HostListener('mousemove', ['$event'])
  public onMouseMove(event: MouseEvent): void {
    if (this._isDrag) {
      const diffX: number = this._startX - (event.clientX + this._elementRef.nativeElement.scrollLeft);
      this._elementRef.nativeElement.scrollLeft += diffX;
    }
  }
}
