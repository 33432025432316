import { Params } from '@angular/router';
import { FiltersApi } from '@sap/logic/api-access/api/bet-tracer-api';
import { LoadMoreResult } from '@sap/logic/api-access/repository/bet-tracer-repository';
import { Betslip } from '@sap/logic/shared/models/betslip';
import { BrandType } from '@sap/shared/enums';
import * as R from 'rambdax';
import { filter, map, MonoTypeOperatorFunction, OperatorFunction } from 'rxjs';

export const mapToBetsSortedByDate: OperatorFunction<Betslip[], Betslip[]> = map((betslips: Betslip[]) =>
  R.piped(
    betslips,
    R.sort((betslipA: Betslip, betslipB: Betslip) => betslipB.date!.getTime() - betslipA.date!.getTime())
  )
);

export const mapToBetsSlicedTo100AndSortedByDate: OperatorFunction<Betslip[], Betslip[]> = map(
  (betslips: Betslip[]) =>
    R.piped(
      betslips,
      R.sort((betslipA: Betslip, betslipB: Betslip) => betslipB.date!.getTime() - betslipA.date!.getTime()),
      R.slice(0, 100)
    ) as Betslip[]
);

export const mapToBetsSlicedByOffsetAndSortedByDate: OperatorFunction<[Betslip[], number], Betslip[]> = map(
  ([betslips, offset]: [Betslip[], number]) =>
    R.piped(
      betslips,
      R.sort((betslipA: Betslip, betslipB: Betslip) => betslipB.date!.getTime() - betslipA.date!.getTime()),
      R.slice(0, offset * 100 + 100) // display 100 when offset is 0, 200 when 1, 300 when 2 ect.
    ) as Betslip[]
);

export const mapToCalcPayouts: OperatorFunction<Betslip[], number> = map((betslips: Betslip[]) =>
  R.piped(
    betslips,
    R.map((betslip: Betslip) => betslip.payout),
    R.reduce((sum: number, current: number) => R.add(sum, current), 0)
  )
);

export const mapToCalcTurnover: OperatorFunction<Betslip[], number> = map((betslips: Betslip[]) =>
  R.piped(
    betslips,
    R.map((betslip: Betslip) => betslip.stake),
    R.reduce((sum: number, current: number) => R.add(sum, current), 0)
  )
);

export const mapToBetsLength: OperatorFunction<Betslip[], number> = map((betslips: Betslip[]) =>
  R.piped(betslips, R.length)
);

export const mapToSelectedBrandsFromFilters: OperatorFunction<Params, BrandType[]> = map((filters: Params) =>
  R.prop('selectedBrands', filters)
);

export const mapToFiltersApi: OperatorFunction<[Params, BrandType], Params> = map(
  ([filters, initialBrand]: [Params, BrandType]) => {
    const brands: BrandType | BrandType[] | undefined = R.prop('selectedBrands', filters);
    return {
      ...filters,
      selectedBrands: brands ? (Array.isArray(brands) ? brands : [brands]) : [initialBrand]
    };
  }
);

export const filterNoAccessByBrands: MonoTypeOperatorFunction<BrandType[]> = filter((brands: BrandType[]) =>
  brands.some(Boolean)
);

export const filterNoViewInitiated: MonoTypeOperatorFunction<[[FiltersApi, number | void | undefined], boolean]> =
  filter(([[_filters], viewInitiated]: [[FiltersApi, number | void | undefined], boolean]) => viewInitiated);

export const filterNoViewInitiatedLoadMore: MonoTypeOperatorFunction<
  [[FiltersApi, number | void | undefined, number], boolean]
> = filter(([[_filters], viewInitiated]: [[FiltersApi, number | void | undefined, number], boolean]) => viewInitiated);

export const mapToFirstWithoutWithLatestFrom = <O1, O2>(): OperatorFunction<[O1, O2], O1> =>
  map((array: [O1, O2]) => array[0]);

export const accAddBetsResult12: <T, V extends LoadMoreResult>(result: V) => (acc: T) => Betslip[] =
  <T, V extends LoadMoreResult>(result: V) =>
  (acc: T): Betslip[] =>
    result.isRefreshing ? result.bets : R.concat(<[]>acc, result.bets);
