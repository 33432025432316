import { Params } from '@angular/router';
import { isDefined } from '@sap/logic/shared/utils/pure-utils';
import { parseISO } from 'date-fns';
import { getDateRangeTextareaString } from '../components/date-range-picker/pure-utils/get-date-range-textarea-string';

export function parseQueryParams<T>(arrayKeys: string[], params: Params): T {
  const copyParams: T = { ...params } as T;
  for (const key of arrayKeys) {
    copyParams[key] = params[key] ?? undefined;
  }
  return copyParams;
}

export function parseQueryParamsAsNumber<T>(arrayKeys: string[], params: Params): T {
  const copyParams: T = { ...params } as T;
  for (const key of arrayKeys) {
    copyParams[key] = isDefined(params[key]) ? Number(copyParams[key]) : undefined;
  }
  return copyParams;
}

export function parseDateQueryParams<
  T extends {
    dateTo: Date | undefined;
    dateFrom: Date | undefined;
    dateRange?: string | undefined;
  }
>(
  params: Params,
  setDateRange?: boolean,
  initial?: {
    dateTo: Date | undefined;
    dateFrom: Date | undefined;
  }
): T {
  const copyParams: T = { ...params } as T;
  copyParams.dateFrom = params.dateFrom ? parseISO(params.dateFrom) : initial?.dateFrom;
  copyParams.dateTo = params.dateTo ? parseISO(params.dateTo) : initial?.dateTo;
  if (setDateRange) {
    copyParams.dateRange = getDateRangeTextareaString([copyParams.dateFrom, copyParams.dateTo], true);
  }
  return copyParams;
}
