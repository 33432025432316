import * as R from 'rambdax';
import { SportsGroup } from '../models/sports-group';
import { mapToSportsGroupsWithoutUnassigned } from '../pure-utils/to-sports-groups-without-unassigned';

export function mapToDisciplinesWithUnassignedBrands([disciplines, childrenToSports]: [
  SportsGroup[],
  Record<number, SportsGroup[]>
]): number[] {
  const sportGroupsIdWithUnassignedBrands: number[] = mapToSportsGroupsWithoutUnassigned(disciplines, true).map(
    R.prop('id')
  );
  // check that the discipline doesn't have a region or league with brand visibility assigned to the provider
  for (let index: number = 0; index < sportGroupsIdWithUnassignedBrands.length; index++) {
    if (childrenToSports[sportGroupsIdWithUnassignedBrands[index]]) {
      const hasSomeBrandVisibility: boolean = Boolean(
        mapToSportsGroupsWithoutUnassigned(childrenToSports[sportGroupsIdWithUnassignedBrands[index]]).length
      );
      if (hasSomeBrandVisibility) {
        sportGroupsIdWithUnassignedBrands.splice(index, 1);
      }
    }
  }
  return sportGroupsIdWithUnassignedBrands;
}
