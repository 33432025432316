export enum LocalStorageKeys {
  authToken = 'authToken',
  displayColumnsBetTracer = 'displayColumnsBetTracer',
  displayColumnsWidthBetTracer = 'displayColumnsWidthBetTracer'
}

export enum ColumnsKeys {
  sportGroupsRestrictions = 'sportGroupsRestrictions',
  supplyManager = 'supplyManager',
  createProfile = 'createProfile',
  sportGroupsSorting = 'sportGroupsSorting'
}

export enum SessionStorageKeys {
  lastAddedManualEventSuccess = 'lastAddedManualEventSuccess',
  lastAddedManualMarketSuccess = 'lastAddedManualMarketSuccess',
  betTracerFiltersVisibility = 'betTracerFiltersVisibility',
  scrollTopPositions = 'scrollTopPositions',
  expandedSportsGroupIds = 'expandedSportsGroupIds',
  expandedSportsGroupIdsSupplyManager = 'expandedSportsGroupIdsSupplyManager',
  expandedSportsGroupIdsSorting = 'expandedSportsGroupIdsSorting',
  expandedBetRowsIds = 'expandedBetRowsIds',
  expandedSportsGroupIdsCreateProfile = 'expandedSportsGroupIdsCreateProfile',
  selectedBrandsOnColumns = 'selectedBrandsOnColumns',
  selectedBrandInfosOnColumns = 'selectedBrandInfosOnColumns',
  columnsCount = 'columnsCount',
  columnsCountCreateProfile = 'columnsCountCreateProfile',
  columnsCountSorting = 'columnsCountSorting',
  selectedBrand = 'selectedBrand',
  lastRouterUrl = 'lastRouterUrl',
  routerVisitedUrls = 'routerVisitedUrls'
}

export enum BrandType {
  default = 'default',
  lvbetLv = 'lvbet.lv',
  lvbetPl = 'lvbet.pl',
  lvbetCom = 'lvbet.com',
  letsbetmdCom = 'letsbetmd.com'
}

export enum BrandTypeWithoutDots {
  default = 'default',
  noblebetPl = 'noblebetpl',
  lvbetLv = 'lvbetlv',
  lvbetPl = 'lvbetpl',
  lvbetCom = 'lvbetcom',
  letsbetmdCom = 'letsbetmdcom',
  hotslotsIo = 'hotslotsio'
}

export enum MatchOfferProvider {
  sportCast = 'sc',
  betConstruct = 'bc',
  betGenius = 'bg',
  unassigned = 'u',
  manualApi = 'm',
  related = 'rel'
}

export enum ReversedMatchOfferProvider {
  sc = 'sportCast',
  bc = 'betConstruct',
  bg = 'betGenius',
  u = 'unassigned',
  m = 'manualApi',
  rel = 'related'
}

export enum OfferCategory {
  league = 'league',
  event = 'event'
}

export enum ModuleScope {
  sportGroupsSorting = 'sportGroupsSorting',
  sportGroupsRestrictions = 'sportGroupsRestrictions',
  eventRestrictions = 'eventRestrictions',
  supplyManager = 'supplyManager',
  marketClassesSetup = 'marketClassesSetup',
  marketGroups = 'marketGroups',
  primaryColumns = 'primaryColumns',
  profileCreate = 'profileCreate',
  profileEdit = 'profileEdit',
  competitionMapping = 'competitionMapping',
  manualOffer = 'manualOffer',
  lowerTax = 'lowerTax',
  rateBoost = 'rateBoost',
  eventView = 'eventView',
  resultingManager = 'resultingManager',
  streamMapping = 'streamMapping',
  sportcastMapping = 'sportcastMapping',
  matchesList = 'matchesList',
  matchesSupply = 'matchesSupply',
  streamings = 'streamings',
  translations = 'translations',
  users = 'users',
  recommendedStandard = 'recommendedStandard',
  recommendedEsports = 'recommendedEsports',
  betTracer = 'betTracer',
  failedTransactions = 'failedTransactions',
  customers = 'customers',
  apiTokens = 'apiTokens',
  locations = 'locations',
  terminals = 'terminals',
  agents = 'agents',
  clientReports = 'clientReports',
  maintenance = 'maintenance',
  countryLimits = 'countryLimits'
}

export enum RoleScope {
  //dummy
  dummyRestrictionsView = 'dummyRestrictionsView',
  dummyCreateProfileView = 'dummyCreateProfileView',
  dummyCreateProfileEdit = 'dummyCreateProfileEdit',
  dummyEditProfileView = 'dummyEditProfileView',
  dummyEditProfileEdit = 'dummyEditProfileEdit',
  dummySupplyManagerView = 'dummySupplyManagerView',
  dummySupplyManagerEdit = 'dummySupplyManagerEdit',
  dummyPrimaryColumnsView = 'dummyPrimaryColumnsView',
  dummyPrimaryColumnsEdit = 'dummyPrimaryColumnsEdit',
  dummyStreamMappingView = 'dummyStreamMappingView',
  dummyStreamMappingEdit = 'dummyStreamMappingEdit',
  dummySportcastMappingView = 'dummySportcastMappingView',
  dummySportcastMappingEdit = 'dummySportcastMappingEdit',
  dummyMarketsClassesView = 'dummyMarketsClassesView',
  dummyMarketsClassesEdit = 'dummyMarketsClassesEdit',
  dummySportsGroupSortingView = 'dummySportsGroupSortingView',
  dummySportsGroupSortingEdit = 'dummySportsGroupSortingEdit',
  dummyResultingManagerView = 'dummyResultingManagerView',
  dummyResultingManagerEdit = 'dummyResultingManagerEdit',
  dummyMatchesListEdit = 'dummyMatchesListEdit',

  // manual api
  manualApiEventPost = 'manual_api:event:post',
  manualApiMarketPost = 'manual_api:market:post',

  // auth api: roles
  authRolesGet = 'auth:roles:get',
  authRolesPost = 'auth:roles:post',
  authRolesPut = 'auth:roles:put',
  authRolesDelete = 'auth:roles:delete',
  authRolesDeleteScope = 'auth:roles:delete_scope',

  // auth api: users
  authUsersDelete = 'auth:users:delete',
  authUsersGet = 'auth:users:get',
  authUsersGetByEmail = 'auth:users:get_by_email',
  authUsersPost = 'auth:users:post',
  authUsersPut = 'auth:users:put',
  authUsersChangePasswordPut = 'auth:users_change_password:put',
  authUsersResetPasswordPut = 'auth:users_reset_password:put',
  authUsersSearchGet = 'auth:users_search:get',

  // offer mgr: tokens
  offerMgrApiTokensGet = 'offer_mgr:api_tokens:get',
  offerMgrApiTokensPost = 'offer_mgr:api_tokens:post',
  offerMgrApiTokensDelete = 'offer_mgr:api_tokens:delete',
  offerMgrApiTokensSearch = 'offer_mgr:api_tokens_search:get',

  // offer mgr: brands
  offerMgrBrandsGet = 'offer_mgr:brands:get',
  offerMgrBrandsSearch = 'offer_mgr:brands_search:get',

  // offer mgr: lower_taxes
  offerMgrLowerTaxesGet = 'offer_mgr:lower_taxes:get',
  offerMgrLowerTaxesPost = 'offer_mgr:lower_taxes:post',
  offerMgrLowerTaxesCopyPost = 'offer_mgr:lower_taxes_copy:post',
  offerMgrLowerTaxesPut = 'offer_mgr:lower_taxes:put',
  offerMgrLowerTaxesSearch = 'offer_mgr:lower_taxes_search:get',
  offerMgrLowerTaxesDelete = 'offer_mgr:lower_taxes:delete',

  // offer mgr: market_types
  offerMgrMarketTypesGet = 'offer_mgr:market_types:get',
  offerMgrMarketTypesPut = 'offer_mgr:market_types:put',
  offerMgrMarketTypesSearchGet = 'offer_mgr:market_types_search:get',

  // offer mgr: markets_classes
  offerMgrMarketsClassesDelete = 'offer_mgr:markets_classes:delete',
  offerMgrMarketsClassesGet = 'offer_mgr:markets_classes:get',
  offerMgrMarketsClassesPost = 'offer_mgr:markets_classes:post',
  offerMgrMarketsClassesGetByMarketType = 'offer_mgr:markets_classes_by_market_type:get',
  offerMgrMarketsClassesSearch = 'offer_mgr:markets_classes_search:get',
  offerMgrMarketsClassesPatch = 'offer_mgr:markets_classes:patch',
  offerMgrMarketsClassesPut = 'offer_mgr:markets_classes:put',

  // offer mgr: markets_groups
  offerMgrMarketsGroupsPost = 'offer_mgr:markets_groups:post',
  offerMgrMarketsGroupsPut = 'offer_mgr:markets_groups:put',
  offerMgrMarketsGroupsSearch = 'offer_mgr:markets_groups_search:get',
  offerMgrMarketsGroupsDelete = 'offer_mgr:markets_groups:delete',

  // offer mgr: primary_columns
  offerMgrPrimaryColumnsDelete = 'offer_mgr:primary_columns:delete',
  offerMgrPrimaryColumnsGet = 'offer_mgr:primary_columns:get',
  offerMgrPrimaryColumnsPost = 'offer_mgr:primary_columns:post',
  offerMgrPrimaryColumnsPut = 'offer_mgr:primary_columns:put',
  offerMgrPrimaryColumnsPutBrands = 'offer_mgr:primary_columns_brands:put',
  offerMgrPrimaryColumnsGetByMarketClass = 'offer_mgr:primary_columns_by_markets_class:get',
  offerMgrPrimaryColumnsSearch = 'offer_mgr:primary_columns_search:get',

  // offer mgr: profiles
  offerMgrProfilesDelete = 'offer_mgr:profiles:delete',
  offerMgrProfilesGet = 'offer_mgr:profiles:get',
  offerMgrProfilesPost = 'offer_mgr:profiles:post',
  offerMgrProfilesPut = 'offer_mgr:profiles:put',
  offerMgrProfilesGetMarketClasses = 'offer_mgr:profiles_market_classes:get',
  offerMgrProfilesPatchMarketClasses = 'offer_mgr:profiles_market_classes:patch',
  offerMgrProfilesPutMarketClasses = 'offer_mgr:profiles_market_classes:put',
  offerMgrProfilesGetToSportsGroupsCount = 'offer_mgr:profiles_to_sports_groups_count:get',
  offerMgrProfilesDeleteToSportsGroups = 'offer_mgr:profiles_to_sports_groups:delete',
  offerMgrProfilesGetToSportsGroups = 'offer_mgr:profiles_to_sports_groups:get',
  offerMgrProfilesPostToSportsGroups = 'offer_mgr:profiles_to_sports_groups:post',
  offerMgrProfilesPutToSportsGroups = 'offer_mgr:profiles_to_sports_groups:put',
  offerMgrProfilesSearchMarketClasses = 'offer_mgr:profiles_market_classes_search:get',
  offerMgrProfilesSearchToSportsGroups = 'offer_mgr:profiles_to_sports_groups_search:get',
  offerMgrProfilesSearch = 'offer_mgr:profiles_search:get',

  // offer mgr: rate_boosts
  offerMgrRateBoostsDelete = 'offer_mgr:rate_boosts:delete',
  offerMgrRateBoostsGet = 'offer_mgr:rate_boosts:get',
  offerMgrRateBoostsPost = 'offer_mgr:rate_boosts:post',
  offerMgrRateBoostsPut = 'offer_mgr:rate_boosts:put',
  offerMgrRateBoostsPostCopy = 'offer_mgr:rate_boosts_copy:post',
  offerMgrRateBoostsSearch = 'offer_mgr:rate_boosts_search:get',

  // offer mgr: sports
  offerMgrSportsSearchGet = 'offer_mgr:sports_search:get',

  // offer mgr: regions
  offerMgrRegionsSearchGet = 'offer_mgr:regions_search:get',

  // offer mgr: competitions
  offerMgrCompetitionsSearchGet = 'offer_mgr:competitions_search:get',
  offerMgrCompetitionsCountGet = 'offer_mgr:competitions_count:get',
  offerMgrCompetitionsPut = 'offer_mgr:competitions:put',
  offerMgrGetMatchByIdPost = 'offer_mgr:get_match_by_id:post',

  // offer mgr: sports_groups
  offerMgrSportsGroupsGet = 'offer_mgr:sports_groups:get',
  offerMgrSportsGroupsPost = 'offer_mgr:sports_groups:post',
  offerMgrSportsGroupsCompetitionDelete = 'offer_mgr:sports_groups:delete_competition',
  offerMgrSportsGroupsPutBrandsVisibility = 'offer_mgr:sports_groups_brands_visibility:put',
  offerMgrSportsGroupsPutBrandsOrder = 'offer_mgr:sports_groups_order:put',
  offerMgrSportsGroupsGetChildren = 'offer_mgr:sports_groups_children:get',
  offerMgrSportsGroupsGetCount = 'offer_mgr:sports_groups_count:get',
  offerMgrSportsGroupsPutEventRestriction = 'offer_mgr:sports_groups_event_restriction:put',
  offerMgrSportsGroupsGetProviders = 'offer_mgr:sports_groups_providers:get',
  offerMgrSportsGroupsSearchProviders = 'offer_mgr:sports_groups_providers_search:get',
  offerMgrSportsGroupsSearch = 'offer_mgr:sports_groups_search:get',

  // offer mgr: restrictions_sets
  offerMgrRestrictionsSetsSearchGet = 'offer_mgr:restrictions_sets_search:get',
  offerMgrRestrictionsSetsGet = 'offer_mgr:restrictions_sets:get',
  offerMgrRestrictionsSetsDelete = 'offer_mgr:restrictions_sets:delete',
  offerMgrRestrictionsSetsPost = 'offer_mgr:restrictions_sets:post',
  offerMgrRestrictionsSetsPut = 'offer_mgr:restrictions_sets:put',

  // client bo api: betslips
  clientsBoApiBetslipsGetSearch = 'client_bo_api:betslips_search:get',
  clientsBoApiBetslipsRefreshStatusPutBet = 'client_bo_api:refresh_betslip_status:put',
  clientsBoApiBetslipsForceStatusPatchBet = 'client_bo_api:betslips_force_status_bet:patch',
  clientsBoApiBetslipsForceStatusPatchBetItem = 'client_bo_api:betslips_force_status_bet_item:patch',
  clientsBoApiBetslipsForceStatusPatchBetslip = 'client_bo_api:betslips_force_status_betslip:patch',
  clientsBoApiBetslipsForceStatusPatchVirtualBetslip = 'client_bo_api:betslips_force_status_virtual_betslip:patch',

  // client bo api: clients
  clientsBoApiClientsGet = 'client_bo_api:clients:get',
  clientsBoApiClientsPut = 'client_bo_api:clients:put',
  clientsBoApiClientsPutAnonymize = 'client_bo_api:clients_anonymize:put',
  clientsBoApiClientsPutInvalidateBets = 'client_bo_api:clients_invalidate_bets:put',
  clientsBoApiClientsDelete = 'client_bo_api:clients_notes:delete',
  clientsBoApiClientsGetNotes = 'client_bo_api:clients_notes:get',
  clientsBoApiClientsPostNotes = 'client_bo_api:clients_notes:post',
  clientsBoApiClientsPutNotes = 'client_bo_api:clients_notes:put',
  clientsBoApiClientsGetSearch = 'client_bo_api:clients_search:get',
  clientsBoApiClientsPutSetAsTest = 'client_bo_api:clients_set_as_test:put',
  clientsBoApiClientsGetStatistics = 'client_bo_api:clients_statistics:get',

  // client bo api: transactions
  clientsBoApiTransactionsGet = 'client_bo_api:transactions_search:get',

  // client bo api: locations
  clientsBoApiLocationsGet = 'client_bo_api:locations:get',
  clientsBoApiLocationsPost = 'client_bo_api:locations:post',
  clientsBoApiLocationsPut = 'client_bo_api:locations:put',
  clientsBoApiLocationsGetSearch = 'client_bo_api:locations_search:get',

  // client bo api: maintenance mode
  clientsBoApiMaintenanceModeGet = 'client_bo_api:maintenance_mode:get',
  clientsBoApiMaintenanceModePut = 'client_bo_api:maintenance_mode:put',
  clientsBoApiMaintenanceModeGetSearch = 'client_bo_api:maintenance_mode_search:get',

  // offer api: markets
  offerApiMarketsGet = 'offer_api:markets:get',
  offerApiMarketsPatch = 'offer_api:markets:patch',
  offerApiMarketsGetByMatchId = 'offer_api:markets_match:get',
  offerApiMarketsGetSearch = 'offer_api:markets_search:get',
  offerApiMarketsGetSortingColumns = 'offer_api:markets_sorting:get',
  offerApiMarketsPutVoidSelectionsForMatch = 'offer_api:markets_void_all_selections:put',

  // offer api: matches
  offerApiMatchesGet = 'offer_api:matches:get',
  offerApiMatchesPatch = 'offer_api:matches:patch',
  offerApiMatchesGetComments = 'offer_api:matches_comments:get',
  offerApiMatchesPutComments = 'offer_api:matches_comments:put',
  offerApiMatchesPutForceLive = 'offer_api:matches_force_live:put',
  offerApiMatchesGetSearch = 'offer_api:matches_search:get',
  offerApiRelatedMatchIdPut = 'offer_api:related_match_id:put',

  // offer api: market_risk
  offerApiMarketRiskGetSearch = 'offer_api:market_risk_search:get',

  // offer api: recommendations
  offerApiRecommendationsDelete = 'offer_api:recommendations:delete',
  offerApiRecommendationsPost = 'offer_api:recommendations:post',
  offerApiRecommendationsPut = 'offer_api:recommendations:put',
  offerApiRecommendationsGet = 'offer_api:recommendations_search:get',

  // offer api: recommendations_esports
  offerApiRecommendationsEsportsDelete = 'offer_api:recommendations_esports:delete',
  offerApiRecommendationsEsportsPost = 'offer_api:recommendations_esports:post',
  offerApiRecommendationsEsportsPut = 'offer_api:recommendations_esports:put',
  offerApiRecommendationsEsportsGet = 'offer_api:recommendations_esports_search:get',

  // offer api: selections
  offerApiSelectionsPut = 'offer_api:selections:put',

  // offer api: streamings
  offerApiStreamingsGet = 'offer_api:streamings:get',
  offerApiStreamingsGetSearch = 'offer_api:streamings_search:get',
  offerApiStreamingsPost = 'offer_api:streamings:post',
  offerApiStreamingsPut = 'offer_api:streamings:put',
  offerApiStreamingsGetBetradar = 'offer_api:streamings_betradar:get',
  offerApiStreamingsGetSearchBetradar = 'offer_api:streamings_betradar_search:get',
  offerApiStreamingsPutBetradar = 'offer_api:streamings_betradar:put',
  offerApiStreamingsPutMap = 'offer_api:streamings_map:put',
  offerApiStreamingsGetProviders = 'offer_api:streamings_providers:get',

  // client_bo_reporting
  clientBOReportingClientReportsGet = 'client_bo_reporting:clients_report:get',

  // translation mgr
  translationsMgrGet = 'translation_mgr:languages:get',
  translationsMgrDelete = 'translation_mgr:translation:delete',
  translationsMgrPost = 'translation_mgr:translation:post',
  translationsMgrPut = 'translation_mgr:translation:put',
  translationsMgrGetSearch = 'translation_mgr:translation_search:get',
  translationsMgrGetTranslationTypes = 'translation_mgr:translation_types:get',

  // client_bo_api: country_limits
  clientsBoApiCountryLimitsGet = 'client_bo_api:country_limits:get',
  clientsBoApiCountryLimitsPost = 'client_bo_api:country_limits:post',
  clientsBoApiCountryLimitsPut = 'client_bo_api:country_limits:put',
  clientsBoApiCountryLimitsDelete = 'client_bo_api:country_limits:delete',
  clientsBoApiCountryLimitsGetSearch = 'client_bo_api:country_limits_search:get',

  // client_bo_api: terminals
  clientsBoApiTerminalsGet = 'client_bo_api:terminals:get',
  clientsBoApiTerminalsPut = 'client_bo_api:terminals:put',
  clientsBoApiTerminalsDelete = 'client_bo_api:terminals:delete',
  clientsBoApiTerminalsGetSearch = 'client_bo_api:terminals_search:get',

  // client_bo_api: agents
  clientsBoApiAgentsGet = 'client_bo_api:agents:get',
  clientsBoApiAgentsPost = 'client_bo_api:agents:post',
  clientsBoApiAgentsPut = 'client_bo_api:agents:put',
  clientsBoApiAgentsSearchGet = 'client_bo_api:agents_search:get'
}
