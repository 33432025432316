import { Injectable } from '@angular/core';
import { SharedBrand } from '@sap/logic/shared/brand/shared-brand';
import { BrandType, SessionStorageKeys } from '@sap/shared/enums';
import { transferRxNext } from '@sap/shared/helpers/rx-helpers';
import { SessionStorageService } from '@sap/shared/services/session-storage.service';
import { ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BrandSettings {
  public selectedBrand$: ReplaySubject<BrandType> = new ReplaySubject<BrandType>(1);

  constructor(
    private _sessionStorage: SessionStorageService,
    private _sharedBrand: SharedBrand
  ) {}

  public init(): void {
    const brand: BrandType | undefined = this._sessionStorage.getString(SessionStorageKeys.selectedBrand) as BrandType;

    this._sharedBrand.getInitialBrand(undefined, brand).subscribe(transferRxNext(this.selectedBrand$));

    this.selectedBrand$.subscribe((brand: BrandType) =>
      this._sessionStorage.setString(SessionStorageKeys.selectedBrand, brand)
    );
  }
}
