import { SimpleChanges } from '@angular/core';

export function whenChanged<T, V>(
  changes: SimpleChanges,
  prop: keyof T,
  distinctUntilChangedFn: (previous: V, current: V) => boolean,
  whenDiff: (a: V) => void
): void {
  const fixedPropType: string = prop as string;
  if (!changes[fixedPropType]) {
    return;
  }
  if (!distinctUntilChangedFn(changes[fixedPropType].previousValue, changes[fixedPropType].currentValue)) {
    whenDiff(changes[fixedPropType].currentValue);
  }
}

export function whenChangedCast<V>(
  changes: SimpleChanges,
  prop: string,
  distinctUntilChangedFn: (previous: V, current: V) => boolean,
  whenDiff: (a: V) => void
): void {
  const fixedPropType: string = prop as string;
  if (!changes[fixedPropType]) {
    return;
  }
  if (!distinctUntilChangedFn(changes[fixedPropType].previousValue, changes[fixedPropType].currentValue)) {
    whenDiff(changes[fixedPropType].currentValue);
  }
}

export function changesExtract<T, V>(changes: SimpleChanges, prop: keyof T, fnWithValue: (a: V) => void): void {
  const fixedPropType: string = prop as string;
  if (changes[fixedPropType]) {
    fnWithValue(changes[fixedPropType].currentValue);
  }
}
