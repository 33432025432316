import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Filters, FiltersApi } from '@sap/logic/api-access/api/bet-tracer-api';
import { BetTracerRepository, LoadMoreResult } from '@sap/logic/api-access/repository/bet-tracer-repository';
import { BetInternalCommentDatabase } from '@sap/logic/firestore-database/bet-internal-comment-database';
import { MatchInternalCommentDatabase } from '@sap/logic/firestore-database/match-internal-comment-database';
import { IndexedMatchComments } from '@sap/logic/firestore-database/types';
import { SharedBrand } from '@sap/logic/shared/brand/shared-brand';
import { Betslip } from '@sap/logic/shared/models/betslip';
import { BettingLocation } from '@sap/logic/shared/models/betting-location';
import { clearAccumulatedArray } from '@sap/logic/shared/pure-utils/to-clear-accumulated-array';
import {
  distinctUntilArrayLengthChanged,
  distinctUntilEmittedValuesChanged,
  scanArrayWithAddClear
} from '@sap/logic/shared/rxjs/operators';
import { BrandType } from '@sap/shared/enums';
import { omitKeys } from '@sap/shared/helpers/pure-utils';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  distinctUntilChanged,
  map,
  merge,
  mergeMap,
  shareReplay,
  tap,
  withLatestFrom
} from 'rxjs';
import { RefreshStateCached } from '../../shared/refresh-state-cached';
import { BetTracerStreams } from '../bet-tracer-streams';
import {
  accAddBetsResult12,
  filterNoViewInitiated,
  filterNoViewInitiatedLoadMore,
  mapToBetsLength,
  mapToBetsSlicedByOffsetAndSortedByDate,
  mapToCalcPayouts,
  mapToCalcTurnover,
  mapToFiltersApi,
  mapToFirstWithoutWithLatestFrom,
  mapToSelectedBrandsFromFilters
} from '../rx-operators';

@Injectable()
export class BetTracerOtherViewsData {
  public isLoadingMoreBets$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public noAccessByBrands$: Subject<boolean> = new Subject<boolean>();

  public isLoading$: ReplaySubject<boolean> = this._streams.isLoading$;
  public isLoadingBettingLocations$: ReplaySubject<boolean> = this._streams.isLoadingBettingLocations$;
  public betsNotFound$: ReplaySubject<boolean> = this._streams.betsNotFound$;
  public filters$: ReplaySubject<Filters> = this._streams.filtersStateCached$;
  public viewInitiated$: ReplaySubject<boolean> = this._streams.viewInitiated$;
  public doRefresh$: BehaviorSubject<[number, boolean]> = this._refreshStateCached.doRefresh$;
  public doForceRefresh$: Subject<void> = this._refreshStateCached.doForceRefresh$;

  private _betslipStateShareRef$: Observable<Betslip[]> = combineLatest([
    this._fetchBets(),
    this._scanLoadMoreData()
  ]).pipe(
    map(([bets, moreBets]: [Betslip[], Betslip[]]) => [...bets, ...moreBets]),
    shareReplay({ bufferSize: 4, refCount: true })
  );

  constructor(
    private _betTracerRepository: BetTracerRepository,
    private _sharedBrand: SharedBrand,
    private _refreshStateCached: RefreshStateCached,
    private _streams: BetTracerStreams,
    private _matchComments: MatchInternalCommentDatabase,
    private _betComments: BetInternalCommentDatabase
  ) {}

  public getBets(): Observable<Betslip[]> {
    return this._betslipStateShareRef$.pipe(
      withLatestFrom(this._streams.currentLoadMoreBetsOffset$),
      mapToBetsSlicedByOffsetAndSortedByDate,
      tap((bets: Betslip[]) => this._streams.currentDisplayedBets$.next(bets.length))
    );
  }

  public getCalculatedPayouts(): Observable<number> {
    return this._betslipStateShareRef$.pipe(mapToCalcPayouts);
  }

  public getCalculatedTurnover(): Observable<number> {
    return this._betslipStateShareRef$.pipe(mapToCalcTurnover);
  }

  public getIsMoreBetsToDisplay(): Observable<boolean> {
    return combineLatest([
      this._betslipStateShareRef$.pipe(mapToBetsLength, distinctUntilChanged()),
      this._streams.currentDisplayedBets$.pipe(distinctUntilChanged()),
      this._streams.isLoadMoreAvailableFromApi$
    ]).pipe(
      map(
        ([fetchedBets, displayedBets, isLoadMoreAvailableFromApi]: [number, number, boolean]) =>
          fetchedBets > displayedBets || isLoadMoreAvailableFromApi
      )
    );
  }

  public getBettingLocations(): Observable<BettingLocation[]> {
    return this._getSelectedBrands().pipe(
      tap(() => this.isLoadingBettingLocations$.next(true)),
      mergeMap(this._betTracerRepository.getBettingLocations()),
      tap(() => this.isLoadingBettingLocations$.next(false)),
      shareReplay(1)
    );
  }

  public getIndexedMatchComments(): Observable<IndexedMatchComments> {
    return this._matchComments.getIndexedMatchComments();
  }

  public getIndexedBetComments(): Observable<IndexedMatchComments> {
    return this._betComments.getIndexedBetComments();
  }

  private _fetchBets(): Observable<Betslip[]> {
    return combineLatest([this._getFilters(), this._refreshStateCached.getRefreshingState()]).pipe(
      withLatestFrom(this._streams.viewInitiated$),
      filterNoViewInitiated,
      mapToFirstWithoutWithLatestFrom(),
      tap(() => {
        this.betsNotFound$.next(false);
        this.isLoading$.next(true);
      }),
      mergeMap(this._betTracerRepository.getBetsFilteredAndSorted()),
      tap((betslips: Betslip[]) => {
        this.betsNotFound$.next(betslips.length === 0);
        this.isLoading$.next(false);
      })
    );
  }

  private _fetchMoreBets(): Observable<LoadMoreResult> {
    return combineLatest([
      this._getFilters().pipe(),
      this._refreshStateCached.getRefreshingState(),
      this._streams.doLoadMoreBetsFromApi$.pipe(distinctUntilChanged())
    ]).pipe(
      withLatestFrom(this._streams.viewInitiated$),
      filterNoViewInitiatedLoadMore,
      mapToFirstWithoutWithLatestFrom(),
      tap(() => this.isLoadingMoreBets$.next(true)),
      mergeMap(this._betTracerRepository.getMoreBetsFilteredAndSorted()),
      tap((result: LoadMoreResult) => {
        this._streams.isLoadMoreAvailableFromApi$.next(result.loadMoreAvailable);
        this.isLoadingMoreBets$.next(false);
      })
    );
  }

  private _scanLoadMoreData(): Observable<Betslip[]> {
    return merge(
      this._fetchMoreBets().pipe(map(accAddBetsResult12)),
      this._streams.doResetLoadMore$.pipe(map(clearAccumulatedArray<Betslip>()))
    ).pipe(scanArrayWithAddClear());
  }

  private _getSelectedBrands(): Observable<BrandType[]> {
    return this._getFilters().pipe(
      mapToSelectedBrandsFromFilters,
      tap((selectedBrands: BrandType[]) => this.noAccessByBrands$.next(!selectedBrands.some(Boolean))),
      distinctUntilArrayLengthChanged()
    );
  }

  private _getFilters(): Observable<FiltersApi> {
    return this.filters$.pipe(
      map((filters: Params) => omitKeys(filters, ['oddFormat'])),
      withLatestFrom(this._sharedBrand.getInitialBrand(BrandType.default)),
      mapToFiltersApi,
      distinctUntilEmittedValuesChanged()
    );
  }
}
