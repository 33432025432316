import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventRestrictionKind } from '@sap/logic/shared/enums';
import {
  mapToBrandTypeWithoutDots,
  SportsGroup,
  SportsGroupBrandsVisibility
} from '@sap/logic/shared/models/sports-group';
import { SportGroupsOrderPayload } from '@sap/logic/sports-groups-sorting/bloc/sports-groups-sorting/sports-groups-order-form';
import { SharedSettings } from '@sap/shared-settings/shared-settings';
import { BrandType, BrandTypeWithoutDots } from '@sap/shared/enums';
import { catchError, lastValueFrom, map, pluck } from 'rxjs';
import { ApiConfig } from '../api-config';
import { ApiForbiddenDetector, MethodError } from '../detectors/api-forbidden-detector';
import { MessageError } from '../errors/message-error';

export type SportsGroupRestrictionResponseWithBrand = {
  brand: BrandTypeWithoutDots;
  sportsGroup: SportsGroup;
};

@Injectable({ providedIn: 'root' })
export class SportsGroupApi {
  constructor(
    private _sharedSettings: SharedSettings,
    private _http: HttpClient,
    private _apiConfig: ApiConfig,
    private _apiForbiddenDetector: ApiForbiddenDetector
  ) {}

  public getSportsGroups(offset: number, limit: number): Promise<SportsGroup[]> {
    return lastValueFrom(
      this._http
        .get<Record<string, any>[]>(
          `${this._sharedSettings.environment.offerMgrUrl}offer-mgr/v4/sports-groups/?offset=${offset}&limit=${limit}`
        )
        .pipe(map(SportsGroup.fromJsonArray), catchError(this._apiForbiddenDetector.detectOnHttpError()))
    );
  }

  public getSportsGroupsByParentId(parentId: number): Promise<SportsGroup[]> {
    return lastValueFrom(
      this._http
        .get<Record<string, any>[]>(
          `${this._sharedSettings.environment.offerMgrUrl}offer-mgr/v4/sports-groups/children/${parentId}`
        )
        .pipe(map(SportsGroup.fromJsonArray), catchError(this._apiForbiddenDetector.detectOnHttpError()))
    );
  }

  public getSportsGroupsCount(): Promise<number> {
    return lastValueFrom(
      this._http
        .get<Record<string, any>>(`${this._sharedSettings.environment.offerMgrUrl}offer-mgr/v4/sports-groups/count/`)
        .pipe(pluck('count'), catchError(this._apiForbiddenDetector.detectOnHttpError()))
    );
  }

  public updateSportsGroupRestriction(
    sportsGroup: SportsGroup,
    brand: BrandType,
    restriction: EventRestrictionKind
  ): Promise<SportsGroupRestrictionResponseWithBrand | MessageError> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const body: Record<string, EventRestrictionKind> = { sports_group_restriction: restriction };
    return lastValueFrom(
      this._http
        .put<Record<string, any>>(
          `${this._sharedSettings.environment.offerMgrUrl}offer-mgr/v4/sports-groups/${sportsGroup.id}/event-restriction`,
          body,
          { ...this._apiConfig.authOptions, params: { brand } }
        )
        .pipe(
          map((json: Record<string, any>) => {
            return { brand: mapToBrandTypeWithoutDots(brand), sportsGroup: SportsGroup.fromJson(json) };
          }),
          catchError(this._apiForbiddenDetector.detectOnHttpError(MethodError.write)),
          MessageError.fromHttpError()
        )
    );
  }

  public updateSportsGroupOrder({
    sportsGroupId,
    order
  }: SportGroupsOrderPayload): Promise<SportsGroup | MessageError> {
    return lastValueFrom(
      this._http
        .put<Record<string, any>>(
          `${this._sharedSettings.environment.offerMgrUrl}offer-mgr/v4/sports-groups/${sportsGroupId}/order`,
          {
            order
          },
          { ...this._apiConfig.authOptions }
        )
        .pipe(
          map(SportsGroup.fromJson),
          catchError(this._apiForbiddenDetector.detectOnHttpError(MethodError.write)),
          MessageError.fromHttpError()
        )
    );
  }

  public updateSportsGroupsBrandVisibility(
    editedSportsGroup: SportsGroup,
    brand: BrandType
  ): Promise<SportsGroup | MessageError> {
    const body: Record<string, SportsGroupBrandsVisibility> = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      brands_visibility: editedSportsGroup.brandsVisibility
    };
    return lastValueFrom(
      this._http
        .put<Record<string, any>>(
          `${this._sharedSettings.environment.offerMgrUrl}offer-mgr/v4/sports-groups/${editedSportsGroup.id}/brands-visibility`,
          body,
          { ...this._apiConfig.authOptions, params: { brand } }
        )
        .pipe(
          map(SportsGroup.fromJson),
          catchError(this._apiForbiddenDetector.detectOnHttpError(MethodError.write)),
          MessageError.fromHttpError()
        )
    );
  }

  public addSportGroup(payload: Record<string, any>): Promise<SportsGroup | MessageError> {
    return lastValueFrom(
      this._http
        .post<Record<string, any>[]>(
          `${this._sharedSettings.environment.offerMgrUrl}offer-mgr/v4/sports-groups/`,
          payload
        )
        .pipe(
          map(SportsGroup.fromJson),
          catchError(this._apiForbiddenDetector.detectOnHttpError(MethodError.write)),
          MessageError.fromHttpError()
        )
    );
  }

  public removeSportGroup(id: number): Promise<SportsGroup | MessageError> {
    return lastValueFrom(
      this._http
        .delete<Record<string, any>[]>(
          `${this._sharedSettings.environment.offerMgrUrl}offer-mgr/v4/sports-groups/${id}`
        )
        .pipe(
          map(SportsGroup.fromJson),
          catchError(this._apiForbiddenDetector.detectOnHttpError(MethodError.write)),
          MessageError.fromHttpError()
        )
    );
  }
}
