import { Pipe, PipeTransform } from '@angular/core';
import { DateConstants } from '@sap/logic/shared/constants';
import { add, format } from 'date-fns';

@Pipe({ name: 'dateObjFormat', standalone: true })
export class DateObjFormatPipe implements PipeTransform {
  public transform(
    value: Date | undefined,
    formatString: string = DateConstants.DateTime24Format,
    addHours?: number
  ): string {
    if (addHours) {
      return value ? format(add(value, { hours: addHours }), formatString) : '';
    }
    return value ? format(value, formatString) : '';
  }
}
