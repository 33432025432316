import * as R from 'rambdax';
import { WithRawJson } from '../interfaces';
import { BetSelection } from './bet-selection';

export enum BetStatus {
  open = 'open',
  lost = 'lost',
  paid = 'paid',
  void = 'void'
}

export enum BetType {
  single = 'single',
  combi = 'combi',
  system = 'system'
}

export enum BetKind {
  pre = 'PRE',
  live = 'LIVE',
  boa = 'BOA',
  leap = 'Leap',
  gr = 'GR',
  dst = 'DST',
  tvBet = 'TVbet'
}

export enum BetCurrency {
  eur = 'EUR',
  pln = 'PLN',
  gbp = 'GBP'
}

export interface BetWithSelections extends Bet, BetSelection {}
export class Bet implements WithRawJson {
  constructor(
    public readonly id: number,
    public readonly type: BetType,
    public readonly risk: number,
    public readonly currency: BetCurrency,
    public readonly odds: number,
    public readonly americanOdds: number,
    public readonly fractionalOdds: number,
    public readonly earlyCashoutOdds: number,
    public readonly earlyCashoutAmericanOdds: number,
    public readonly earlyCashoutFractionalOdds: number,
    public readonly payout: number,
    public readonly payoutCurrency: number,
    public readonly stake: number,
    public readonly stakeCurrency: number,
    public readonly status: BetStatus,
    public readonly statusUpdated: Date | undefined,
    public readonly kind: BetKind | undefined,
    public readonly rawJson: Record<string, any>
  ) {}

  public static fromJson(json: Record<string, any>): Bet {
    return new Bet(
      json['bet_id'],
      json['bet_type'] ? json['bet_type'].toLocaleLowerCase() : undefined,
      json['coupon_risk'],
      json['currency'],
      json['odds'],
      json['american_odds'],
      json['fractional_odds'],
      json['early_cashout_odds'],
      json['early_cashout_american_odds'],
      json['early_cashout_fractional_odds'],
      json['payout'],
      json['payout_currency'],
      json['stake'],
      json['stake_currency'],
      json['status'],
      json['status_updated'] ? new Date(json['status_updated']) : undefined,
      json['type'],
      json
    );
  }

  public static fromJsonArray(jsons: Record<string, any>[]): BetWithSelections[][] {
    return jsons.map((bet: Record<string, any>) =>
      bet['bet_items'].map((item: any) => R.mergeAll([Bet.fromJson(bet), BetSelection.fromJson(item)]))
    );
  }
}
