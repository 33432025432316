import { BetTracerRepository } from '@sap/logic/api-access/repository/bet-tracer-repository';
import { BetInternalCommentDatabase } from '@sap/logic/firestore-database/bet-internal-comment-database';
import { BetTracerStreams } from '../bet-tracer-streams';
import { BetTracerOtherViewsData } from './bet-tracer-other-views-data';
import { BetTracerOtherViewsBloc } from './bet-tracer-other-views.bloc';

type BetTracerDeps = (
  | typeof BetTracerOtherViewsBloc
  | typeof BetTracerOtherViewsData
  | typeof BetTracerStreams
  | typeof BetInternalCommentDatabase
  | typeof BetTracerRepository
)[];

export const betTracerOtherDeps: BetTracerDeps = [
  BetTracerOtherViewsBloc,
  BetTracerOtherViewsData,
  BetTracerStreams,
  BetInternalCommentDatabase,
  BetTracerRepository
];
