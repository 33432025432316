import { Injectable } from '@angular/core';
import { InfoRepositoryCached } from '@sap/logic/api-access/repository-cached/info-repository-cached';
import { mapToGetSelectedCountriesByCodes } from '@sap/logic/promoted-events/bloc/promoted-evets-filters/pure-utils';
import { Country } from '@sap/logic/shared/models/brand-info';
import { Provider } from '@sap/logic/shared/models/provider';
import { SportsGroup } from '@sap/logic/shared/models/sports-group';
import { withLatestFromWaitable } from '@sap/shared/custom-rx-operators/with-latest-from-waitable';
import * as R from 'rambdax';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SharedBrand } from '../brand/shared-brand';
import { SharedSportsGroups } from '../sports-groups/shared-sports-groups';

@Injectable({ providedIn: 'root' })
export class SharedFilters {
  public paramCountryCodesSelected$: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

  constructor(
    private _infoRepositoryCached: InfoRepositoryCached,
    private _sharedSportsGroups: SharedSportsGroups,
    private _sharedBrand: SharedBrand
  ) {}

  public getProviders(excludedProviderIds: string[] = []): Observable<Provider[]> {
    return this._infoRepositoryCached
      .getProvidersInfo()
      .pipe(
        map((providersInfo: Provider[]) =>
          providersInfo.filter((provider: Provider) => !R.includes(provider.id, excludedProviderIds))
        )
      );
  }

  public getProvidersNames(excludedProviderIds: string[] = []): Observable<string[]> {
    return this.getProviders(excludedProviderIds).pipe(map((providers: Provider[]) => providers.map(R.prop('name'))));
  }

  public getAllBrandsCountries(): Observable<Country[]> {
    return this._sharedBrand.getAllBrandsCountries();
  }

  public getFavoriteIndexesSportsIds(): Observable<Record<string, boolean>> {
    return this._sharedSportsGroups.getFavoriteIndexedSportsIds();
  }

  public getUnassignedBrandFilter(): Observable<boolean> {
    return this._sharedSportsGroups.getUnassignedBrandFilter();
  }

  public getSportsGroups(): Observable<SportsGroup[]> {
    return this._sharedSportsGroups.getSportsGroups();
  }

  public getCountriesSelected(): Observable<Country[] | undefined> {
    return this.paramCountryCodesSelected$.pipe(
      withLatestFromWaitable(this.getAllBrandsCountries()),
      map(mapToGetSelectedCountriesByCodes)
    );
  }
}
