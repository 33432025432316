import { Injectable } from '@angular/core';
import { RefreshCounterCached } from '@sap/ui/bet-tracer/shared/refresh-counter-cached';
import {
  BehaviorSubject,
  interval,
  merge,
  Observable,
  of,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  tap
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RefreshStateCached {
  public doRefresh$: BehaviorSubject<[number, boolean]> = new BehaviorSubject<[number, boolean]>([60000, true]);
  public doForceRefresh$: Subject<void> = new Subject<void>();

  private _refreshStateShareRef$: Observable<number | void | undefined> | undefined;

  constructor(private _refreshCounterCached: RefreshCounterCached) {}

  public getRefreshingState(): Observable<number | void | undefined> {
    return (this._refreshStateShareRef$ ??= merge(
      this.doRefresh$.pipe(
        switchMap(([timing, autoRefreshingEnabled]: [number, boolean]) =>
          autoRefreshingEnabled
            ? interval(timing).pipe(tap(() => this._refreshCounterCached.didRefresh()))
            : of(undefined)
        ),
        startWith(undefined)
      ),
      this.doForceRefresh$.pipe(startWith(undefined))
    ).pipe(shareReplay(1)));
  }
}
