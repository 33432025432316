export enum BetInputPropertiesReadable {
  couponId = 'Coupon ID',
  betId = 'Bet ID',
  totalStake = 'Total Stake [€]',
  totalStakeCurrency = 'Total Stake',
  couponRisk = 'Coupon Risk',
  payout = 'Payout [€]',
  // reachedRisk = 'Reached Risk [%]', temporary disabled reachedRisk input - not implemented on API
  quickWonBetTime = 'Quick Won Bet Time [m]'
}

export enum EventInputPropertiesReadable {
  matchId = 'Event ID',
  marketId = 'Market ID',
  clientId = 'Client ID',
  clientLogin = 'Client login'
}

export enum CategoryPropertiesReadable {
  betbuilder = 'Betbuilder',
  betonaction = 'LV Jackpot',
  digitalsportstech = 'Player Bets',
  cards = 'Cards',
  leap = 'Leap',
  gr = 'Golden Race',
  manual = 'Manual Event',
  regular = 'Regular'
}

export enum BetslipStatusPropertiesReadable {
  open = 'Open',
  closed = 'Closed'
}

export enum BetTypePropertiesReadable {
  single = 'Single',
  combi = 'Combi',
  system = 'System'
}

export enum BetTestReadable {
  test = 'test bets',
  real = 'real bets'
}

export enum BetForceStatusPropertiesReadable {
  forceHalfLost = 'Force Half Lost',
  forceHalfWon = 'Force Half Won',
  forceLost = 'Force Lost',
  forceOpen = 'Force Open',
  forceReturn = 'Force Return',
  forceWon = 'Force Won'
}

export enum OddsFormat {
  decimal = 'decimal',
  american = 'american',
  fractional = 'fractional'
}

export enum BetItemStatusPropertiesReadable {
  won = 'won',
  lost = 'lost',
  halfWon = 'half won',
  halfLost = 'half lost',
  return = 'return'
}

export enum BetBonusTypesPropertiesReadable {
  bonusMoney = 'Bonus Money',
  lowerTax = 'Lower Tax',
  rateBoost = 'Rate Boost',
  real = 'Real'
}

export enum DevicesPropertiesReadable {
  androidMobileWeb = 'android (mobile-web)',
  androidNative = 'android (native)',
  androidPwa = 'android (PWA)',
  iosMobilWeb = 'ios (mobile-web)',
  iosNative = 'ios (native)',
  iosPwa = 'ios (PWA)',
  merchant = 'merchant',
  mobile = 'mobile',
  mobileApp = 'mobile-app',
  mobileWeb = 'mobile-web',
  pos = 'POS (Point of Sell)',
  pwa = 'PWA',
  terminal = 'terminal',
  web = 'web',
  webPwa = 'web (PWA)',
  webWebsite = 'web (website)'
}
