import { Pipe, PipeTransform } from '@angular/core';
import { getHours } from 'date-fns';

@Pipe({
  standalone: true,
  name: 'getHours'
})
export class GetHoursPipe implements PipeTransform {
  public transform(date: Date | undefined): number {
    if (!date) {
      return 0;
    }
    return getHours(date);
  }
}
