import { SelectionOutcome, SelectionStatus } from './selection-info-status';

export class BetSelection {
  constructor(
    public readonly betItemId: number,
    public readonly parentId: number,
    public readonly matchDate: Date | undefined,
    public readonly matchName: string,
    public readonly matchId: string,
    public readonly marketName: string | undefined,
    public readonly marketId: string | undefined,
    public readonly selectionName: string | undefined,
    public readonly selectionId: string | undefined,
    public readonly selectionStatus: SelectionStatus,
    public readonly selectionRiskReached: number,
    public readonly betItemsOdds: number,
    public readonly betItemsAmericanOdds: number,
    public readonly betItemsFractionalOdds: number,
    public readonly betItemStatus: SelectionOutcome,
    public readonly isLive: boolean,
    public readonly virtualId: number | undefined,
    public readonly sportGroups: string[]
  ) {}

  public static fromJson(json: Record<string, any>): BetSelection {
    return new BetSelection(
      json['bet_item_id'],
      json['bet_id'],
      json['event_date'] ? new Date(json['event_date']) : undefined,
      json['event_name'],
      json['event_id'] ?? undefined,
      json['market_name'] ?? undefined,
      json['market_id'] ?? undefined,
      json['selection_name'] ?? undefined,
      json['selection_id'] ?? undefined,
      json['selection_status'] ?? undefined,
      json['selection_risk_reached'] ?? undefined, // currently not comming from API
      json['odds'],
      json['american_odds'],
      json['fractional_odds'],
      json['status'],
      json['is_live'],
      json['virtual_id'],
      json['sports_groups']
    );
  }

  public static fromJsonArray(jsons: Record<string, any>[]): BetSelection[] {
    return jsons.map(BetSelection.fromJson);
  }
}
