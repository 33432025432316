import { NavigationItem } from '../left-navigation-drawer.component';
import { brandsWithoutDefault } from './brand';

export const customerSearchMain: NavigationItem = {
  title: 'Customers Search',
  url: '/customer-search',
  icon: 'search',
  scope: 'customers.main',
  brandsScope: brandsWithoutDefault,
  preventMultipleTab: true
};
