export class BettingLocation {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly address: string | undefined,
    public readonly label: string | undefined,
    public readonly agentId: number | undefined,
    public readonly createdAt: Date | undefined,
    public readonly updatedAt: Date | undefined,
    public readonly isActive: boolean,
    public agentName?: string | undefined
  ) {}

  public static fromJson(json: Record<string, any>): BettingLocation {
    return new BettingLocation(
      json['id'],
      json['name'],
      json['address'],
      `${json['name']}, ${json['address']}`,
      json['agent'] ?? undefined,
      json['created'] ? new Date(json['created']) : undefined,
      json['modified'] ? new Date(json['modified']) : undefined,
      json['is_active']
    );
  }

  public static fromJsonArray(jsons: Record<string, any>[]): BettingLocation[] {
    return jsons.map(BettingLocation.fromJson);
  }
}
