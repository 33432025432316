import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GetHoursPipe } from '@sap/ui/shared/pipes/get-hours.pipe';
import { GetMinutesPipe } from '@sap/ui/shared/pipes/get-minutes.pipe';
import { IsAfterDatePipe } from '@sap/ui/shared/pipes/is-after-date.pipe';
import { IsBeforeDatePipe } from '@sap/ui/shared/pipes/is-before-date.pipe';
import { IsNextMonthPipe } from '@sap/ui/shared/pipes/is-next-month.pipe';
import { IsSameDayPipe } from '@sap/ui/shared/pipes/is-same-day.pipe';
import { IsSameMonthPipe } from '@sap/ui/shared/pipes/is-same-month.pipe';
import { ClickDetachedDirective } from '../../directives/click-detached.directive';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';
import { KeyupTargetNumericValueDirective } from '../../directives/inputs/keyup-target-numeric-value.directive';
import { FxThemeCssModule } from '../../fx-theme-css/fx-theme-css.module';
import { DateObjFormatPipe } from '../../pipes/date-obj-format.pipe';
import { DatePickerComponent } from './date-picker.component';
import { TemplateVarTypedDirective } from './template-var-typed.directive';

@NgModule({
  declarations: [DatePickerComponent, TemplateVarTypedDirective],
  imports: [
    CommonModule,
    KeyupTargetNumericValueDirective,
    FxThemeCssModule,
    DateObjFormatPipe,
    ClickOutsideDirective,
    ClickDetachedDirective,
    GetHoursPipe,
    GetMinutesPipe,
    IsSameMonthPipe,
    IsNextMonthPipe,
    IsSameDayPipe,
    IsAfterDatePipe,
    IsBeforeDatePipe
  ],
  exports: [DatePickerComponent]
})
export class DatePickerModule {}
