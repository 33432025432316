import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Params, QueryParamsHandling, Router } from '@angular/router';
import { Filters } from '@sap/logic/api-access/api/bet-tracer-api';
import { BetTracerFiltersBloc } from '@sap/logic/bet-tracer/bloc/bet-tracer-filters/bet-tracer-filters.bloc';
import { betTracerFiltersBloc } from '@sap/logic/bet-tracer/bloc/bet-tracer-filters/deps';
import { ClassificationPropertiesReadable } from '@sap/logic/shared/enums';
import { BetStatus } from '@sap/logic/shared/models/bet';
import { BettingLocation } from '@sap/logic/shared/models/betting-location';
import { SportsGroup } from '@sap/logic/shared/models/sports-group';
import { Unsubscribable } from '@sap/shared/classes/unsubscribable';
import { BrandType } from '@sap/shared/enums';
import { mapQueryParamsBooleansToCorrectTypes } from '@sap/shared/helpers/map-query-params';
import { getDateRangeString } from '@sap/ui/shared/components/date-range-picker/pure-utils/get-date-range-string';
import { findLastActivatedRouteChild } from '@sap/ui/shared/helpers/router-utils';
import { SetAndDetect, setAndDetect } from '@sap/ui/shared/helpers/set-and-detect';
import { Subject, combineLatest, map } from 'rxjs';
import { BetTraceColumNames } from '../../components/bet-tracer-table-tool/enum';
import {
  BetBonusTypesPropertiesReadable,
  BetForceStatusPropertiesReadable,
  BetInputPropertiesReadable,
  BetItemStatusPropertiesReadable,
  BetTestReadable,
  BetTypePropertiesReadable,
  BetslipStatusPropertiesReadable,
  CategoryPropertiesReadable,
  DevicesPropertiesReadable,
  EventInputPropertiesReadable,
  OddsFormat
} from './enums';
import { getBetsFilterFromQueryParams } from './impure-bet-tracer-filter-helper';
import { NgContextVarsTyped, TemplateVarTypedDirective } from './template-var-typed.directive';

type ExtendedFilters = Filters & { selectedBrands?: string[] };

@Component({
  selector: 'sap-bet-tracer-top-filters',
  templateUrl: './bet-tracer-top-filters.component.html',
  styleUrls: ['./bet-tracer-top-filters.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  providers: betTracerFiltersBloc
})
export class BetTracerTopFiltersComponent extends Unsubscribable implements OnInit {
  public readonly dateRangeString: typeof getDateRangeString = getDateRangeString;

  @ViewChild(TemplateVarTypedDirective) private set _templateVarTypedDirective(
    templateVarTypedDirective: TemplateVarTypedDirective
  ) {
    if (!this._templateVarTypedDirectiveRef) {
      this._templateVarTypedDirectiveRef = templateVarTypedDirective;
      this._subQueryParamsAndSetFiltersToContextVars();
    }
  }

  public BrandType: typeof BrandType = BrandType;
  public initialBrand: BrandType | undefined = undefined;
  public BetInputPropertiesReadable: typeof BetInputPropertiesReadable = BetInputPropertiesReadable;
  public EventInputPropertiesReadable: typeof EventInputPropertiesReadable = EventInputPropertiesReadable;
  public ClassificationPropertiesReadable: typeof ClassificationPropertiesReadable = ClassificationPropertiesReadable;
  public CategoryPropertiesReadable: typeof CategoryPropertiesReadable = CategoryPropertiesReadable;
  public BetForceStatusPropertiesReadable: typeof BetForceStatusPropertiesReadable = BetForceStatusPropertiesReadable;
  public BetslipStatusPropertiesReadable: typeof BetslipStatusPropertiesReadable = BetslipStatusPropertiesReadable;
  public BetTypePropertiesReadable: typeof BetTypePropertiesReadable = BetTypePropertiesReadable;
  public BetTestReadable: typeof BetTestReadable = BetTestReadable;
  public OddsFormat: typeof OddsFormat = OddsFormat;
  public BetItemStatusPropertiesReadable: typeof BetItemStatusPropertiesReadable = BetItemStatusPropertiesReadable;
  public BetStatusPropertiesReadable: typeof BetStatus = BetStatus;
  public DevicesPropertiesReadable: typeof DevicesPropertiesReadable = DevicesPropertiesReadable;
  public BetBonusTypesPropertiesReadable: typeof BetBonusTypesPropertiesReadable = BetBonusTypesPropertiesReadable;

  @Input() public set noAccess(value: boolean) {
    this._noAccess = value;
    this._detectChanges();
  }
  public get noAccess(): boolean {
    return this._noAccess;
  }
  @Input() public set refreshState(state: [number, boolean] | undefined) {
    this._refreshState = state;
    this._detectChanges();
  }
  public get refreshState(): [number, boolean] | undefined {
    return this._refreshState;
  }

  @Input() public set calculatedPayouts(result: number) {
    this._calculatedPayouts = result;
    this._detectChanges();
  }
  public get calculatedPayouts(): number {
    return this._calculatedPayouts;
  }

  @Input() public set calculatedTurnover(result: number) {
    this._calculatedTurnover = result;
    this._detectChanges();
  }
  public get calculatedTurnover(): number {
    return this._calculatedTurnover;
  }

  @Input() public set isLoading(result: boolean) {
    this._isLoading = result;
    this._detectChanges();
  }
  public get isLoading(): boolean {
    return this._isLoading;
  }

  @Input() public set isLoadingBettingLocations(result: boolean) {
    this._isLoadingBettingLocations = result;
    this._detectChanges();
  }
  public get isLoadingBettingLocations(): boolean {
    return this._isLoadingBettingLocations;
  }

  @Input() public set bettingLocations(result: BettingLocation[]) {
    this._bettingLocations = result;
    this._detectChanges();
  }
  public get bettingLocations(): BettingLocation[] {
    return this._bettingLocations;
  }

  @Output() public doSelectBrands: EventEmitter<BrandType[]> = new EventEmitter<BrandType[]>();
  @Output() public doSelectOddFormat: EventEmitter<OddsFormat> = new EventEmitter<OddsFormat>();
  @Output() public doRefresh: EventEmitter<[number, boolean]> = new EventEmitter<[number, boolean]>();
  @Output() public doForceRefresh: EventEmitter<void> = new EventEmitter<void>();
  @Output() public updateDisplayedColumns: EventEmitter<BetTraceColumNames[]> = new EventEmitter<
    BetTraceColumNames[]
  >();
  @Output() public resetColumns: EventEmitter<void> = new EventEmitter<void>();
  @Output() public isFiltersSticky: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public resetOffset: EventEmitter<void> = new EventEmitter<void>();

  private _set: SetAndDetect<this>;
  private _initialFilters: ExtendedFilters = {
    limit: 100
  };
  private _filters: ExtendedFilters = {
    ...this._initialFilters
  };
  private _templateVarTypedDirectiveRef: TemplateVarTypedDirective | undefined = undefined;
  private _refreshState: [number, boolean] | undefined;
  private _noAccess: boolean = false;
  private _calculatedPayouts: number = 0;
  private _calculatedTurnover: number = 0;
  private _bettingLocations: BettingLocation[] = [];
  private _isLoadingBettingLocations: boolean = true;
  private _isLoading: boolean = true;

  public brands: BrandType[] = [];
  public sportsGroups: SportsGroup[] = [];
  public sortedSports: SportsGroup[] = [];
  public popularSports: SportsGroup[] = [];

  public doToggleFiltersVisibility$: Subject<boolean> = this._bloc.doToggleFiltersVisibility$;

  constructor(
    private _cdRef: ChangeDetectorRef,
    private _bloc: BetTracerFiltersBloc,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
    super();
    this._set = setAndDetect(this, this._cdRef);
  }

  public ngOnInit(): void {
    this._sub = this._bloc.sharedBrand.getBrandsWithoutDefault().subscribe(this._set('brands'));
    this._sub = this._bloc.getSportsGroups().subscribe(this._set('sportsGroups'));
    this._sub = this._bloc.getSportsSorted().subscribe(this._set('sortedSports'));
    this._sub = this._bloc.getPopularSports().subscribe(this._set('popularSports'));
  }

  private _subQueryParamsAndSetFiltersToContextVars(): void {
    this._sub = combineLatest([
      this._activatedRoute.queryParams.pipe(map(mapQueryParamsBooleansToCorrectTypes)),
      this._bloc.sharedBrand.getInitialBrand(BrandType.default)
    ]).subscribe(([params, initialBrand]: [Params, BrandType]) =>
      this._setFiltersFromQueryParams(params, initialBrand)
    );
  }

  public updateFilters(key: string, value: string | number | undefined | boolean | (number | string)[]): void {
    if (Array.isArray(value) && value.length === 0) {
      value = undefined;
    }
    if (key === 'sportsIds') {
      this._filters['sportsGroupsIds'] = undefined;
    }
    if (key === 'sportsGroupsIds') {
      this._filters['sportsIds'] = undefined;
    }
    this._filters[key] = value;
  }

  public doFilter(): void {
    this.resetOffset.next();
    this._setQueryParamsInRouter(this._filters);
  }

  public doReset(): void {
    if (this._filters.selectedBrands) {
      this._filters = {
        selectedBrands: this._filters.selectedBrands,
        ...this._initialFilters
      };
    } else {
      this._filters = {
        ...this._initialFilters
      };
    }

    this.resetOffset.next();
    this._setQueryParamsInRouter(this._filters);
  }

  private _detectChanges(): void {
    this._cdRef.detectChanges();
  }

  private _setQueryParamsInRouter(filters: Params, queryParamsHandling: QueryParamsHandling = ''): void {
    this._router.navigate(['.'], {
      relativeTo: findLastActivatedRouteChild(this._activatedRoute),
      queryParams: filters,
      queryParamsHandling
    });
  }

  private _setFiltersFromQueryParams(params: Params, initialBrand: BrandType): void {
    this._filters = {};
    this.initialBrand = initialBrand;
    const copyParams: NgContextVarsTyped = getBetsFilterFromQueryParams(params, initialBrand, this.doSelectOddFormat);

    for (const key of Object.keys(params)) {
      this._filters[key] = params![key];
    }
    copyParams.filtersVisible = this._bloc.getFiltersVisibility();

    this._templateVarTypedDirectiveRef!.updateInitialContext(copyParams);
  }
}
