import { Directive } from '@angular/core';
import { NgContext, NgContextVars, TemplateVarDirective } from '@sap/ui/shared/directives/template-var.directive';

type NgContextVarsTyped = {
  yaerView: boolean;
  monthView: boolean;
};

@Directive({
  standalone: true,
  selector: '[templateVarTyped]'
})
export class TemplateVarTypedDirective extends TemplateVarDirective<NgContextVarsTyped> {
  protected override _initialContext: NgContextVarsTyped = {
    yaerView: false,
    monthView: false
  };

  public static override ngTemplateContextGuard<NgContextVarsTyped extends NgContextVars>(
    _dir: TemplateVarDirective<NgContextVarsTyped>,
    ctx: unknown
  ): ctx is NgContext<NgContextVarsTyped> {
    return true;
  }
}
