import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getApiUrlBrandByEnv } from '@sap/logic/api-access/pure-utils/pure-utils';
import { FiltersKeys, FiltersValues } from '@sap/logic/bet-tracer/bloc/bet-tracer/enums';
import { Bet, BetStatus, BetType } from '@sap/logic/shared/models/bet';
import { Betslip } from '@sap/logic/shared/models/betslip';
import { mapToQueryParamsFilters, mapToSportsGroupsQueryParams } from '@sap/logic/shared/utils/pure-utils';
import { SharedSettings } from '@sap/shared-settings/shared-settings';
import { BrandType } from '@sap/shared/enums';
import { catchError, lastValueFrom, map } from 'rxjs';
import { ApiConfig } from '../api-config';
import { ApiForbiddenDetector, MethodError } from '../detectors/api-forbidden-detector';
import { BetsNotFoundError } from '../errors/betslips-not-found-error';

export type FiltersApi = {
  limit?: number | undefined;
  offset?: number | undefined;
  betslipId?: number | undefined;
  hasEarlyCashout?: boolean;
  manualOdds?: boolean;
  betslipTypes?: BetType;
  betStatuses?: BetStatus;
  sportsIds?: string | string[] | undefined;
  sportsGroupsIds?: string | string[] | undefined;
};

export type Filters = FiltersApi;

export type BetslipsResult = {
  data: Betslip[];
  nextKeyForBrand: Record<string, number>;
};

type BetslipsResponse = {
  count: number;
  data: BetslipsDataResponse[];
  nextKey: number;
};

type BetslipsDataResponse = {
  betslip: Betslip;
  bets: Bet[];
};

@Injectable({ providedIn: 'root' })
export class BetTracerApi {
  private _isProduction: boolean = this._sharedSettings.environment.production;
  private _envApiUrl: string = this._sharedSettings.environment.clientBOApiUrl;
  private _betslipsUrl: string = 'client-bo-api/v1/betslips/';

  constructor(
    private _sharedSettings: SharedSettings,
    private _http: HttpClient,
    private _apiConfig: ApiConfig,
    private _apiForbiddenDetector: ApiForbiddenDetector
  ) {}

  public getBetslipsFilteredAndSorted(filters: Filters, brand: BrandType): Promise<BetslipsResult | BetsNotFoundError> {
    const envApiUrl: string | undefined = getApiUrlBrandByEnv(this._isProduction, this._envApiUrl, brand);
    if (filters.sportsIds) {
      filters.sportsGroupsIds = filters.sportsIds;
    } else {
      mapToSportsGroupsQueryParams(filters);
    }
    if (filters.limit === undefined) {
      filters.limit = 100; // required query param
    }
    return lastValueFrom(
      this._http
        .get<BetslipsResponse>(`${envApiUrl}${this._betslipsUrl}`, {
          params: mapToQueryParamsFilters(FiltersKeys, filters, {}, FiltersValues),
          headers: this._apiConfig.authHeaders
        })
        .pipe(
          map((response: BetslipsResponse) => ({
            data: Betslip.fromJsonArray(response.data, brand),
            nextKeyForBrand: { [brand]: response['next_key'] ?? undefined }
          })),
          catchError(this._apiForbiddenDetector.detectOnHttpError()),
          BetsNotFoundError.fromHttpError()
        )
    );
  }

  public refreshBetStatus(betId: number, brand: BrandType): Promise<void> {
    const envApiUrl: string | undefined = getApiUrlBrandByEnv(this._isProduction, this._envApiUrl, brand);
    return lastValueFrom(
      this._http
        .put<void>(`${envApiUrl}${this._betslipsUrl}status/refresh/${betId}`, {}, this._apiConfig.authOptions)
        .pipe(catchError(this._apiForbiddenDetector.detectOnHttpError(MethodError.write)))
    );
  }
}
