import { Directive } from '@angular/core';
import { BrandType } from '@sap/shared/enums';
import { NgContext, NgContextVars, TemplateVarDirective } from '@sap/ui/shared/directives/template-var.directive';
import { OddsFormat } from './enums';

export type NgContextBetInputVarsTyped = {
  couponId: number | undefined;
  betId: number | undefined;
  totalStake: number | undefined;
  totalStakeCurrency: number | undefined;
  couponRisk: number | undefined;
  payout: number | undefined;
  reachedRisk: number | undefined;
  quickWonBetTime: number | undefined;
  oddFormat: keyof typeof OddsFormat;
  isTest: boolean | undefined;
};

export type NgContextEventInputVarsTyped = {
  matchId: string | undefined;
  marketId: string | undefined;
  clientId: number | undefined;
  clientLogin: string | undefined;
};

export type NgContextInputRateVarsTyped = {
  refreshRateOn: boolean;
  refreshInterval: number;
  selectedBrands: BrandType[];
  classification: string[];
  category: string[];
  forceStatus: string[];
  betslipStatus: string[];
  betType: string[];
  dateRangePickerOpen: boolean;
  statusUpdatedDateRangePickerOpen: boolean;
  dateFrom: Date | undefined;
  dateTo: Date | undefined;
  statusUpdatedDateFrom: Date | undefined;
  statusUpdatedDateTo: Date | undefined;
  dateRange: string | undefined;
  statusUpdatedDateRange: string | undefined;
  hasEarlyCashout: boolean | undefined;
  manualOdds: boolean | undefined;
  isLive: boolean | undefined;
  sportsIds: number[];
  sportsGroupsIds: number[];
  sportId: number | undefined;
  regionId: number | undefined;
  competitionId: number | undefined;
};

export type NgContextAdditionalFiltersVarsTyped = {
  filtersVisible: boolean;
  betSelectionStatus: string | undefined;
  betStatuses: string[];
  devices: string[];
  bonusInfo: string | undefined;
  bonusTypes: string[];
  locationId: number | undefined;
};

export type NgContextVarsTyped = NgContextInputRateVarsTyped &
  NgContextBetInputVarsTyped &
  NgContextEventInputVarsTyped &
  NgContextAdditionalFiltersVarsTyped;

@Directive({
  selector: '[templateVarTyped]'
})
export class TemplateVarTypedDirective extends TemplateVarDirective<NgContextVarsTyped> {
  protected override _initialContext: NgContextVarsTyped = {
    refreshRateOn: true,
    refreshInterval: 60000,
    selectedBrands: [],
    classification: [],
    category: [],
    forceStatus: [],
    betslipStatus: [],
    betType: [],
    couponId: undefined,
    betId: undefined,
    totalStake: undefined,
    totalStakeCurrency: undefined,
    couponRisk: undefined,
    payout: undefined,
    reachedRisk: undefined,
    quickWonBetTime: undefined,
    matchId: undefined,
    marketId: undefined,
    clientId: undefined,
    clientLogin: undefined,
    dateFrom: undefined,
    dateTo: undefined,
    dateRange: undefined,
    dateRangePickerOpen: false,
    statusUpdatedDateRange: undefined,
    statusUpdatedDateRangePickerOpen: false,
    statusUpdatedDateFrom: undefined,
    statusUpdatedDateTo: undefined,
    hasEarlyCashout: false,
    manualOdds: false,
    isLive: undefined,
    oddFormat: OddsFormat.decimal,
    isTest: undefined,
    filtersVisible: true,
    betSelectionStatus: undefined,
    betStatuses: [],
    devices: [],
    bonusInfo: undefined,
    bonusTypes: [],
    locationId: undefined,
    sportsIds: [],
    sportsGroupsIds: [],

    sportId: undefined,
    regionId: undefined,
    competitionId: undefined
  };

  public static override ngTemplateContextGuard<NgContextVarsTyped extends NgContextVars>(
    _dir: TemplateVarDirective<NgContextVarsTyped>,
    ctx: unknown
  ): ctx is NgContext<NgContextVarsTyped> {
    return true;
  }
}
