import {
  AfterViewInit,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges
} from '@angular/core';
import { equalsSimple } from '@sap/logic/shared/utils/pure-utils';
import { rxDone } from '@sap/shared/helpers/rx-helpers';
import { Subject, startWith, takeUntil } from 'rxjs';
import { whenChanged } from '../helpers/ng-changes-helpers';

@Directive({
  selector: '[selectChangeValue]',
  standalone: true
})
export class SelectChangeValueDirective<T> {
  @Input() selectChangeValue!: T;
  @Input() dontSet: boolean = false;
}

@Directive({
  selector: '[selectChange]',
  standalone: true,
  exportAs: 'selectChangeRef'
})
export class SelectChangeDirective implements AfterViewInit, OnDestroy, OnChanges {
  @ContentChildren(SelectChangeValueDirective) selectOptions?: QueryList<SelectChangeValueDirective<any>>;

  @Output()
  selectChange: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  dontSetClick: EventEmitter<any> = new EventEmitter<any>();

  @Input() selectValue?: any;
  @Input() watchSelectValueChange: boolean = false;
  @Input() selectValueKey?: string;
  @Input() enableDefaultFirstOption: boolean = true;
  @Input() enableInitialEmitting: boolean = true;

  private _destroy$: Subject<void> = new Subject<void>();
  private _emittingCounter: number = 0;

  private _previousSelectedIndex: number | undefined = undefined;

  constructor(private _elementRef: ElementRef) {}

  @HostListener('change', ['$event'])
  public change(event: Event, isVirtualChange: boolean = false): void {
    if (!this.enableInitialEmitting && isVirtualChange) {
      return;
    }
    const value: any = this.selectOptions!.get((event.target as any).options.selectedIndex)?.selectChangeValue;
    const dontSet: boolean = this.selectOptions!.get((event.target as any).options.selectedIndex)?.dontSet ?? false;
    if (dontSet) {
      this._elementRef.nativeElement.selectedIndex = this._previousSelectedIndex;
      this.dontSetClick.emit(value);
      return;
    }
    // if select is empty, we should not send event
    if (this.selectOptions!.length > 0) {
      this.selectChange.emit(value);
    }
  }

  public ngAfterViewInit(): void {
    this.selectOptions!.changes.pipe(startWith(undefined), takeUntil(this._destroy$)).subscribe(() => this.reset());
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.watchSelectValueChange) {
      whenChanged(changes, 'selectValue', equalsSimple, () => this.reset(false));
    }
  }

  public reset(emitChange: boolean = true, clearSelectValue: boolean = false): void {
    if (clearSelectValue) {
      this.selectValue = undefined;
    }
    if (!this.selectOptions) {
      return;
    }
    const findIndex: number = this.selectOptions
      .toArray()
      .findIndex(
        (item: SelectChangeValueDirective<any>) =>
          (this.selectValueKey ? item.selectChangeValue[this.selectValueKey] : item.selectChangeValue) ===
          this.selectValue
      );
    if (findIndex !== -1) {
      this._elementRef.nativeElement.selectedIndex = findIndex;
      this._virtualChangeIndex(findIndex, emitChange);
    } else if (this.enableDefaultFirstOption) {
      this._elementRef.nativeElement.selectedIndex = 0;
      this._virtualChangeIndex(0, emitChange);
    }
  }

  private _virtualChangeIndex(index: number, emitChange: boolean): void {
    this._previousSelectedIndex = index;
    if (!this.enableInitialEmitting && this._emittingCounter === 0) {
      this._emittingCounter += 1;
      return;
    }

    if (emitChange) {
      this.change({ target: { options: { selectedIndex: index } } } as any, true);
    }
  }

  public ngOnDestroy(): void {
    rxDone(this._destroy$);
  }
}
