import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { MessageError } from '../errors/message-error';
import { ApiMessageSignal } from '../successes/api-message-success-signal';

export enum MethodError {
  read,
  write
}
@Injectable({
  providedIn: 'root'
})
export class ApiForbiddenDetector {
  constructor(private _apiMessageSignal: ApiMessageSignal) {}

  public detectOnHttpError(method: MethodError = MethodError.read) {
    return (error: HttpErrorResponse): Observable<never> => {
      if (error.status === 403) {
        const errorMsg: MessageError = new MessageError(
          error.message,
          method === MethodError.write ? 'Forbidden to do this action' : 'Forbidden to load some data'
        );
        this._apiMessageSignal.errorMessage$.next(errorMsg);
      }
      return throwError(() => error);
    };
  }
}
