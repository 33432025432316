import { Pipe, PipeTransform } from '@angular/core';
import { isAfter, isBefore } from 'date-fns';

@Pipe({
  standalone: true,
  name: 'isBetweenDates'
})
export class IsBetweenDatesPipe implements PipeTransform {
  public transform(date: Date, dateFrom: Date | undefined, dateTo: Date | undefined): boolean {
    if (!dateFrom || !dateTo) {
      return false;
    }
    return isAfter(date, dateFrom) && isBefore(date, dateTo);
  }
}
