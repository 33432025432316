import { SelectionRisk } from '@sap/logic/shared/models/selection-risk';
import { SelectionRiskInfo, SelectionRiskInfoTotal } from '@sap/logic/shared/models/selection-risk-info';
import * as R from 'rambdax';
import { BrandType } from '../enums';

export function byBrandName<T>(inputArray: T[], brandName: BrandType, propKey: string = 'brandName'): T {
  return inputArray.find(R.propEq(propKey, brandName) as any)! as T;
}

export function byBrandNameOptional<T>(inputArray: T[], brandName: BrandType): T | undefined {
  return inputArray.find(R.propEq('brandName', brandName) as any);
}

export function indexByBrandName<T>(inputArray: T[], brandName: BrandType): number {
  return inputArray.findIndex(R.propEq('brandName', brandName) as any);
}

export function byBrandNameWithDefault(
  selectionRisk: SelectionRisk | undefined,
  brand: BrandType
): SelectionRiskInfo | SelectionRiskInfoTotal | undefined {
  if (!selectionRisk) {
    return undefined;
  }
  if (brand === BrandType.default) {
    return selectionRisk.totalRisk;
  }
  return byBrandNameOptional(selectionRisk.risks, brand);
}

export function mapUpdatedDataById<T extends { id: number | string }>(data: T[], updateItem: T): T[] {
  return data.map((item: T) => {
    if (item.id === updateItem.id) {
      return {
        ...item,
        ...updateItem
      };
    }
    return item;
  });
}

export function mapUpdatedDataAlreadyEditedIds<T extends { id: number | string }>(
  currentEdited: T[],
  alreadyEdited: T[]
): T[] {
  const currentEditedIds: (string | number)[] = currentEdited.map(R.prop('id'));
  const alreadyEditedIds: (string | number)[] = alreadyEdited.map(R.prop('id'));
  const currentEditedFiltered: T[] = currentEdited.filter((item: T) => !alreadyEditedIds.includes(item.id));
  const alreadyEditedUpdated: T[] = alreadyEdited.map((item: T) => {
    if (currentEditedIds.includes(item.id)) {
      return {
        ...item,
        ...currentEdited.find(R.propEq('id', item.id))
      };
    }
    return item;
  });
  return [...alreadyEditedUpdated, ...currentEditedFiltered];
}

export function omitKeys<T>(input: T, keys: string[]): T {
  return R.omit(keys, input) as T;
}

export function compareTwoStringArraysAreEquals(arrayA: string[] | undefined, arrayB: string[] | undefined): boolean {
  if (arrayA && arrayB) {
    const sortedA: string[] = R.piped(
      arrayA,
      R.sort((itemA: string, itemB: string) => itemA.localeCompare(itemB))
    );
    const sortedB: string[] = R.piped(
      arrayB,
      R.sort((itemA: string, itemB: string) => itemA.localeCompare(itemB))
    );
    return R.equals(sortedA, sortedB);
  }
  return R.equals(arrayA, arrayB);
}
