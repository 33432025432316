<ng-container *templateVarTyped="let vars = vars; let update = updateVar; let updateObject = updateVarObject">
  <div class="modal show-modal">
    <div class="modal-dialog card">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Comments for coupon {{ betId }}
            <ng-container *ngIf="oldComments"> ({{ oldComments.length }}) </ng-container>
          </h5>
          <span class="modal-close" (click)="doCancelEdit.emit(undefined)">x</span>
        </div>
        <div class="modal-body">
          <form (dsFormSubmit)="doSubmit.emit(undefined)">
            <div class="comment-add-form">
              <div class="mb-2">
                <div class="grow-wrap" [attr.data-replicated-value]="vars.newNote">
                  <textarea
                    #addNoteInputRef="inputSetValueRef"
                    inputSetValue
                    class="form-control"
                    rows="1"
                    placeholder="Write a comment here..."
                    (keyupTargetValue)="update('newNote', $event.trim()); doUpdateCommentForm.next($event.trim())"
                  ></textarea>
                </div>
              </div>
            </div>
            <div>
              <sapds-button
                class="mb-3"
                type="submit"
                variant="warning"
                [isDisabled]="!vars.newNote"
                (click)="update('newNote', ''); addNoteInputRef.setValue('')"
              >
                Add comment
              </sapds-button>
            </div>
          </form>
          <!-- dissplay old comments -->
          <ng-container *ngFor="let comment of oldComments">
            <div class="comment mt-2">
              <div class="comment--head">
                <span class="comment--head__date">{{ comment.createdAt | dateObjFormat }}</span>
                <span class="comment--head__author">{{ comment.author }}</span>
              </div>
              <div class="grow-wrap" [attr.data-replicated-value]="comment.message">
                <textarea class="form-control" rows="1" readonly [inputSetValue]="comment.message"></textarea>
              </div>
            </div>
          </ng-container>
          <sapds-button class="mt-3" variant="danger" size="large" (click)="doCancelEdit.emit(undefined)"
            >Close</sapds-button
          >
        </div>
      </div>
    </div>
  </div>
</ng-container>
