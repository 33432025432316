import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { isDefined } from '@sap/logic/shared/utils/pure-utils';
import { formatAsDecimal, formatAsRound } from '@sap/shared/miscs/utils';

@Directive({
  selector: '[keyupTargetNumericValue]',
  standalone: true
})
export class KeyupTargetNumericValueDirective {
  @Output() keyupTargetNumericValue: EventEmitter<number> = new EventEmitter<number>();
  @Input() decimalMaxLength: number = 16;
  @Input() notAllowDecimals: boolean = false;

  @Input() set setValue(value: number | string | undefined) {
    if (isDefined(value) || value === 0) {
      const valueAsString: string = value.toString();
      this._elementRef.nativeElement.value = this.notAllowDecimals
        ? formatAsRound(valueAsString)
        : formatAsDecimal(valueAsString);
    }
  }

  constructor(private _elementRef: ElementRef) {}

  @HostListener('keyup', ['$event.target.value'])
  public onKeyup(value: string): void {
    if (value.endsWith('.')) {
      return;
    }
    const formattedValue: string | undefined = this._formatValue(value);
    this._elementRef.nativeElement.value = formattedValue ?? '';
    this.keyupTargetNumericValue.emit(formattedValue ? Number(formattedValue) : undefined);
  }

  @HostListener('change', ['$event.target.value'])
  public change(value: string): void {
    const formattedValue: string | undefined = this._formatValue(value);
    this.keyupTargetNumericValue.emit(formattedValue ? Number(formattedValue) : undefined);
  }

  private _formatValue(value: string): string | undefined {
    return Number.isNaN(value) ? undefined : this.notAllowDecimals ? formatAsRound(value) : formatAsDecimal(value);
  }
}
