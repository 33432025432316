import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { isDefined } from '@sap/logic/shared/utils/pure-utils';
import { Unsubscribable } from '@sap/shared/classes/unsubscribable';
import * as R from 'rambdax';
import { SelectAppearance } from './shared/enum';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'multi-select',
  template: `
    <div
      fxThemeCss
      class="form-multi-select form-multi-select-multiple form-multi-select-selection-tags"
      [class.form-select_rounded]="appearance === SelectAppearance.rounded"
      [class.form-select_button]="appearance === SelectAppearance.button"
      [class.form-select_table]="appearance === SelectAppearance.table"
      [class.form-select_selected]="selectedOptions.length"
      [class.show]="show"
      (click)="doToggle()"
      (clickOutside)="doHide()"
      [triggerWhen]="show"
    >
      <span *ngIf="selectedOptions.length > 0" class="form-multi-select-selection">
        <ng-container *ngFor="let option of selectedOptions">
          <span
            *ngIf="displayProperty ? option[displayProperty] : option as displayedOption"
            [attr.title]="displayedOption"
            class="form-multi-select-tag"
          >
            <span class="form-multi-select-tag__text"> {{ displayedOption }}</span>
            <button class="form-multi-select-tag-delete" data-clickOutsideException="true">
              <span (click)="toggleOption(option)">×</span>
            </button>
          </span>
        </ng-container>
      </span>
      <input
        *ngIf="(appearance !== SelectAppearance.button && selectedOptions.length === 0) || (!hideSearch && show)"
        #inputRef
        class="form-multi-select-search"
        [placeholder]="selectedOptions.length > 0 ? '' : placeholderTitle ? placeholderTitle : 'Select...'"
        (keyupTargetValue)="doFilterBy($event)"
      />

      <sapds-icon
        *ngIf="appearance === SelectAppearance.rounded"
        iconName="trash"
        (click)="$event.stopPropagation(); selectReset.next(); selectedOptions = []; doHide()"
      />
      <ng-template *ngIf="buttonsTemplate" [ngTemplateOutlet]="buttonsTemplate"></ng-template>
      <div class="form-multi-select-dropdown">
        <div
          [ngClass]="{
            'form-multi-select-options styled-scrollbar': !hideScroll,
            'form-multi-select-options-no-scroll': hideScroll
          }"
        >
          <ng-container *ngIf="selectAllOption && filteredOptions?.length">
            <div
              class="form-multi-select-option form-multi-select-option-with-checkbox"
              [class.form-multi-selected]="selectedOptions.length === options.length"
              (click)="toggleSelectAll()"
            >
              all
            </div>
          </ng-container>

          <ng-container *ngFor="let option of filteredOptions">
            <div
              *ngIf="displayProperty ? option[displayProperty] : option as displayedOption"
              class="form-multi-select-option form-multi-select-option-with-checkbox"
              [class.form-multi-selected]="selectedOptions | arrayIncludesObj: option"
              [attr.title]="displayedOption"
              (click)="toggleOption(option); $event.stopPropagation()"
            >
              {{ displayedOption }}
            </div>
          </ng-container>
          <ng-container *ngIf="filteredOptions?.length === 0">
            <div class="form-multi-select-option">No results</div>
          </ng-container>
          <ng-container *ngIf="resetOption">
            <div
              class="form-multi-select-option form-multi-select-reset_option"
              (click)="$event.stopImmediatePropagation(); selectReset.next(); doHide()"
            >
              Reset
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./shared/select.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class MultiSelectComponent<T, V> extends Unsubscribable implements OnInit {
  public SelectAppearance: typeof SelectAppearance = SelectAppearance;

  @Input() buttonsTemplate: TemplateRef<any> | undefined;
  @Input() selectAllOption: boolean = false;
  @Input() resetOption: boolean = false;
  @Input() options: T[] = [];
  @Input() emitDidUpdateAfterSetOptions?: boolean;
  @Input() valueProperty?: string;
  @Input() displayProperty?: string;
  @Input() placeholderTitle?: string;
  @Input() hideScroll: boolean = false;
  @Input() hideSearch: boolean = false;
  @Input() appearance: keyof typeof SelectAppearance = SelectAppearance.default;
  @Input() set setSelectedOptions(options: T[] | undefined) {
    this._handleSetOptions(options);
  }

  @Output() didChanged: EventEmitter<V[]> = new EventEmitter<V[]>();
  @Output() selectReset: EventEmitter<void> = new EventEmitter<void>();

  public show: boolean = false;
  public filterBy?: string;
  public selectedOptions: T[] = [];
  @ViewChild('inputRef') input?: ElementRef;

  constructor(private _cdRef: ChangeDetectorRef) {
    super();
  }

  public ngOnInit(): void {
    this._cdRef.detectChanges();
  }

  public doShow(): void {
    this.show = true;
    this._cdRef.detectChanges();
  }

  public doHide(): void {
    this.show = false;
    this.clearInputValueAndFilter();
    this._cdRef.detectChanges();
  }

  public doToggle(): void {
    this.show ? this.doHide() : this.doShow();
  }

  public doFilterBy(value: string): void {
    this.filterBy = value;
    this._cdRef.detectChanges();
  }

  public toggleOption(optionToToggle: T): void {
    const index: number = this.selectedOptions.findIndex((option: T) =>
      this.valueProperty ? option[this.valueProperty] === optionToToggle[this.valueProperty] : option === optionToToggle
    );

    if (index === -1) {
      this.selectedOptions = [...this.selectedOptions, optionToToggle];
    } else {
      this.selectedOptions = [...this.selectedOptions.slice(0, index), ...this.selectedOptions.slice(index + 1)];
    }
    this._emitUpdate();

    this._cdRef.detectChanges();
  }

  public toggleSelectAll(): void {
    this.selectedOptions = this.options.length !== this.selectedOptions.length ? [...this.options] : [];
    if (this.selectedOptions.length) {
      this.doHide();
    }
    this._emitUpdate();
    this._cdRef.detectChanges();
  }

  public reset(): void {
    this.selectedOptions = [];
    this._emitUpdate();
    this._cdRef.detectChanges();
  }

  public get filteredOptions(): T[] {
    if (this.filterBy) {
      return this.options.filter((option: T) =>
        this._getOptionValue(option).toLowerCase().startsWith(this.filterBy!.toLowerCase())
      );
    }
    return this.options;
  }

  private _emitUpdate(): void {
    if (this.valueProperty) {
      this.didChanged.emit(this.selectedOptions.map(R.prop(this.valueProperty!) as any));
    } else {
      this.didChanged.emit(this.selectedOptions as any as V[]);
    }
  }

  private _getOptionValue<T>(option: T): string {
    return this.displayProperty ? option[this.displayProperty] : option;
  }

  public clearInputValueAndFilter(): void {
    if (this.input) {
      this.input.nativeElement.value = '';
      this.filterBy = '';
    }
  }

  private _handleSetOptions(options: T[] | undefined): void {
    const currentSelected: T[] = this.selectedOptions;
    if (isDefined(options)) {
      this.selectedOptions = options;
    } else {
      this.selectedOptions = [];
    }
    if (this.emitDidUpdateAfterSetOptions && !R.equals(currentSelected, this.selectedOptions)) {
      this._emitUpdate();
    }
    this._cdRef.detectChanges();
  }
}
