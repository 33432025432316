import { Injectable, OnDestroy } from '@angular/core';
import { BBPTab } from '@sap/bbp-integrations/open-tab-signal-send-to-bbp';
import { isDefined } from '@sap/logic/shared/utils/pure-utils';
import { SessionStorageKeys } from '@sap/shared/enums';
import { SessionStorageService } from '@sap/shared/services/session-storage.service';
import * as R from 'rambdax';
import { Subject, Subscription, scan, switchMap, timer } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RefreshCounterCached implements OnDestroy {
  public refreshCounterSource$: Subject<[number, boolean]> = new Subject<[number, boolean]>();
  public sub: Subscription | undefined = undefined;
  public refreshInterval: number | undefined = undefined;
  public refreshRateOn: boolean | undefined = undefined;
  public valueToReturn: number = 0;

  public didChange$: Subject<void> = new Subject<void>();

  constructor(private _sessionStorage: SessionStorageService) {}

  public subscribeRefreshCounterSource(urlPathToKeepSub: string): void {
    this.sub = this.refreshCounterSource$
      .pipe(
        switchMap(([refreshInterval, _]: [number, boolean]) =>
          timer(0, 1000).pipe(scan((acc: number) => acc - 1, refreshInterval / 1000 + 1))
        )
      )
      .subscribe((seconds: number) => {
        if (this.refreshInterval! % (seconds * 1000) === 0) {
          this._checkTabExistsAndDestroyIfNot(urlPathToKeepSub);
        }
        this.valueToReturn = seconds;
        if (this.valueToReturn === 0) {
          this.didRefresh();
        }
        this.didChange$.next(undefined);
      });
  }

  public didRefresh(): void {
    this.refreshCounterSource$.next([this.refreshInterval!, this.refreshRateOn!]);
  }

  private _checkTabExistsAndDestroyIfNot(urlPathToKeepSub: string): void {
    const routerVisitedUrls: BBPTab[] = this._sessionStorage.getJson(SessionStorageKeys.routerVisitedUrls) ?? [];
    const index: number = routerVisitedUrls.findIndex(R.pipe(R.prop('url'), R.includes(urlPathToKeepSub)));
    if (this.sub && index === -1) {
      this.sub.unsubscribe();
      this.sub = undefined;
    }
  }

  public ngOnDestroy(): void {
    if (isDefined(this.sub)) {
      this.sub.unsubscribe();
      this.sub = undefined;
    }
  }
}
