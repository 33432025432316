import { Pipe, PipeTransform } from '@angular/core';
import { isAfter } from 'date-fns';

@Pipe({
  standalone: true,
  name: 'isAfterDate'
})
export class IsAfterDatePipe implements PipeTransform {
  public transform(date: Date | undefined | null, compareDate: Date): boolean {
    if (!date) {
      return false;
    }
    return isAfter(date, compareDate);
  }
}
