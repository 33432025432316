import { Injectable } from '@angular/core';
import { BetTracerRepository } from '@sap/logic/api-access/repository/bet-tracer-repository';
import { AuthState } from '@sap/logic/auth/services/auth-state';
import { BetInternalCommentDatabase } from '@sap/logic/firestore-database/bet-internal-comment-database';
import { UserInfo } from '@sap/logic/shared/models/user-info';
import { mapAs } from '@sap/logic/shared/rxjs/operators';
import { isDefined } from '@sap/logic/shared/utils/pure-utils';
import { Unsubscribable } from '@sap/shared/classes/unsubscribable';
import { BrandType, SessionStorageKeys } from '@sap/shared/enums';
import { SessionStorageService } from '@sap/shared/services/session-storage.service';
import { betTracerMain } from '@sap/ui/navs/left-navigation-drawer/navigation-items/bet-tracer';
import { RouterPlacesService } from '@sap/ui/shared/services/router-places.service';
import { BehaviorSubject, Observable, ReplaySubject, Subject, filter, mergeMap, of, withLatestFrom } from 'rxjs';
import { doSaveExpandedBetsToStorage } from '../../actions/do-on-save-expanded-bet-rows-local-storage';
import { BetTracerStreams } from '../bet-tracer-streams';
import { BetTracerOtherViewsData } from './bet-tracer-other-views-data';

@Injectable()
export class BetTracerOtherViewsBloc extends Unsubscribable {
  public doDisplayMoreBets$: BehaviorSubject<number> = this._streams.doDisplayMoreBets$;
  public doSaveExpandedIdsToStorage$: Subject<Record<string, boolean>> = new Subject<Record<string, boolean>>();

  public doSelectSetIdForComments$: ReplaySubject<number> = new ReplaySubject<number>(1);
  public doRefreshStatus$: Subject<[number, BrandType]> = new Subject<[number, BrandType]>();
  public doUpdateCommentForm$: Subject<string | undefined> = new Subject<string | undefined>();
  public doSubmitComment$: Subject<void> = new Subject<void>();

  constructor(
    private _sessionStorage: SessionStorageService,
    private _streams: BetTracerStreams,
    private _authState: AuthState,
    private _betCommentsDatabase: BetInternalCommentDatabase,
    private _betTracerRepository: BetTracerRepository,
    private _routerPlacesService: RouterPlacesService,
    public data: BetTracerOtherViewsData
  ) {
    super();
    this._init();
  }

  private _init(): void {
    this._handleCurrentDisplayedBets();
    this._handleExpandIds();
    this._handleAddCommentToBet();
    this._handleRefreshStatus();

    this._sub = this._routerPlacesService
      .getCurrentUrl()
      .pipe(filter((url: string) => url.startsWith(betTracerMain.url!)))
      .subscribe(() => {
        this._streams.doLoadMoreBetsFromApi$.next(0);
        this._streams.doResetLoadMore$.next(undefined);
      });
  }

  public getExpandedBetRows(): Observable<Record<number, boolean> | undefined> {
    return of(this._sessionStorage.getJson<Record<number, boolean>>(SessionStorageKeys.expandedBetRowsIds));
  }

  private _handleRefreshStatus(): void {
    this._sub = this.doRefreshStatus$.pipe(mergeMap(this._betTracerRepository.refreshBetStatus())).subscribe(() => {
      this.data.doForceRefresh$.next(undefined);
    });
  }

  private _handleAddCommentToBet(): void {
    this._sub = this.doSubmitComment$
      .pipe(
        withLatestFrom(
          this.doSelectSetIdForComments$,
          this.doUpdateCommentForm$,
          this._authState.getUserInfo().pipe(filter(Boolean), mapAs<UserInfo>())
        ),
        filter(([_1, _2, comment]: [void, number, string | undefined, UserInfo]) => isDefined(comment)),
        mapAs<[void, number, string, UserInfo]>()
      )
      .subscribe(([_, betId, comment, user]: [void, number, string, UserInfo]) => {
        this._betCommentsDatabase.addComment(betId, comment, user.email);
        this.doUpdateCommentForm$.next(undefined);
      });
  }

  private _handleCurrentDisplayedBets(): void {
    this._sub = this.doDisplayMoreBets$
      .pipe(withLatestFrom(this._streams.isLoadMoreAvailableFromApi$))
      .subscribe(([nextOffset, isLoadMoreAvailableFromApi]: [number, boolean]) => {
        if (isLoadMoreAvailableFromApi) {
          this._streams.doLoadMoreBetsFromApi$.next(nextOffset);
        } else if (nextOffset === 0) {
          // when offset has been reset
          this._streams.doLoadMoreBetsFromApi$.next(nextOffset);
        }
        this._streams.currentLoadMoreBetsOffset$.next(nextOffset);
      });
  }

  private _handleExpandIds(): void {
    this._sub = this.doSaveExpandedIdsToStorage$.subscribe(
      doSaveExpandedBetsToStorage({
        sessionStorage: this._sessionStorage,
        storageKey: SessionStorageKeys.expandedBetRowsIds
      })
    );
  }
}
