import { NavigationItem } from '../left-navigation-drawer.component';
import { defaultBrand } from './brand';

export const crmMain: NavigationItem = {
  title: 'CRM',
  url: undefined,
  icon: 'compass',
  scope: 'crm.main'
};

export const crmLowerTax: NavigationItem = {
  title: 'Lower Tax',
  url: '/lower-tax',
  icon: 'fax',
  scope: 'crm.lowerTaxSearch',
  preventMultipleTab: true
};

export const crmRateBoost: NavigationItem = {
  title: 'Rate Boost',
  url: '/rate-boost',
  icon: 'sort-ascending',
  scope: 'crm.rateBoostSearch',
  preventMultipleTab: true
};

export const crmApiTokens: NavigationItem = {
  title: 'API Tokens',
  url: '/api-tokens',
  icon: 'keyboard',
  queryParams: { brand: defaultBrand },
  scope: 'crm.apiTokens'
};
export const allCrmChildren: NavigationItem[] = [crmLowerTax, crmRateBoost, crmApiTokens];
