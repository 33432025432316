import { Params } from '@angular/router';
import * as R from 'rambdax';

export function mapQueryParamsBooleansToCorrectTypes(params: Params): Params {
  const keys: (string | number)[] = R.keys(params);
  const mappedParams: Params = {};
  for (const key of keys) {
    if (params[key] === 'true') {
      mappedParams[key] = true;
    } else if (params[key] === 'false') {
      mappedParams[key] = false;
    } else {
      mappedParams[key] = params[key];
    }
  }
  return mappedParams;
}
