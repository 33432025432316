import { Pipe, PipeTransform } from '@angular/core';
import { isSameDay } from 'date-fns';

@Pipe({
  standalone: true,
  name: 'isSameDay'
})
export class IsSameDayPipe implements PipeTransform {
  public transform(date: Date | undefined, compareDate: Date): boolean {
    if (!date) {
      return false;
    }
    return isSameDay(date, compareDate);
  }
}
