import { Pipe, PipeTransform } from '@angular/core';
import { isSameMonth } from 'date-fns';

@Pipe({
  standalone: true,
  name: 'isSameMonth'
})
export class IsSameMonthPipe implements PipeTransform {
  public transform(date: Date, compareDate: Date): boolean {
    return isSameMonth(date, compareDate);
  }
}
