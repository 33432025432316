import { BetInternalComment } from '@sap/logic/shared/models/bet-internal-comment';
import { MatchInternalComment } from '@sap/logic/shared/models/match-internal-comment';
import { DocumentData } from 'firebase/firestore';
import * as R from 'rambdax';
import { IndexedBetComments, IndexedMatchComments } from './types';

export function mapToMatchInternalComment(collection: Record<string, DocumentData[]>): IndexedMatchComments {
  return R.mapObject((value: DocumentData[]) => {
    return MatchInternalComment.fromJsonArray(value).reverse();
  }, collection) as IndexedMatchComments;
}

export function mapToBetInternalComment(collection: Record<string, DocumentData[]>): IndexedBetComments {
  return R.mapObject((value: DocumentData[]) => {
    return BetInternalComment.fromJsonArray(value).reverse();
  }, collection) as IndexedBetComments;
}
