import { Pipe, PipeTransform } from '@angular/core';
import { BetKind, BetWithSelections } from '@sap/logic/shared/models/bet';

@Pipe({ name: 'betKindWithClass' })
export class BetKindWithClassPipe implements PipeTransform {
  public transform(
    bet: BetWithSelections,
    classes: string[],
    isExpendable: boolean
  ): {
    class: string;
    text: BetKind | undefined;
  } {
    const isPreOrLiveBetKind: boolean = bet.kind === BetKind.live || bet.kind === BetKind.pre;
    if (isExpendable && isPreOrLiveBetKind) {
      return {
        class: bet.kind === BetKind.live ? classes[0] : classes[1],
        text: bet.kind
      };
    }
    if (isPreOrLiveBetKind) {
      return {
        class: bet.isLive ? classes[0] : classes[1],
        text: bet.isLive ? BetKind.live : BetKind.pre
      };
    }

    return {
      class: classes[2],
      text: bet.kind
    };
  }
}
