import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonComponent } from '@sap-design-system/components/button/button.component';
import { IconComponent } from '@sap-design-system/components/icon/icon.component';
import { DatePickerModule } from '@sap/ui/shared/components/date-picker/date-picker.module';
import { DateRangePickerComponent } from '@sap/ui/shared/components/date-range-picker/date-range-picker.component';
import { CoreuiComponentsModule } from '@sap/ui/shared/coreui-components/coreui-components.module';
import { DoOnValueChangeDirective } from '@sap/ui/shared/directives/do-on-value-change.directive';
import { InputSetValueDirective } from '@sap/ui/shared/directives/inputs/input-set-value.directive';
import { KeyupTargetNumericValueDirective } from '@sap/ui/shared/directives/inputs/keyup-target-numeric-value.directive';
import { KeyupTargetValueDirective } from '@sap/ui/shared/directives/inputs/keyup-target-value.directive';
import { FxThemeCssModule } from '@sap/ui/shared/fx-theme-css/fx-theme-css.module';
import { ArrayFilterPipe } from '@sap/ui/shared/pipes/array-filter.pipe';
import { DateObjFormatPipe } from '@sap/ui/shared/pipes/date-obj-format.pipe';
import { EnumKeysToArrayPipe } from '@sap/ui/shared/pipes/enum-keys-to-array.pipe';
import { EnumToOArrayWithObjectPipe } from '@sap/ui/shared/pipes/enum-to-array-object.pipe';
import { EnumValuesToArrayPipe } from '@sap/ui/shared/pipes/enum-values-to-array.pipe';
import { ToUpdatedArrayPipe } from '@sap/ui/shared/pipes/to-updated-array.pipe';
import { BetTracerTableToolComponent } from '../../components/bet-tracer-table-tool/bet-tracer-table-tool.component';
import { BetTracerTopFiltersComponent } from './bet-tracer-top-filters.component';
import { RefreshCounterPipe } from './refresh-counter.pipe';
import { TemplateVarTypedDirective } from './template-var-typed.directive';

@NgModule({
  declarations: [
    BetTracerTopFiltersComponent,
    TemplateVarTypedDirective,
    RefreshCounterPipe,
    BetTracerTableToolComponent
  ],
  imports: [
    CommonModule,
    KeyupTargetValueDirective,
    KeyupTargetNumericValueDirective,
    InputSetValueDirective,
    DateObjFormatPipe,
    DatePickerModule,
    FxThemeCssModule,
    CoreuiComponentsModule,
    ButtonComponent,
    IconComponent,
    ToUpdatedArrayPipe,
    ArrayFilterPipe,
    EnumValuesToArrayPipe,
    EnumKeysToArrayPipe,
    EnumToOArrayWithObjectPipe,
    DateRangePickerComponent,
    DoOnValueChangeDirective
  ],
  exports: [BetTracerTopFiltersComponent, BetTracerTableToolComponent]
})
export class BetTracerTopFiltersModule {}
