import { Directive } from '@angular/core';
import { NgContext, NgContextVars, TemplateVarDirective } from '@sap/ui/shared/directives/template-var.directive';

export type TemplateSettingsTyped = {
  expandedRows: Record<number, boolean>;
  addNewComment: number | undefined;
};

@Directive({
  selector: '[templateVarTyped]'
})
export class TemplateVarTypedDirective extends TemplateVarDirective<TemplateSettingsTyped> {
  protected override _initialContext: TemplateSettingsTyped = {
    expandedRows: {},
    addNewComment: undefined
  };

  public static override ngTemplateContextGuard<NgContextVarsTyped extends NgContextVars>(
    _dir: TemplateVarDirective<NgContextVarsTyped>,
    ctx: unknown
  ): ctx is NgContext<NgContextVarsTyped> {
    return true;
  }
}
