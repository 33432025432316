import { Injectable } from '@angular/core';
import { SharedBrand } from '@sap/logic/shared/brand/shared-brand';
import { SharedFilters } from '@sap/logic/shared/filters/shared-filters';
import { SportsGroup } from '@sap/logic/shared/models/sports-group';
import { mapToSportsGroupsWithoutUnassigned } from '@sap/logic/shared/pure-utils/to-sports-groups-without-unassigned';
import { Unsubscribable } from '@sap/shared/classes/unsubscribable';
import { SessionStorageKeys } from '@sap/shared/enums';
import { SessionStorageService } from '@sap/shared/services/session-storage.service';
import * as R from 'rambdax';
import { map, Observable, shareReplay, Subject } from 'rxjs';
import { mapToPopularSports } from './pure-utils';

@Injectable()
export class BetTracerFiltersBloc extends Unsubscribable {
  public doToggleFiltersVisibility$: Subject<boolean> = new Subject<boolean>();
  private _sportsGroupsWithoutUnassignedShareRef$!: Observable<SportsGroup[]>;

  constructor(
    public sharedBrand: SharedBrand,
    public sharedFilters: SharedFilters,
    private _sessionStorage: SessionStorageService
  ) {
    super();
    this._init();
  }

  private _init(): void {
    this._sub = this.doToggleFiltersVisibility$.subscribe((areVisible: boolean) =>
      this._saveFiltersVisibility(areVisible)
    );
  }

  public getFiltersVisibility(): boolean {
    return this._sessionStorage.hasKey(SessionStorageKeys.betTracerFiltersVisibility)
      ? this._sessionStorage.getBoolean(SessionStorageKeys.betTracerFiltersVisibility)
      : true;
  }

  public getSportsGroups(): Observable<SportsGroup[]> {
    return this._sportsGroupsWithoutUnassigned();
  }

  public getSportsSorted(): Observable<SportsGroup[]> {
    return this._getSports().pipe(map(R.pipe(R.sortByPath('name'))));
  }

  public getPopularSports(): Observable<SportsGroup[]> {
    return this._getSports().pipe(map(mapToPopularSports));
  }

  private _getSports(): Observable<SportsGroup[]> {
    return this._sportsGroupsWithoutUnassigned().pipe(map(R.filter(R.where({ parentId: R.equals(null) }))));
  }

  private _sportsGroupsWithoutUnassigned(): Observable<SportsGroup[]> {
    return (this._sportsGroupsWithoutUnassignedShareRef$ ??= this.sharedFilters.getSportsGroups().pipe(
      map((sportsGroups: SportsGroup[]) => mapToSportsGroupsWithoutUnassigned(sportsGroups)),
      shareReplay(1)
    ));
  }

  private _saveFiltersVisibility(areVisible: boolean): void {
    this._sessionStorage.setBoolean(SessionStorageKeys.betTracerFiltersVisibility, areVisible);
  }
}
