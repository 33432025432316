import { CommonModule, DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { UnsubscribableWithSetable } from '@sap/shared/classes/unsubscribable-with-setable';
import { DateRangePickerPanelComponent } from '@sap/ui/shared/components/date-range-picker/date-range-picker-panel/date-range-picker-panel.component';
import {
  NgContextVarsTyped,
  TemplateVarTypedDirective
} from '@sap/ui/shared/components/date-range-picker/template-var-typed.directive';
import { QuickOptionType } from '@sap/ui/shared/components/date-range-picker/types';
import { ClickDetachedDirective } from '@sap/ui/shared/directives/click-detached.directive';
import { ClickOutsideDirective } from '@sap/ui/shared/directives/click-outside.directive';
import { DragableHorizontalScrollDirective } from '@sap/ui/shared/directives/dragable-horizontal-scroll.directive';
import { KeyupTargetNumericValueDirective } from '@sap/ui/shared/directives/inputs/keyup-target-numeric-value.directive';
import { FxThemeCssModule } from '@sap/ui/shared/fx-theme-css/fx-theme-css.module';
import { DateObjFormatPipe } from '@sap/ui/shared/pipes/date-obj-format.pipe';
import { GetHoursPipe } from '@sap/ui/shared/pipes/get-hours.pipe';
import { GetMinutesPipe } from '@sap/ui/shared/pipes/get-minutes.pipe';
import { IsDefinedPipe } from '@sap/ui/shared/pipes/is-defined.pipe';
import {
  endOfMonth,
  endOfToday,
  endOfWeek,
  endOfYesterday,
  getHours,
  getMinutes,
  isSameDay,
  isSameHour,
  setHours,
  setMinutes,
  startOfMonth,
  startOfToday,
  startOfWeek,
  startOfYesterday,
  subHours
} from 'date-fns';
import * as R from 'rambdax';
import { Subject, asapScheduler } from 'rxjs';

@Component({
  selector: 'sap-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: true,
  imports: [
    //modules
    CommonModule,
    FxThemeCssModule,
    // components
    DateRangePickerPanelComponent,
    //directives
    TemplateVarTypedDirective,
    // pipes
    IsDefinedPipe,
    TemplateVarTypedDirective,
    ClickOutsideDirective,
    DateObjFormatPipe,
    KeyupTargetNumericValueDirective,
    ClickDetachedDirective,
    DragableHorizontalScrollDirective,
    GetHoursPipe,
    GetMinutesPipe
  ]
})
/* eslint-disable  @typescript-eslint/brace-style */
export class DateRangePickerComponent
  extends UnsubscribableWithSetable<DateRangePickerComponent>
  implements OnInit, AfterViewInit, OnDestroy
{
  public readonly DateRangePickerPanelComponent: typeof DateRangePickerPanelComponent = DateRangePickerPanelComponent;

  @ViewChild(TemplateVarTypedDirective) private _templateVarTypedDirective!: TemplateVarTypedDirective;

  @Input() enableTimeMode: boolean = false;
  @Input() showClear: boolean = true;
  @Input() hideQuickOptions: boolean = false;
  @Input() forceFiltersCss: boolean = false;
  @Input() onModal: boolean = false;
  @Output() didSelectDateRange: EventEmitter<(Date | undefined)[]> = new EventEmitter<(Date | undefined)[]>();
  @Output() doClose: EventEmitter<void> = new EventEmitter<void>();

  // quickOptionsMap
  get quickOptionsMap(): Record<QuickOptionType, (Date | undefined)[]> {
    return {
      now: [new Date(), undefined],
      last1Hour: [subHours(new Date(), 1), new Date()],
      last3Hours: [subHours(new Date(), 3), new Date()],
      today: [startOfToday(), endOfToday()],
      yesterday: [startOfYesterday(), endOfYesterday()],
      thisWeek: [startOfWeek(startOfToday(), { weekStartsOn: 1 }), endOfWeek(startOfToday(), { weekStartsOn: 1 })],
      thisMonth: [startOfMonth(startOfToday()), endOfMonth(startOfToday())]
    };
  }

  // dateFrom
  private _dateFrom: Date | undefined;
  @Input() set dateFrom(date: Date | undefined) {
    this._dateFrom = date;
  }
  get dateFrom(): Date | undefined {
    return this._dateFrom;
  }

  // dateTo
  private _dateTo: Date | undefined;
  @Input() set dateTo(date: Date | undefined) {
    this._dateTo = date;
  }
  get dateTo(): Date | undefined {
    return this._dateTo;
  }

  public updateDateRange$: Subject<(Date | undefined)[]> = new Subject<(Date | undefined)[]>();
  public doQuickOption$: Subject<QuickOptionType> = new Subject<QuickOptionType>();

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _renderer2: Renderer2
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.forceFiltersCss) {
      this._renderer2.addClass(this._document.body, 'datepicker-opened');
    }
    this._handleQuickOptions();
    this._handleUpdateDateRange();
  }

  public ngAfterViewInit(): void {
    this._updateContextVars();
  }

  public setMinutes(from: number, to: number): void {
    this.dateFrom = setMinutes(this.dateFrom!, from);
    if (this.dateTo) {
      this.dateTo = setMinutes(this.dateTo!, to);
    }
  }

  public setHours(from: number, to: number): void {
    this.dateFrom = setHours(this.dateFrom!, from);
    if (this.dateTo) {
      this.dateTo = setHours(this.dateTo!, to);
    }
  }

  private _handleQuickOptions(): void {
    this._sub = this.doQuickOption$.subscribe((type: QuickOptionType) => {
      this.dateFrom = this.quickOptionsMap[type][0];
      this.dateTo = this.quickOptionsMap[type][1];
      this._updateContextVars();
      asapScheduler.schedule(() => this._cdRef.detectChanges());
    });
  }

  private _handleUpdateDateRange(): void {
    this._sub = this.updateDateRange$.subscribe((dates: (Date | undefined)[]) => {
      this.dateFrom = dates[0];
      this.dateTo = dates[1];
      this._updateContextVars();
      asapScheduler.schedule(() => this._cdRef.detectChanges());
    });
  }

  private _updateContextVars(): void {
    const updatedSelectedQuickOption: QuickOptionType | undefined = R.keys(
      R.filterObject((type: (Date | undefined)[]) => {
        if (type[0] instanceof Date && type[1] instanceof Date) {
          return (
            this.dateFrom !== undefined &&
            this.dateTo !== undefined &&
            isSameDay(type[0], this.dateFrom) &&
            isSameDay(type[1], this.dateTo) &&
            isSameHour(type[0], this.dateFrom) &&
            isSameHour(type[1], this.dateTo)
          );
        }
        return false;
      }, this.quickOptionsMap)
    )[0] as QuickOptionType | undefined;
    const initialContextVarsTyped: NgContextVarsTyped = {
      selectedQuickOption: updatedSelectedQuickOption,
      hoursFrom: this.dateFrom ? getHours(this.dateFrom) : 0,
      hoursTo: this.dateTo ? getHours(this.dateTo) : 0,
      minutesFrom: this.dateFrom ? getMinutes(this.dateFrom) : 0,
      minutesTo: this.dateTo ? getMinutes(this.dateTo) : 0
    };
    this._templateVarTypedDirective.updateInitialContext(initialContextVarsTyped);
  }

  public ngOnDestroy(): void {
    this._renderer2.removeClass(this._document.body, 'datepicker-opened');
  }
}
