<ng-container *templateVarTyped="let vars = vars; let update = updateVar">
  <div class="datepicker" (clickOutside)="doClose.emit(undefined)" [class.datepicker-on-modal]="onModal">
    <div class="datepicker-bar" *ngIf="!vars.selectYearView">
      <button type="button" class="datepicker-btn-switch datepicker-btn-prev" (clickDetached)="switchToPrevMonth()">
        <i class="cil-arrow-left"></i>
      </button>
      <span class="datepicker-bar-title" (clickDetached)="update('selectYearView', true)">
        {{ date | dateObjFormat: 'LLLL' }} {{ date | dateObjFormat: 'yyyy' }}</span
      >
      <button type="button" class="datepicker-btn-switch datepicker-btn-next" (clickDetached)="switchToNextMonth()">
        <i class="cil-arrow-right"></i>
      </button>
    </div>
    <!-- <div class="datepicker-bar" *ngIf="!vars.yaerView && !vars.monthView">
      <button type="button" class="datepicker-btn-switch" (clickDetached)="switchToPrevMonth()">
        <i class="cil-arrow-left"></i>
      </button>
      <span class="datepicker-bar-title">
        <span (clickDetached)="update('monthView', true)">{{ monthDate | dateObjFormat: 'LLLL' }}</span>
        <span (clickDetached)="update('yaerView', true)">{{ monthDate | dateObjFormat: 'yyyy' }}</span>
      </span>
      <button type="button" class="datepicker-btn-switch" (clickDetached)="switchToNextMonth()">
        <i class="cil-arrow-right"></i>
      </button>
    </div> -->
    <ng-container *ngIf="!hideQuickOptions">
      <div class="datepicker-quick" dragableHorizontalScroll>
        <span class="datepicker-quick__option">Today</span>
        <span class="datepicker-quick__option">Yesterday</span>
        <span class="datepicker-quick__option">Last 7 Days</span>
        <span class="datepicker-quick__option">Last 30 Days</span>
      </div>
    </ng-container>
    <div class="datepicker-content">
      <ng-container *ngIf="!vars.selectYearView; else selectYearViewRef">
        <div class="datepicker-header datepicker-grid-row">
          <span class="datepicker-header-entry datepicker-grid-entry">mo</span>
          <span class="datepicker-header-entry datepicker-grid-entry">tu</span>
          <span class="datepicker-header-entry datepicker-grid-entry">we</span>
          <span class="datepicker-header-entry datepicker-grid-entry">th</span>
          <span class="datepicker-header-entry datepicker-grid-entry">fr</span>
          <span class="datepicker-header-entry datepicker-grid-entry">sa</span>
          <span class="datepicker-header-entry datepicker-grid-entry">su</span>
        </div>
        <div class="datepicker-days datepicker-grid">
          <ng-container *ngFor="let dayRow of calendarMatrix; trackBy: trackByIndex">
            <div class="datepicker-days-row datepicker-grid-row">
              <ng-container *ngFor="let day of dayRow; trackBy: trackByDay">
                <span
                  class="datepicker-days-entry datepicker-grid-entry"
                  [ngClass]="{
                    'diff-month prev-month': !(date | isSameMonth: day) || isRangeConflict(day),
                    'diff-month next-month': date | isNextMonth: day
                  }"
                  [class.disabled]="(disableBeforeDate | isAfterDate: day) || (disableAfterDate | isBeforeDate: day)"
                  [class.is-active]="date | isSameDay: day"
                  (clickDetached)="date = day"
                  >{{ day | dateObjFormat: 'dd' }}</span
                >
              </ng-container>
            </div>
          </ng-container>
        </div>

        <div class="datepicker-line"></div>

        <ng-container *ngIf="enableTimeMode">
          <div *ngIf="showFromLabel" class="datepicker-time-panel-header">FROM</div>
          <div class="datepicker-time">
            <div class="time-input">
              <input
                type="number"
                required
                (keyupTargetNumericValue)="update('hours', $event)"
                [setValue]="date | getHours"
              />
              <span class="label">hour</span>
            </div>
            <span class="datepicker-time-delimiter">:</span>
            <div class="time-input">
              <input
                type="number"
                required
                (keyupTargetNumericValue)="update('minutes', $event)"
                [setValue]="date | getMinutes"
              />
              <span class="label">minute</span>
            </div>
          </div>
        </ng-container>

        <div class="datepicker-buttons">
          <button
            type="button"
            [class.disabled]="(disableBeforeDate | isAfterDate: date) || (disableAfterDate | isBeforeDate: date)"
            class="datepicker-btn-choose"
            (clickDetached)="setHours(vars.hours); setMinutes(vars.minutes); didSelectDate.emit(date)"
          >
            select date
          </button>
          <button type="button" class="datepicker-btn-choose" (clickDetached)="didSelectDate.emit(undefined)">
            clear
          </button>
        </div>
      </ng-container>
      <ng-template #selectYearViewRef>
        <div class="datepicker-content">
          <div class="datepicker-years datepicker-grid">
            <ng-container *ngFor="let yearRow of yearMatrix">
              <div class="datepicker-years-row datepicker-grid-row">
                <ng-container *ngFor="let year of yearRow">
                  <span
                    class="datepicker-years-entry datepicker-grid-entry"
                    (clickDetached)="date = year; update('selectYearView', false)"
                    >{{ year | dateObjFormat: 'yyyy' }}</span
                  >
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
