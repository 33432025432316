import { mapAs } from '@sap/logic/shared/rxjs/operators';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  concatMap,
  distinctUntilChanged,
  interval,
  map,
  of,
  pairwise,
  startWith,
  takeUntil,
  takeWhile
} from 'rxjs';

export function lazyRenderSource<T>(
  source$: Subject<T[]> | BehaviorSubject<T[]> | ReplaySubject<T[]>,
  takeUntil$: Observable<void>,
  bufferSize: number = 1
): Observable<T[]> {
  return source$.pipe(
    distinctUntilChanged(),
    startWith([]),
    pairwise(),
    concatMap(([previous, current]: [T[], T[]]) =>
      previous.length === current.length
        ? of(current)
        : interval(0).pipe(
            map((i: number) => [current.slice(0, i * bufferSize), current]),
            mapAs<[T[], T[]]>(),
            takeWhile(([sliced, data]: [T[], T[]]) => sliced.length !== data.length, true),
            map(([sliced, _]: [T[], T[]]) => sliced)
          )
    ),
    takeUntil(takeUntil$)
  );
}
