import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { BetInternalComment } from '@sap/logic/shared/models/bet-internal-comment';

@Component({
  selector: 'sap-comment-create',
  templateUrl: './comment-create.component.html',
  styleUrls: ['./comment-create.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class CommentCreateComponent {
  private _betId: number | undefined;

  @Input() isValidForm: boolean = false;
  @Input() oldComments: BetInternalComment[] = [];
  @Input() set betId(betId: number) {
    this._betId = betId;
    this.doSelectSetIdForComments.next(betId);
  }
  get betId(): number | undefined {
    return this._betId;
  }

  @Output() doCancelEdit: EventEmitter<void> = new EventEmitter<void>();
  @Output() doSubmit: EventEmitter<void> = new EventEmitter<void>();
  @Output() doUpdateCommentForm: EventEmitter<string | undefined> = new EventEmitter<string | undefined>();
  @Output() doSelectSetIdForComments: EventEmitter<number> = new EventEmitter<number>();
}
