import { Pipe, PipeTransform } from '@angular/core';
import { isSameYear } from 'date-fns';

@Pipe({
  standalone: true,
  name: 'isSameYear'
})
export class IsSameYearPipe implements PipeTransform {
  public transform(date: Date, compareDate: Date): boolean {
    return isSameYear(date, compareDate);
  }
}
