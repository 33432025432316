<sapds-button title="Manage columns" iconName="settings">
  <multi-select
    appearance="button"
    valueProperty="value"
    displayProperty="label"
    [hideSearch]="true"
    [setSelectedOptions]="selectedOptions"
    [selectAllOption]="true"
    [resetOption]="true"
    [options]="manageableDefaultColumnOptions"
    (didChanged)="handleColumnsChange($event)"
    (selectReset)="handleResetColumns()"
  ></multi-select>
</sapds-button>
