export enum BetTraceColumNames {
  'time',
  'device',
  'clientId',
  'customer',
  'brand',
  'class',
  'event',
  'starting',
  'sportsGroups',
  'couponId',
  'eventIdAndMarketId',
  'selection',
  'odds',
  'stake',
  'risk',
  'payout',
  'type',
  'paid',
  'bonus',
  'betStatus',
  'selectionStatus',
  'statusUpdate',
  'forceStatus',
  'actions'
}
