import { Injectable } from '@angular/core';
import { MatchInternalComment } from '@sap/logic/shared/models/match-internal-comment';
import { FirestoreDatabase } from '@sap/shared-platform/providers/firestore/firestore-database';
import { from, map, mergeMap, Observable, takeUntil } from 'rxjs';
import { mapToMatchInternalComment } from './pure-utils';
import { mapToCommentsSortedByCreated } from './rx-operators';
import { IndexedMatchComments } from './types';

@Injectable({
  providedIn: 'root'
})
export class MatchInternalCommentDatabase {
  constructor(private _firestoreDatabase: FirestoreDatabase) {}

  public getIndexedMatchComments(): Observable<IndexedMatchComments> {
    return from(this._firestoreDatabase.getCollectionWithArrayField('matches', 'comments')).pipe(
      map(mapToMatchInternalComment)
    );
  }

  public getMatchComments(matchId: string): Observable<MatchInternalComment[]> {
    return this._firestoreDatabase
      .getIsUserSigned()
      .pipe(
        mergeMap(() =>
          this._firestoreDatabase
            .getArrayData('matches', 'comments', matchId)
            .pipe(
              takeUntil(this._firestoreDatabase.userSignOutSignal()),
              map(MatchInternalComment.fromJsonArray),
              mapToCommentsSortedByCreated()
            )
        )
      );
  }

  public addComment(matchId: string, comment: string, userEmail: string): void {
    this._firestoreDatabase.setDataInArray('matches', matchId, 'comments', {
      date: new Date().getTime(),
      message: comment,
      user: userEmail
    });
  }
}
