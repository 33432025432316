import { NavigationItem } from '../left-navigation-drawer.component';
import { defaultBrand } from './brand';

export const externalProductsMain: NavigationItem = {
  title: 'External Products',
  url: undefined,
  icon: 'input-power',
  scope: 'externalProducts.main'
};

export const externalProductsStreamings: NavigationItem = {
  title: 'Streamings',
  url: '/streamings',
  icon: 'video',
  scope: 'externalProducts.streamings'
};

export const externalProductsStreamsMapping: NavigationItem = {
  title: 'Streams Mapping',
  url: '/provider-streamings',
  icon: 'link-alt',
  queryParams: { brand: defaultBrand, tvStreamBooked: true, isPre: true, streamProvider: 'betRadar' },
  scope: 'externalProducts.streamMapping',
  brandsScope: [defaultBrand]
};

export const externalProductsSportcastMapping: NavigationItem = {
  title: 'Sportcast Mapping',
  url: '/provider-matches',
  icon: 'transfer',
  queryParams: {
    sort: 'id',
    brand: defaultBrand,
    isPre: true,
    isActive: true,
    matchActive: new Date().toISOString(),
    matchProvider: 'sc',
    providerLimit: 400
  },
  scope: 'externalProducts.sportcastMapping',
  brandsScope: [defaultBrand]
};

export const allExternalProductsChildren: NavigationItem[] = [
  externalProductsStreamings,
  externalProductsStreamsMapping,
  externalProductsSportcastMapping
];
