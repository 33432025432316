import { Pipe, PipeTransform } from '@angular/core';
import { DateConstants } from '@sap/logic/shared/constants';
import { isDefined } from '@sap/logic/shared/utils/pure-utils';
import { zonedTimeToUtc } from '@sap/shared/miscs/utils';
import { add, format } from 'date-fns';

@Pipe({ name: 'dateUtcTitle', standalone: true })
export class DateUtcTitlePipe implements PipeTransform {
  public transform(
    value: Date | undefined,
    formatString: string = DateConstants.DateTime24Format,
    addHours?: number
  ): string {
    // Generating time zone information: 19.04.2023 11:42 [UTC: 19.04.2023 09:42]
    if (isDefined(addHours)) {
      return value
        ? `${format(add(value, { hours: addHours }), formatString)} [UTC: ${format(
            add(zonedTimeToUtc(value), { hours: addHours }),
            formatString
          )}]`
        : '';
    }
    return value ? `${format(value, formatString)}  [UTC: ${format(zonedTimeToUtc(value), formatString)}]` : '';
  }
}
