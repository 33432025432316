import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BetTracerOtherViewsBloc } from '@sap/logic/bet-tracer/bloc/bet-tracer/other-views/bet-tracer-other-views.bloc';
import { betTracerOtherDeps } from '@sap/logic/bet-tracer/bloc/bet-tracer/other-views/deps';
import { IndexedBetComments, IndexedMatchComments } from '@sap/logic/firestore-database/types';
import { Betslip } from '@sap/logic/shared/models/betslip';
import { BettingLocation } from '@sap/logic/shared/models/betting-location';
import { distinctUntilEmittedValuesChanged } from '@sap/logic/shared/rxjs/operators';
import { Unsubscribable } from '@sap/shared/classes/unsubscribable';
import { BrandType } from '@sap/shared/enums';
import { mapQueryParamsBooleansToCorrectTypes } from '@sap/shared/helpers/map-query-params';
import { transferRxNext } from '@sap/shared/helpers/rx-helpers';
import { SetAndDetect, setAndDetect } from '@sap/ui/shared/helpers/set-and-detect';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { BetTraceColumNames } from '../../components/bet-tracer-table-tool/enum';
import { BetTracerMainDataViewModule } from '../bet-tracer-main-data-view/bet-tracer-main-data-view.module';
import { BetTracerTopFiltersModule } from '../bet-tracer-top-filters/bet-tracer-top-filters.module';
import { OddsFormat } from '../bet-tracer-top-filters/enums';

@Component({
  standalone: true,
  selector: 'sap-bet-tracer-other-view',
  templateUrl: './bet-tracer-other-view.component.html',
  imports: [CommonModule, BetTracerTopFiltersModule, BetTracerMainDataViewModule],
  encapsulation: ViewEncapsulation.ShadowDom,
  providers: betTracerOtherDeps
})
export class BetTracerOtherViewComponent extends Unsubscribable implements OnInit, OnDestroy {
  private _set: SetAndDetect<this>;

  public calculatedPayouts: number = 0;
  public calculatedTurnover: number = 0;
  public currentOffset: number = 0;
  public bettingLocations: BettingLocation[] = [];
  public isLoadingBettingLocations: boolean = true;
  public isFiltersSticky: boolean = false;
  public isLoading: boolean = true;
  public betsNotFound: boolean = false;
  public isLoadingMoreBets: boolean = false;
  public noAccessByBrands: boolean = false;
  public isLoadMoreAvailableFromApi: boolean = true;
  public isMoreBetsToDisplay: boolean = true;
  public bets: Betslip[] = [];
  public displayedColumns: BetTraceColumNames[] = [];
  public indexedMatchComments: IndexedMatchComments = {};
  public indexedBetComments: IndexedBetComments = {};
  public refreshState: [number, boolean] | undefined;
  public selectedOddsFormat: OddsFormat = OddsFormat.decimal;
  public expandedBetRowsIds: Record<number, boolean> | undefined;

  public doRefresh$: Subject<[number, boolean]> = this._bloc.data.doRefresh$;
  public doForceRefresh$: Subject<void> = this._bloc.data.doForceRefresh$;
  public isLoadingMoreBets$: Subject<boolean> = this._bloc.data.isLoadingMoreBets$;
  public doSelectOddFormat$: BehaviorSubject<OddsFormat> = new BehaviorSubject<OddsFormat>(OddsFormat.decimal);
  public displayedColumns$: ReplaySubject<BetTraceColumNames[]> = new ReplaySubject<BetTraceColumNames[]>();
  public doResetColumns$: Subject<void> = new Subject<void>();
  public isFiltersSticky$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public doDisplayMoreBets$: BehaviorSubject<number> = this._bloc.doDisplayMoreBets$;
  public doSelectSetIdForComments$: ReplaySubject<number> = this._bloc.doSelectSetIdForComments$;
  public doRefreshStatus$: Subject<[number, BrandType]> = this._bloc.doRefreshStatus$;
  public doUpdateCommentForm$: Subject<string | undefined> = this._bloc.doUpdateCommentForm$;
  public doSubmitComment$: Subject<void> = this._bloc.doSubmitComment$;
  public doSaveExpandedBetRowsIds$: Subject<Record<number, boolean>> = this._bloc.doSaveExpandedIdsToStorage$;

  constructor(
    private _cdRef: ChangeDetectorRef,
    private _bloc: BetTracerOtherViewsBloc,
    private _activatedRoute: ActivatedRoute
  ) {
    super();
    this._set = setAndDetect(this, this._cdRef);
  }
  public ngOnInit(): void {
    this._bloc.data.viewInitiated$.next(true);
    this._sub = this._bloc.data.getIndexedMatchComments().subscribe(this._set('indexedMatchComments'));
    this._sub = this._bloc.data.getIndexedBetComments().subscribe(this._set('indexedBetComments'));
    this._sub = this._bloc.data.getCalculatedPayouts().subscribe(this._set('calculatedPayouts'));
    this._sub = this._bloc.data.getCalculatedTurnover().subscribe(this._set('calculatedTurnover'));
    this._sub = this._bloc.data.getBettingLocations().subscribe(this._set('bettingLocations'));
    this._sub = this._bloc.data.isLoadingBettingLocations$.subscribe(this._set('isLoadingBettingLocations'));
    this._sub = this._bloc.data.isLoading$.subscribe(this._set('isLoading'));
    this._sub = this._bloc.data.betsNotFound$.subscribe(this._set('betsNotFound'));
    this._sub = this._bloc.data.doRefresh$.subscribe(this._set('refreshState'));
    this._sub = this._bloc.data.getBets().subscribe(this._set('bets'));
    this._sub = this._bloc.data.getIsMoreBetsToDisplay().subscribe(this._set('isMoreBetsToDisplay'));
    this._sub = this._bloc.data.noAccessByBrands$.subscribe(this._set('noAccessByBrands'));
    this._sub = this._bloc.getExpandedBetRows().subscribe(this._set('expandedBetRowsIds'));
    this._sub = this.doSelectOddFormat$.pipe(distinctUntilChanged()).subscribe(this._set('selectedOddsFormat'));
    this._sub = this.displayedColumns$.subscribe(this._set('displayedColumns'));
    this._sub = this.isLoadingMoreBets$.subscribe(this._set('isLoadingMoreBets'));
    this._sub = this.doDisplayMoreBets$.subscribe(this._set('currentOffset'));
    this._sub = this.isFiltersSticky$.subscribe(this._set('isFiltersSticky'));
    this._sub = this._activatedRoute.queryParams
      .pipe(
        debounceTime(50), // Prevent call unnecessary API requests
        map(mapQueryParamsBooleansToCorrectTypes),
        distinctUntilEmittedValuesChanged()
      )
      .subscribe(transferRxNext(this._bloc.data.filters$));
  }

  public ngOnDestroy(): void {
    this._unsubscribeAll();
    this._bloc.data.viewInitiated$.next(false);
  }
}
