import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Unsubscribable } from '@sap/shared/classes/unsubscribable';
import { TemplateVarTypedDirective } from '@sap/ui/shared/components/date-range-picker/date-range-picker-panel/template-var-typed.directive';
import { DateRangeType } from '@sap/ui/shared/components/date-range-picker/types';
import { ClickDetachedDirective } from '@sap/ui/shared/directives/click-detached.directive';
import { DateObjFormatPipe } from '@sap/ui/shared/pipes/date-obj-format.pipe';
import { IsBetweenDatesPipe } from '@sap/ui/shared/pipes/is-between-dates.pipe';
import { IsSameDayPipe } from '@sap/ui/shared/pipes/is-same-day.pipe';
import { IsSameMonthPipe } from '@sap/ui/shared/pipes/is-same-month.pipe';
import { IsSameYearPipe } from '@sap/ui/shared/pipes/is-same-year.pipe';
import {
  addMonths,
  addYears,
  eachDayOfInterval,
  eachMonthOfInterval,
  eachWeekOfInterval,
  endOfDay,
  endOfISOWeek,
  endOfMonth,
  endOfToday,
  endOfYear,
  isBefore,
  startOfISOWeek,
  startOfMonth,
  startOfYear,
  subMonths,
  subYears
} from 'date-fns';
import * as R from 'rambdax';
import { Subject } from 'rxjs';

@Component({
  selector: 'sap-date-range-picker-panel',
  templateUrl: './date-range-picker-panel.component.html',
  styleUrls: ['./date-range-picker-panel.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: true,
  imports: [
    //modules
    CommonModule,
    // directives
    TemplateVarTypedDirective,
    ClickDetachedDirective,
    DateObjFormatPipe,
    IsSameMonthPipe,
    IsBetweenDatesPipe,
    IsSameDayPipe,
    IsSameMonthPipe,
    IsSameMonthPipe,
    IsSameMonthPipe,
    IsSameMonthPipe,
    IsSameYearPipe
  ]
})
export class DateRangePickerPanelComponent extends Unsubscribable implements OnInit {
  private readonly _numOfFutureYears: number = 10;

  @Input() type!: DateRangeType;
  @Output() didUpdateDateRange: EventEmitter<(Date | undefined)[]> = new EventEmitter<(Date | undefined)[]>();

  // dateFrom
  private _dateFrom: Date | undefined;
  @Input() set dateFrom(date: Date | undefined) {
    this._dateFrom = date;
  }
  get dateFrom(): Date | undefined {
    return this._dateFrom;
  }

  // dateTo
  private _dateTo: Date | undefined;
  @Input() set dateTo(date: Date | undefined) {
    this._dateTo = date;
  }
  get dateTo(): Date | undefined {
    return this._dateTo;
  }

  public monthDate: Date = new Date();
  public selectDay$: Subject<Date> = new Subject<Date>();

  public get monthMatrix(): Date[][] {
    return eachWeekOfInterval(
      {
        start: startOfMonth(this.monthDate),
        end: endOfMonth(this.monthDate)
      },
      { weekStartsOn: 1 }
    ).map((weekDay: Date) =>
      eachDayOfInterval({
        start: startOfISOWeek(weekDay),
        end: endOfISOWeek(weekDay)
      })
    );
  }

  public get yearMatrix(): Date[][] {
    return R.piped(
      R.range(0, 35),
      R.mapArray((year: number) => subYears(addYears(endOfToday(), this._numOfFutureYears), year)),
      R.reverse as any,
      R.reduce((acc: Date[][], current: Date, index: number | undefined) => {
        const chunkIndex: number = Math.floor(index! / 7);
        if (!acc[chunkIndex]) {
          acc[chunkIndex] = [];
        }
        acc[chunkIndex] = R.append(current, acc[chunkIndex]);
        return acc;
      }, [])
    );
  }

  public get monthsInYearMatrix(): Date[][] {
    return eachMonthOfInterval({
      start: startOfYear(this.monthDate),
      end: endOfYear(this.monthDate)
    }).reduce((acc: Date[][], current: Date, index: number | undefined) => {
      const chunkIndex: number = Math.floor(index! / 4);
      if (!acc[chunkIndex]) {
        acc[chunkIndex] = [];
      }
      acc[chunkIndex] = R.append(current, acc[chunkIndex]);
      return acc;
    }, []);
  }

  constructor(public elementRef: ElementRef) {
    super();
  }

  public ngOnInit(): void {
    this._handleDateSelect();
    this._initMonthDate();
  }

  private _initMonthDate(): void {
    this.monthDate = this.type === 'first' ? this.dateFrom || new Date() : this.dateTo || this.dateFrom || new Date();
  }

  private _handleDateSelect(): void {
    this._sub = this.selectDay$.subscribe((selectedDay: Date) => {
      if (!this.dateFrom || this.dateTo || isBefore(selectedDay, this.dateFrom)) {
        this.dateFrom = selectedDay;
        this.dateTo = undefined;
      } else {
        this.dateTo = endOfDay(selectedDay);
      }
      this.didUpdateDateRange.emit([this.dateFrom, this.dateTo]);
    });
  }

  public switchToNextMonth(): void {
    this.monthDate = addMonths(this.monthDate, 1);
  }

  public switchToPrevMonth(): void {
    this.monthDate = subMonths(this.monthDate, 1);
  }

  public trackByIndex(index: number): number {
    return index;
  }

  public trackByDay(index: number, day: Date): number {
    return day.getTime();
  }
}
