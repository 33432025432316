<ng-container fxThemeCss></ng-container>
<ng-container *templateVarTyped="let vars = vars; let update = updateVar; let updateObj = updateVarObject">
  <ng-container *ngIf="!noAccess; else noAccessTemplate">
    <ng-container
      [skipInitialEmit]="true"
      [doOnValueChange]="vars.expandedRows"
      (didValueChanged)="doSaveExpandedBetRowsIds.emit(vars.expandedRows)"
    ></ng-container>
    <div class="bet-tracer">
      <ng-container *ngIf="(bets.length === 0 || betsNotFound) && !isLoading">
        <h1 class="text-center mt-3 width-100">no bets found</h1>
      </ng-container>
      <ng-container *ngIf="isLoading; then stillLoadingRef"></ng-container>
      <ng-container *ngIf="vars.addNewComment">
        <sap-comment-create
          [oldComments]="indexedBetComments[vars.addNewComment]"
          [betId]="vars.addNewComment"
          (doSubmit)="doSubmitComment.next($event)"
          (doUpdateCommentForm)="doUpdateCommentForm.next($event)"
          (doSelectSetIdForComments)="doSelectSetIdForComments.next($event)"
          (doCancelEdit)="update('addNewComment', undefined)"
        ></sap-comment-create>
      </ng-container>
      <table class="table table-striped align-middle table-wrapper" [class.is-updating]="isLoading">
        <thead
          class="align-middle table-head sticky"
          [class.has-filters-sticky]="isFiltersSticky"
          [hidden]="betsNotFound || bets.length === 0"
        >
          <tr [hiddenColumns]="displayedColumns">
            <th class="table-cell_medium" columnResize [columnResizeReset]="resetColumns$">Time</th>
            <th columnResize [columnResizeReset]="resetColumns$">Device</th>
            <th columnResize [columnResizeReset]="resetColumns$">
              <sap-text-overflow [config]="textOverflowConfig" [text]="'Client ID'"></sap-text-overflow>
            </th>
            <th columnResize [columnResizeReset]="resetColumns$">
              <sap-text-overflow [config]="textOverflowConfig" [text]="'Customer'"></sap-text-overflow>
            </th>
            <th columnResize [columnResizeReset]="resetColumns$">Brand</th>
            <th columnResize [columnResizeReset]="resetColumns$">Class</th>
            <th columnResize [columnResizeReset]="resetColumns$">Event</th>
            <th columnResize [columnResizeReset]="resetColumns$">
              <sap-text-overflow [config]="textOverflowConfig" [text]="'Starting'"></sap-text-overflow>
            </th>
            <th columnResize [columnResizeReset]="resetColumns$">
              <sap-text-overflow [config]="textOverflowConfig" [text]="'Sport / Country / League'"></sap-text-overflow>
            </th>
            <th columnResize [columnResizeReset]="resetColumns$">
              <sap-text-overflow [config]="textOverflowConfig" [text]="'Coupon ID'"></sap-text-overflow>
            </th>
            <th columnResize [columnResizeReset]="resetColumns$">
              <sap-text-overflow [config]="textOverflowConfig" [text]="'Event ID'">/</sap-text-overflow>
              <sap-text-overflow [config]="textOverflowConfig" [text]="'Market ID'"></sap-text-overflow>
            </th>
            <th columnResize [columnResizeReset]="resetColumns$">
              <sap-text-overflow [config]="textOverflowConfig" [text]="'Selection'"></sap-text-overflow>
            </th>
            <th columnResize [columnResizeReset]="resetColumns$">Odds</th>
            <th columnResize [columnResizeReset]="resetColumns$">
              <sap-text-overflow [config]="textOverflowConfig" [text]="'Stake'">/</sap-text-overflow>
              <sap-text-overflow [config]="textOverflowConfig" [text]="'Stake [€]'"></sap-text-overflow>
            </th>
            <th columnResize [columnResizeReset]="resetColumns$">Risk</th>
            <th columnResize [columnResizeReset]="resetColumns$">
              <sap-text-overflow [config]="textOverflowConfig" [text]="'Payout'">/</sap-text-overflow>
              <sap-text-overflow [config]="textOverflowConfig" [text]="'Payout [€]'"></sap-text-overflow>
            </th>
            <th columnResize [columnResizeReset]="resetColumns$">Type</th>
            <th columnResize [columnResizeReset]="resetColumns$">Paid</th>
            <th columnResize [columnResizeReset]="resetColumns$">Bonus</th>
            <th columnResize [columnResizeReset]="resetColumns$">
              <sap-text-overflow [config]="textOverflowConfig" [text]="'Bet Status'"></sap-text-overflow>
            </th>
            <th columnResize [columnResizeReset]="resetColumns$">
              <sap-text-overflow [config]="textOverflowConfig" [text]="'Selection Status'"></sap-text-overflow>
            </th>
            <th columnResize [columnResizeReset]="resetColumns$">
              <sap-text-overflow [config]="textOverflowConfig" [text]="'Status Update'"></sap-text-overflow>
            </th>
            <th columnResize [columnResizeReset]="resetColumns$">
              <sap-text-overflow [config]="textOverflowConfig" [text]="'Force'">/</sap-text-overflow>
              <sap-text-overflow [config]="textOverflowConfig" [text]="'Invalid Status'"></sap-text-overflow>
            </th>
            <th columnResize class="table-cell_small" [columnResizeReset]="resetColumns$">
              <sap-text-overflow [config]="textOverflowConfig" [text]="'Event'">/</sap-text-overflow>
              <sap-text-overflow [config]="textOverflowConfig" [text]="'Resulting Manager'">/</sap-text-overflow>
              <sap-text-overflow [config]="textOverflowConfig" [text]="'Actions'"></sap-text-overflow>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="!betsNotFound && bets.length > 0">
          <ng-container *ngFor="let betslip of bets; trackBy: ngForTracker.byId; let betslipIndex = index">
            <ng-container [ngSwitch]="betslip.type">
              <!-- single betslips -->
              <ng-container
                *ngSwitchCase="BetType.single"
                [ngTemplateOutlet]="singleBetTemplate"
                [ngTemplateOutletContext]="{ betslip, bets: betslip.bets.flat(1), isCoupon: true, class: '' }"
              >
              </ng-container>
              <!-- combi betslips -->
              <ng-container
                *ngSwitchCase="BetType.combi"
                [ngTemplateOutlet]="combiBetTemplate"
                [ngTemplateOutletContext]="{
                  betslip,
                  betslipId: betslip.id,
                  bets: betslip.bets.flat(1),
                  isCoupon: true,
                  class: 'bet-tracer-item_combi'
                }"
              >
              </ng-container>
              <!-- system betslips -->
              <ng-container
                *ngSwitchCase="BetType.system"
                [ngTemplateOutlet]="systemBetTemplate"
                [ngTemplateOutletContext]="{
                  betslip,
                  betslipId: betslip.id,
                  bets: betslip.bets,
                  isCoupon: true,
                  class: ''
                }"
              >
              </ng-container>
            </ng-container>

            <ng-template
              #singleBetTemplate
              let-betslip="betslip"
              let-bets="bets"
              let-collapsed="collapsed"
              let-isCoupon="isCoupon"
              let-class="class"
            >
              <ng-container *ngFor="let bet of bets; let betIndex = index">
                <sap-bet-tracer-item
                  *ngIf="!collapsed"
                  [class]="class"
                  [betslip]="betslip"
                  [bet]="bet"
                  [commentsLength]="indexedBetComments[betslip.id] | arrayLength"
                  [isCoupon]="isCoupon"
                  [hiddenColumns]="displayedColumns"
                  [oddsFormat]="selectedOddsFormat"
                  [indexedMatchComments]="indexedMatchComments"
                  [indexedBetComments]="indexedBetComments[betslip.id]"
                  (addComment)="update('addNewComment', $event)"
                  (doRefreshStatus)="doRefreshStatus.next($event)"
                ></sap-bet-tracer-item>
              </ng-container>
            </ng-template>

            <ng-template
              #combiBetTemplate
              let-betslip="betslip"
              let-betslipId="betslipId"
              let-bets="bets"
              let-isCoupon="isCoupon"
              let-collapsed="collapsed"
              let-class="class"
            >
              <ng-container *ngFor="let bet of bets; let betIndex = index">
                <ng-container *ngIf="betIndex === 0">
                  <sap-bet-tracer-item
                    *ngIf="!collapsed"
                    [class]="class"
                    [betslip]="betslip"
                    [bet]="bet"
                    [isExpendable]="true"
                    [isExpanded]="vars.expandedRows[betslipId]"
                    [betslipId]="betslipId"
                    [commentsLength]="indexedBetComments[betslip.id] | arrayLength"
                    [indexedBetComments]="indexedBetComments[betslip.id]"
                    [isCoupon]="isCoupon"
                    [hiddenColumns]="displayedColumns"
                    [oddsFormat]="selectedOddsFormat"
                    (doToggleBetId)="updateObj('expandedRows', betslipId, !vars.expandedRows[betslipId])"
                    (addComment)="update('addNewComment', $event)"
                    (doRefreshStatus)="doRefreshStatus.next($event)"
                  ></sap-bet-tracer-item>
                </ng-container>
                <sap-bet-tracer-item
                  *ngIf="vars.expandedRows[betslipId] && !collapsed"
                  [class]="'bet-tracer-item_expandable ' + class"
                  [class.bet-tracer-item_expanded]="vars.expandedRows[betslipId] && !collapsed"
                  [betslip]="betslip"
                  [bet]="bet"
                  [indexedMatchComments]="indexedMatchComments"
                  [indexedBetComments]="indexedBetComments[betslip.id]"
                  [oddsFormat]="selectedOddsFormat"
                  [hiddenColumns]="displayedColumns"
                ></sap-bet-tracer-item>
              </ng-container>
            </ng-template>

            <ng-template
              #systemBetTemplate
              let-betslip="betslip"
              let-betslipId="betslipId"
              let-bets="bets"
              let-isCoupon="isCoupon"
            >
              <ng-container *ngFor="let betsArray of bets; let betArrayIndex = index">
                <ng-container *ngIf="betArrayIndex === 0">
                  <sap-bet-tracer-item
                    [betslip]="betslip"
                    [isExpendable]="true"
                    [isExpanded]="vars.expandedRows[betslipId]"
                    [betslipId]="betslipId"
                    [commentsLength]="indexedBetComments[betslip.id] | arrayLength"
                    [indexedBetComments]="indexedBetComments[betslip.id]"
                    [isCoupon]="isCoupon"
                    [hiddenColumns]="displayedColumns"
                    [oddsFormat]="selectedOddsFormat"
                    (doToggleBetId)="updateObj('expandedRows', betslipId, !vars.expandedRows[betslipId])"
                    (addComment)="update('addNewComment', $event)"
                    (doRefreshStatus)="doRefreshStatus.next($event)"
                  ></sap-bet-tracer-item>
                </ng-container>

                <ng-container [ngSwitch]="betsArray[0].type">
                  <ng-container
                    *ngSwitchCase="BetType.single"
                    [ngTemplateOutlet]="singleBetTemplate"
                    [ngTemplateOutletContext]="{
                      betslip,
                      bets: betsArray,
                      collapsed: !vars.expandedRows[betslipId],
                      class: 'bet-tracer-item_system-single'
                    }"
                  >
                  </ng-container>
                  <ng-container
                    *ngSwitchCase="BetType.combi"
                    [ngTemplateOutlet]="combiBetTemplate"
                    [ngTemplateOutletContext]="{
                      betslip,
                      betslipId: betslipId + betsArray[0]?.betItemId,
                      bets: betsArray,
                      collapsed: !vars.expandedRows[betslipId],
                      class: 'bet-tracer-item_system-combi'
                    }"
                  >
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-template>
          </ng-container>
        </tbody>
      </table>
      <!-- Load more button -->
      <div class="load-more-wrapper d-flex justify-content-center">
        <ng-container *ngIf="!(betsNotFound || bets.length < 100)">
          <sapds-button
            class="button d-flex justify-content-center align-items-center load-more"
            [isDisabled]="!isMoreBetsToDisplay"
            [isLoading]="isLoadingMoreBets"
            [class.u-disabled]="isLoadingMoreBets || isLoading"
            (click)="displayMoreBets.next(currentOffset! + 1)"
          >
            {{ isMoreBetsToDisplay ? 'Load more bets' : 'No more bets' }}
          </sapds-button>
        </ng-container>
      </div>
    </div>

    <!--  templates -->
    <ng-template #stillLoadingRef>
      <div class="loading justify-content-center mt-4">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #noAccessTemplate>
    <div class="d-flex justify-content-center mt-4">
      <span class="u-text-danger"> No data available for your permissions. </span>
    </div>
  </ng-template>
</ng-container>
