import { ChangeDetectorRef, Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

type MouseEventWithPath = MouseEvent & {
  path: HTMLElement[];
};

@Directive({
  selector: '[clickOutside]',
  standalone: true
})
export class ClickOutsideDirective {
  @Output() clickOutside: EventEmitter<EventTarget> = new EventEmitter<EventTarget>();
  @Input() triggerWhen?: boolean;
  @Input() exceptions?: HTMLElement[];
  @Input() componentsTagRef?: string[];
  @Input() skip?: number;

  private _skipped: number = 0;

  constructor(
    private _elementRef: ElementRef,
    private _cdRef: ChangeDetectorRef
  ) {}

  @HostListener('document:mousedown', ['$event'])
  public onDocumentClick(event: MouseEventWithPath): void {
    if (this.skip && this.skip > 0 && this._skipped < this.skip) {
      this._skipped++;
      return;
    }

    // we get first element from path, because when shadowroot enabled it get container not clicked element
    // https://developpaper.com/firefox-and-safari-are-compatible-with-event-path/
    const path: Element[] = event.path || (event.composedPath && event.composedPath());
    const pathLocalNames: string[] = path.map((eventTarget: Element) => eventTarget.localName);
    const targetElement: EventTarget | null = path[0];
    if (this.exceptions && this.exceptions.includes(targetElement as HTMLElement)) {
      return;
    }
    let componentClicked: boolean = false;
    this.componentsTagRef?.forEach((componentTagRef: string) => {
      if (pathLocalNames.includes(componentTagRef)) {
        componentClicked = true;
      }
    });
    if (
      (typeof this.triggerWhen === 'undefined' || this.triggerWhen) &&
      targetElement &&
      !this._elementRef.nativeElement.contains(targetElement) &&
      !componentClicked
    ) {
      this.clickOutside.emit(targetElement);
      this._cdRef.detectChanges();
    }
  }
}
