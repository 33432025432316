import { Injectable } from '@angular/core';
import { BetslipsResult, BetTracerApi, FiltersApi } from '@sap/logic/api-access/api/bet-tracer-api';
import { BetsNotFoundError, predicateNotFoundBetsError } from '@sap/logic/api-access/errors/betslips-not-found-error';
import { pickSelectedBrands } from '@sap/logic/bet-tracer/bloc/bet-tracer/pure-utils/pick-seleted-brands';
import { Betslip } from '@sap/logic/shared/models/betslip';
import { BettingLocation } from '@sap/logic/shared/models/betting-location';
import { isDefined } from '@sap/logic/shared/utils/pure-utils';
import { BrandType } from '@sap/shared/enums';
import { asPromise } from '@sap/shared/helpers/rx-helpers';
import * as R from 'rambdax';
import { forkJoin, from, Observable } from 'rxjs';
import { LocationsApi } from '../api/locations-api';
import { MessageError, predicateNotFoundError } from '../errors/message-error';

const getLoadMoreAvailable: (nextKeysForBrandArray: Record<string, number>[], offset: number) => boolean = (
  nextKeysForBrandArray: Record<string, number>[],
  offset: number
): boolean => {
  if (nextKeysForBrandArray.length === 0) {
    return true; // initial value
  }
  return nextKeysForBrandArray[offset] ? Object.values(nextKeysForBrandArray[offset]).some(Boolean) : false;
};

export type LoadMoreResult = {
  bets: Betslip[];
  isRefreshing: boolean;
  loadMoreAvailable: boolean;
};

@Injectable()
export class BetTracerRepository {
  private _nextKeysForBrand: Record<string, Record<string, number>> = {};
  private _currentBrands: BrandType[] | undefined;
  private _currentRefreshState: number | void | undefined;
  private _currentOffset: number = 0;

  constructor(
    private _betTracerApi: BetTracerApi,
    private _locationsApi: LocationsApi
  ) {}

  public getBetsFilteredAndSorted(): ([filters]: [FiltersApi, number | void | undefined]) => Promise<Betslip[]> {
    return async ([filters]: [FiltersApi, number | void | undefined]): Promise<Betslip[]> => {
      const brands: BrandType[] = pickSelectedBrands(filters);
      const requestsToMake: Observable<BetslipsResult | BetsNotFoundError>[] = [];
      if (isDefined(this._currentBrands) && this._currentBrands.length !== brands.length) {
        this._nextKeysForBrand = {};
        this._currentOffset = 0;
      }
      if (brands.length === 0) {
        return [];
      }
      for (const brand of brands) {
        const request: Observable<BetslipsResult | BetsNotFoundError> = from(
          this._betTracerApi.getBetslipsFilteredAndSorted(filters, brand)
        );
        requestsToMake.push(request);
      }
      const betslipResult: (BetslipsResult | BetsNotFoundError)[] = await asPromise(forkJoin(requestsToMake));
      const flattenBetslips: Betslip[] = R.flatten(
        (betslipResult.filter(predicateNotFoundBetsError) as BetslipsResult[]).map(R.prop('data'))
      );
      this._nextKeysForBrand['0'] = R.mergeAll(
        (betslipResult.filter(predicateNotFoundBetsError) as BetslipsResult[]).map(R.prop('nextKeyForBrand'))
      );
      return flattenBetslips;
    };
  }

  public getMoreBetsFilteredAndSorted(): ([filters]: [
    FiltersApi,
    number | void | undefined,
    number
  ]) => Promise<LoadMoreResult> {
    return async ([filters, refreshState, offset]: [
      FiltersApi,
      number | void | undefined,
      number
    ]): Promise<LoadMoreResult> => {
      const requestsToMake: Observable<BetslipsResult | BetsNotFoundError>[] = [];
      let isRefreshing: boolean = false;
      const brands: BrandType[] = pickSelectedBrands(filters);

      if (offset !== 0 && brands.length !== 0) {
        for (const brand of brands) {
          // when offset was changed
          if (this._currentOffset !== offset) {
            isRefreshing = false;
            if (isDefined(this._nextKeysForBrand[this._currentOffset.toString()]?.[brand])) {
              const request: Observable<BetslipsResult | BetsNotFoundError> = from(
                this._betTracerApi.getBetslipsFilteredAndSorted(
                  {
                    ...filters,
                    offset: this._nextKeysForBrand[this._currentOffset][brand]
                  },
                  brand
                )
              );
              requestsToMake.push(request);
            }
          }
          // only when should refresh all
          if (this._currentRefreshState !== refreshState && this._currentOffset) {
            const nextKeys: string[] = Object.keys(this._nextKeysForBrand);
            nextKeys.pop();
            isRefreshing = true;
            for (const idKey of nextKeys) {
              if (isDefined(this._nextKeysForBrand[idKey][brand])) {
                const request: Observable<BetslipsResult | BetsNotFoundError> = from(
                  this._betTracerApi.getBetslipsFilteredAndSorted(
                    {
                      ...filters,
                      offset: this._nextKeysForBrand[idKey][brand]
                    },
                    brand
                  )
                );
                requestsToMake.push(request);
              }
            }
          }
        }
      } else {
        // when filters was changed
        this._nextKeysForBrand = {};
        this._currentOffset = 0;
        isRefreshing = true;
      }
      let loadMoreAvailable: boolean = getLoadMoreAvailable(Object.values(this._nextKeysForBrand), this._currentOffset);

      if (requestsToMake.length === 0) {
        return { bets: [], isRefreshing, loadMoreAvailable };
      }

      const betslipResult: (BetslipsResult | BetsNotFoundError)[] = await asPromise(forkJoin(requestsToMake));
      const flattenBetslips: Betslip[] = R.flatten(
        (betslipResult.filter(predicateNotFoundBetsError) as BetslipsResult[]).map(R.prop('data'))
      );
      const receivedNextKeysForBrands: Record<string, number>[] = (
        betslipResult.filter(predicateNotFoundBetsError) as BetslipsResult[]
      ).map(R.prop('nextKeyForBrand'));
      if (isRefreshing) {
        this._overwriteNextKeysForBrandsWhenRefresh(receivedNextKeysForBrands);
      } else {
        this._nextKeysForBrand[offset] = R.mergeAll(receivedNextKeysForBrands);
      }
      loadMoreAvailable = getLoadMoreAvailable(Object.values(this._nextKeysForBrand), this._currentOffset);
      this._currentRefreshState = refreshState ?? this._currentRefreshState;
      this._currentOffset = offset;
      this._currentBrands = brands;
      return { bets: flattenBetslips, isRefreshing, loadMoreAvailable };
    };
  }

  public getBettingLocations(): (brands: BrandType[]) => Promise<BettingLocation[]> {
    return async (brands: BrandType[]): Promise<BettingLocation[]> => {
      const requestsToMake: Observable<BettingLocation[] | MessageError>[] = [];
      for (const brand of brands) {
        const request: Observable<BettingLocation[] | MessageError> = from(
          this._locationsApi.getBettingLocationsByBrand({ brand, isActive: true })
        );
        requestsToMake.push(request);
      }
      const bettingLocationsResult: (BettingLocation | MessageError)[] = R.flatten(
        (await asPromise(forkJoin(requestsToMake))) as (BettingLocation[] | MessageError)[]
      );
      return R.filter(predicateNotFoundError, bettingLocationsResult) as BettingLocation[];
    };
  }

  public refreshBetStatus(): ([betId, brand]: [number, BrandType]) => Promise<void> {
    return ([betId, brand]: [number, BrandType]): Promise<void> => this._betTracerApi.refreshBetStatus(betId, brand);
  }

  private _overwriteNextKeysForBrandsWhenRefresh(receivedNextKeysForBrands: Record<string, number>[]): void {
    const reducedNextKeysByBrand: Record<string, number[]> = receivedNextKeysForBrands.reduce(
      (acc: Record<string, number[]>, nextKey: Record<string, number>) => {
        for (const key in nextKey) {
          acc[key] = acc[key] ? [...acc[key], nextKey[key]] : [nextKey[key]];
        }
        return acc;
      },
      {}
    );

    for (const brand of Object.keys(reducedNextKeysByBrand)) {
      for (let index: number = 0; index < reducedNextKeysByBrand[brand].length; index++) {
        this._nextKeysForBrand[index + 1] = {
          ...this._nextKeysForBrand[index + 1],
          [brand]: reducedNextKeysByBrand[brand][index]
        };
      }
    }
  }
}
