import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { mapAs } from '@sap/logic/shared/rxjs/operators';
import { SessionStorageKeys } from '@sap/shared/enums';
import { SessionStorageService } from '@sap/shared/services/session-storage.service';
import { NavigationItem } from '@sap/ui/navs/left-navigation-drawer/left-navigation-drawer.component';
import { allRouterPaths } from '@sap/ui/navs/left-navigation-drawer/navigation-items/all-router-paths';
import * as R from 'rambdax';
import { Observable, ReplaySubject, Subject, distinctUntilChanged, map, startWith, withLatestFrom } from 'rxjs';
import { filterNavigationEnd } from '../rxjs/operators';

export function isUrlExistInVisitedUrls(routerVisitedUrls: NavigationItem[], url: string): NavigationItem | undefined {
  return routerVisitedUrls.find(R.propEq('url', url));
}

@Injectable({
  providedIn: 'root'
})
export class RouterPlacesService {
  private _currentScope$: ReplaySubject<string | undefined> = new ReplaySubject<string | undefined>(1);
  private _currentUrl$: ReplaySubject<string> = new ReplaySubject<string>(1);
  private _currentParams$: ReplaySubject<Params> = new ReplaySubject<Params>(1);
  private _routerVisitedUrls$: ReplaySubject<NavigationItem[]> = new ReplaySubject<NavigationItem[]>(1);
  private _lastRouterUrlChanged$: Subject<void> = new Subject<void>();

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _sessionStorage: SessionStorageService,
    private _titleService: Title
  ) {}

  public init(): void {
    this._router.events
      .pipe(
        filterNavigationEnd,
        mapAs<NavigationEnd>(),
        map((event: NavigationEnd) => event.url.split('#')[0]),
        distinctUntilChanged(),
        withLatestFrom(this._activatedRoute.queryParams)
      )
      .subscribe(this._doOnRouterChanged());
  }

  private _doOnRouterChanged() {
    return ([url, { tabTitle, ...queryParams }]: [string, Params]): void => {
      this._sessionStorage.setString(SessionStorageKeys.lastRouterUrl, url);
      this._lastRouterUrlChanged$.next();
      url = url.split('?')[0];
      this._currentUrl$.next(url);
      this._currentParams$.next(queryParams);
      let routerVisitedUrls: NavigationItem[] =
        this._sessionStorage.getJson(SessionStorageKeys.routerVisitedUrls) ?? [];
      const navItem: NavigationItem | undefined = allRouterPaths.find((item: NavigationItem) =>
        item.url ? url.startsWith(item.url) : undefined
      );
      const titleFormUrl: string | undefined = tabTitle ?? this._parseIdFromUrl(url);
      const urlExist: NavigationItem | undefined = isUrlExistInVisitedUrls(routerVisitedUrls, url);
      if (!urlExist && navItem) {
        routerVisitedUrls.push({
          ...navItem,
          title: titleFormUrl ?? navItem.title,
          url,
          queryParams: queryParams ?? null // queryParams used to remember filters on views
        });
      }
      if (urlExist && (!R.equals(urlExist.queryParams, queryParams) || navItem?.preventMultipleTab)) {
        routerVisitedUrls = routerVisitedUrls.map((visitedUrl: NavigationItem) =>
          R.equals(urlExist, visitedUrl) ? { ...visitedUrl, queryParams } : visitedUrl
        );
      }
      this._currentScope$.next(navItem?.scope);
      this._setTitle(urlExist ? urlExist.title : navItem ? navItem.title : titleFormUrl);
      this.setNewRouterVisitedUrls(routerVisitedUrls);
    };
  }

  public setNewRouterVisitedUrls(routerVisitedUrl: NavigationItem[]): void {
    this._routerVisitedUrls$.next(routerVisitedUrl);
    this._sessionStorage.setJson(SessionStorageKeys.routerVisitedUrls, routerVisitedUrl);
  }

  public getRouterVisitedUrls(): Observable<NavigationItem[]> {
    return this._routerVisitedUrls$.asObservable();
  }

  public getCurrentUrl(): Observable<string> {
    return this._currentUrl$.asObservable();
  }

  public getCurrentScope(): Observable<string | undefined> {
    return this._currentScope$.asObservable();
  }

  public getCurrentParams(): Observable<Params> {
    return this._currentParams$;
  }

  public getLastRouterUrl(): Observable<string | undefined> {
    return this._lastRouterUrlChanged$.pipe(
      startWith(undefined),
      map(() => this._sessionStorage.getString(SessionStorageKeys.lastRouterUrl))
    );
  }

  public setNewActiveRouterUrl(routerVisitedUrl: NavigationItem | undefined): void {
    if (!routerVisitedUrl) {
      this._router.navigateByUrl('/');
      return;
    }
    this._router.navigate([routerVisitedUrl.url], {
      queryParams: routerVisitedUrl.queryParams ?? null
    });
  }

  private _parseIdFromUrl(url: string): string | undefined {
    const splittedLinkHref: string[] = url.split('/');
    const id: string | undefined = splittedLinkHref[splittedLinkHref.length - 1] || undefined;
    return id && id.split(':')[1] ? id : undefined;
  }

  private _setTitle(newTitle: string = 'Sportsbook Admin Panel'): void {
    this._titleService.setTitle(newTitle);
  }
}
