import { Pipe, PipeTransform } from '@angular/core';
import { NavigationItem, NavigationItemRoute } from '../left-navigation-drawer.component';
import { betTracerMain } from '../navigation-items/bet-tracer';

@Pipe({ name: 'navRouterLink', standalone: true })
export class NavRouterLinkPipe implements PipeTransform {
  public transform(navItem: NavigationItem, indexTab: number): NavigationItemRoute {
    if (navItem.url?.startsWith(betTracerMain.url!) && indexTab) {
      return { commands: [navItem.url, indexTab.toString()] };
    }
    return navItem.queryParams
      ? { commands: navItem.url ? [navItem.url] : undefined, queryParams: navItem.queryParams }
      : { commands: navItem.url ? [navItem.url] : undefined };
  }
}
