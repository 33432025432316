import { Directive } from '@angular/core';
import { QuickOptionType } from '@sap/ui/shared/components/date-range-picker/types';
import { NgContext, NgContextVars, TemplateVarDirective } from '@sap/ui/shared/directives/template-var.directive';

export type NgContextVarsTyped = {
  selectedQuickOption: QuickOptionType | undefined;
  minutesFrom: number | undefined;
  minutesTo: number | undefined;
  hoursFrom: number | undefined;
  hoursTo: number | undefined;
};

@Directive({
  standalone: true,
  selector: '[templateVarTyped]'
})
export class TemplateVarTypedDirective extends TemplateVarDirective<NgContextVarsTyped> {
  protected override _initialContext: NgContextVarsTyped = {
    selectedQuickOption: undefined,
    minutesFrom: undefined,
    minutesTo: undefined,
    hoursFrom: undefined,
    hoursTo: undefined
  };

  public static override ngTemplateContextGuard<NgContextVarsTyped extends NgContextVars>(
    _dir: TemplateVarDirective<NgContextVarsTyped>,
    ctx: unknown
  ): ctx is NgContext<NgContextVarsTyped> {
    return true;
  }
}
