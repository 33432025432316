import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { isDefined } from '@sap/logic/shared/utils/pure-utils';
import { Unsubscribable } from '@sap/shared/classes/unsubscribable';
import * as R from 'rambdax';
import { Subject, debounceTime } from 'rxjs';
import { SelectAppearance } from './shared/enum';
import { searchAndSortByRankMatching } from './shared/impure-utils';

@Component({
  selector: 'sap-search-select',
  template: `
    <div
      fxThemeCss
      class="form-multi-select form-multi-select-searchable form-multi-select-selection-tags"
      [class.form-select_rounded]="appearance === SelectAppearance.rounded"
      [class.form-select_button]="appearance === SelectAppearance.button"
      [class.form-select_table]="appearance === SelectAppearance.table"
      [class.form-select_selected]="selectedOption !== undefined"
      [class.has-trash-icon]="showTrash"
      [class.show]="show"
      (click)="doToggle()"
      (clickOutside)="doHide()"
      [triggerWhen]="show"
    >
      <div>
        <span *ngIf="selectedOption !== undefined" class="form-multi-select-selection">
          <span class="form-multi-select-tag" [title]="selectedOption![displayProperty]">
            {{ selectedOption![displayProperty] }}
          </span>
        </span>
        <input
          *ngIf="
            appearance !== SelectAppearance.button &&
            appearance !== SelectAppearance.table &&
            (selectedOption === undefined || (showInputSearch && show))
          "
          #inputRef
          class="form-multi-select-search"
          [placeholder]="show ? 'Search...' : inputPlaceholder"
          (keyupTargetValue)="filterBySubject$.next($event)"
          (click)="$event.stopImmediatePropagation(); doShow()"
        />
        <sapds-icon
          *ngIf="appearance === SelectAppearance.rounded"
          iconName="trash"
          [class.u-disabled]="disableTrash"
          (click)="$event.stopImmediatePropagation(); selectReset.next(); selectedOptionById = undefined; doHide()"
        />
      </div>
      <ng-container *ngIf="showOptions">
        <div
          *ngIf="filteredOptions | lazyOptions: filterBy : 500 as lazyFilteredOptions"
          renderSelectDropdownInViewPort
          class="form-multi-select-dropdown"
        >
          <div class="form-multi-select-options styled-scrollbar">
            <div
              *ngIf="appearance === SelectAppearance.table || appearance === SelectAppearance.button"
              class="form-multi-select-option form-multi-select-option_search "
            >
              <input
                #inputRef
                class="form-multi-select-search"
                [placeholder]="show ? 'Search...' : inputPlaceholder"
                (keyupTargetValue)="filterBySubject$.next($event)"
                (click)="$event.stopImmediatePropagation(); doShow()"
              />
            </div>
            <div
              *ngIf="emptyOptionName"
              class="form-multi-select-option"
              [class.form-multi-selected]="selectedOption === undefined"
              (click)="$event.stopImmediatePropagation(); toggleOption(undefined)"
            >
              {{ emptyOptionName }}
            </div>
            <div
              *ngFor="let option of lazyFilteredOptions"
              class="form-multi-select-option"
              [title]="option | toTitle: displayProperty : valueProperty"
              [class.form-multi-selected]="option[valueProperty] === selectedOption?.[valueProperty]"
              (click)="toggleOption(option); $event.stopImmediatePropagation()"
            >
              {{ option[displayProperty] }}
            </div>

            <ng-container *ngIf="!lazyFilteredOptions.length">
              <div class="form-multi-select-option u-not-clickable">No results</div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
    <sapds-icon
      *ngIf="appearance !== SelectAppearance.rounded && showTrash"
      iconName="trash"
      [class.u-disabled]="disableTrash"
      (click)="reset(); selectReset.next()"
    />
  `,
  styleUrls: ['./shared/select.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class SearchSelectComponent<T> extends Unsubscribable implements OnInit {
  @ViewChild('inputRef') input?: ElementRef;
  @ViewChild('dropdownRef') dropdown: ElementRef | undefined;

  public SelectAppearance: typeof SelectAppearance = SelectAppearance;

  @Input() set options(values: T[]) {
    this._options = values;
    if (this.selectedOption === undefined && this.selectedOptionById) {
      this._setSelectedOptionById();
    }
  }
  public get options(): T[] {
    return this._options;
  }
  @Input() emptyOptionName: string | undefined;
  @Input() showTrash: boolean = false;
  @Input() disableTrash: boolean = false;
  @Input() valueProperty: string = 'id';
  @Input() displayProperty: string = 'name';
  @Input() minLettersToSearch: number = 0;
  @Input() searchDebounceTime: number = 0;
  @Input() inputName: string = '';
  @Input() showMinCharsInfo: boolean = false;
  @Input() appearance: keyof typeof SelectAppearance = SelectAppearance.default;
  @Input() enableInitialEmitting: boolean = true;
  @Input() set initialFilterBy(value: string | undefined) {
    if (isDefined(value)) {
      this._initialFilterBy = value;
    }
  }
  @Input() set selectedOptionById(value: number | string | undefined) {
    if (!isDefined(value)) {
      this.selectedOption = undefined;
      this.showInputSearch = true;
      this._emittingCounter += 1;
    }
    this._selectedOptionById = value;

    if (this.selectedOption === undefined || this.selectedOption![this.valueProperty] !== this.selectedOptionById) {
      this._setSelectedOptionById();
    }
  }
  public get selectedOptionById(): number | string | undefined {
    return this._selectedOptionById;
  }

  @Output() didChanged: EventEmitter<T | undefined> = new EventEmitter<T | undefined>();
  @Output() selectReset: EventEmitter<void> = new EventEmitter<void>();

  public filterBySubject$: Subject<string> = new Subject<string>();

  public show: boolean = false;
  public filterBy: string | undefined;
  public selectedOption: T | undefined;
  public showOptions: boolean = false;
  public showInputSearch: boolean = true;

  private _initialFilterBy?: string;
  private _prevFilterBy: string | undefined;
  private _emittingCounter: number = 0;
  private _options: T[] = [];
  private _selectedOptionById: number | string | undefined;

  constructor(private _cdRef: ChangeDetectorRef) {
    super();
    this._cdRef.detach();
  }

  public get inputPlaceholder(): string {
    if (this.selectedOption !== undefined && !this.showInputSearch) {
      return this.selectedOption![this.displayProperty];
    }
    if (this.showMinCharsInfo === true) {
      return `${this.inputName} (min. ${this.minLettersToSearch} chars)`;
    }
    return this.inputName;
  }

  public get filteredOptions(): T[] {
    let toReturn: T[] = this.options;
    if (this.filterBy && this.filterBy !== this._prevFilterBy && this.filterBy !== '') {
      const searchPhrase: string = this.filterBy.toLowerCase();
      this._prevFilterBy = this.filterBy!;
      toReturn = searchAndSortByRankMatching(toReturn, this.displayProperty, searchPhrase);
    }
    return toReturn as T[];
  }

  public ngOnInit(): void {
    this._cdRef.detectChanges();
    this._listenSearchInput();
  }

  public doShow(): void {
    if (this.show) {
      return;
    }
    if (this.input?.nativeElement.value.length >= this.minLettersToSearch || this.minLettersToSearch === 0) {
      this.showOptions = true;
    }
    if (isDefined(this._initialFilterBy)) {
      this.doFilterBy(this._initialFilterBy);
    }

    this.showInputSearch = true;
    this.show = true;
    this._cdRef.detectChanges();
  }

  public doHide(): void {
    this._clearInputValueAndFilter();
    this.show = false;
    this.showInputSearch = false;
    this.showOptions = false;
    this._cdRef.detectChanges();
  }

  public doToggle(): void {
    if (this.show) {
      this.doHide();
    } else {
      this.doShow();
    }
  }

  public doFilterBy(value: string): void {
    if (value.length >= this.minLettersToSearch || this.minLettersToSearch === 0) {
      this.filterBy = value;
      this.showOptions = true;
      if (this.input && this.input.nativeElement.value === '') {
        this.input.nativeElement.value = value;
      }
      return;
    }
    this.showOptions = false;
  }

  public toggleOption(optionToToggle: T | undefined, isRestoring: boolean = false): void {
    this.showOptions = !this.showOptions;

    this.showInputSearch = false;
    this.showOptions = false;
    this.doHide();
    if (!this.enableInitialEmitting && (this._emittingCounter === 0 || isRestoring)) {
      this.selectedOption = optionToToggle;
      this._emittingCounter += 1;
    } else {
      this.selectedOption = optionToToggle;
      this._emitUpdate();
    }

    this._cdRef.detectChanges();
  }

  public reset(shouldRestore?: boolean): void {
    this.selectedOption = undefined;
    if (shouldRestore) {
      this._setSelectedOptionById(true);
      return;
    }
    if (this.input) {
      this.input.nativeElement.value = '';
    }
    this.showInputSearch = true;
    this._emitUpdate();
    this._cdRef.detectChanges();
  }

  private _emitUpdate(): void {
    this.didChanged.emit(this.selectedOption);
  }

  private _listenSearchInput(): void {
    this.filterBySubject$.pipe(debounceTime(this.searchDebounceTime)).subscribe((searchPhrase: string) => {
      this.doFilterBy(searchPhrase);
      this._cdRef.detectChanges();
    });
  }

  private _clearInputValueAndFilter(): void {
    if (this.input) {
      this.input.nativeElement.value = '';
      this.filterBy = undefined;
      this._prevFilterBy = undefined;
    }
  }

  private _setSelectedOptionById(isRestoring: boolean = false): void {
    if (this.selectedOptionById && this.selectedOption?.[this.valueProperty] !== this.selectedOptionById) {
      const selectedOption: T | undefined = R.find(
        (option: T) => option[this.valueProperty] == this.selectedOptionById,
        this.options
      );
      if (selectedOption) {
        this.toggleOption(selectedOption as T, isRestoring);
      }
    } else {
      this.selectedOption = undefined;
      this._cdRef.detectChanges();
    }
  }
}
