<ng-container *templateVarTyped="let vars = vars; let update = updateVar">
  <div class="datepicker-bar" *ngIf="!vars.yaerView && !vars.monthView">
    <button type="button" class="datepicker-btn-switch" (clickDetached)="switchToPrevMonth()">
      <i class="cil-arrow-left"></i>
    </button>
    <span class="datepicker-bar-title">
      <span (clickDetached)="update('monthView', true)">{{ monthDate | dateObjFormat: 'LLLL' }}</span>
      <span (clickDetached)="update('yaerView', true)">{{ monthDate | dateObjFormat: 'yyyy' }}</span>
    </span>
    <button type="button" class="datepicker-btn-switch" (clickDetached)="switchToNextMonth()">
      <i class="cil-arrow-right"></i>
    </button>
  </div>
  <div class="datepicker-content">
    <!--      days view-->
    <ng-container *ngIf="!vars.yaerView && !vars.monthView">
      <div class="datepicker-header datepicker-grid-row">
        <span class="datepicker-header-entry datepicker-grid-entry">mo</span>
        <span class="datepicker-header-entry datepicker-grid-entry">tu</span>
        <span class="datepicker-header-entry datepicker-grid-entry">we</span>
        <span class="datepicker-header-entry datepicker-grid-entry">th</span>
        <span class="datepicker-header-entry datepicker-grid-entry">fr</span>
        <span class="datepicker-header-entry datepicker-grid-entry">sa</span>
        <span class="datepicker-header-entry datepicker-grid-entry">su</span>
      </div>
      <div class="datepicker-days datepicker-grid">
        <ng-container *ngFor="let dayRow of monthMatrix; trackBy: trackByIndex">
          <div class="datepicker-days-row datepicker-grid-row">
            <ng-container *ngFor="let day of dayRow; trackBy: trackByDay">
              <span
                class="datepicker-days-entry datepicker-grid-entry"
                [class.disabled]="!(monthDate | isSameMonth: day)"
                [class.is-between]="day | isBetweenDates: dateFrom : dateTo"
                [class.is-active]="(dateFrom | isSameDay: day) || (dateTo | isSameDay: day)"
                [class.is-active-from]="dateFrom | isSameDay: day"
                [class.is-active-to]="dateTo | isSameDay: day"
                (clickDetached)="selectDay$.next(day)"
                >{{ day | dateObjFormat: 'dd' }}</span
              >
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <!--      yaer view-->
    <ng-container *ngIf="vars.yaerView">
      <div class="datepicker-content">
        <div class="datepicker-years datepicker-grid">
          <ng-container *ngFor="let yearRow of yearMatrix">
            <div class="datepicker-years-row datepicker-grid-row">
              <ng-container *ngFor="let year of yearRow">
                <span
                  class="datepicker-years-entry datepicker-grid-entry"
                  [class.is-active]="monthDate | isSameYear: year"
                  (clickDetached)="monthDate = year; update('yaerView', false)"
                  >{{ year | dateObjFormat: 'yyyy' }}</span
                >
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <!--      monthView view-->
    <ng-container *ngIf="vars.monthView">
      <div class="datepicker-content">
        <div class="datepicker-years datepicker-grid">
          <ng-container *ngFor="let monthRow of monthsInYearMatrix">
            <div class="datepicker-years-row datepicker-grid-row">
              <ng-container *ngFor="let month of monthRow">
                <span
                  class="datepicker-months-entry datepicker-grid-entry"
                  [class.is-active]="monthDate | isSameMonth: month"
                  (clickDetached)="monthDate = month; update('monthView', false)"
                  >{{ month | dateObjFormat: 'LLLL' }}</span
                >
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
