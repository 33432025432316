import { ChangeDetectorRef, Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { asapScheduler } from 'rxjs';

@Directive({
  selector: '[clickDetached]',
  standalone: true
})
export class ClickDetachedDirective {
  @Output() clickDetached: EventEmitter<void> = new EventEmitter<void>();

  constructor(private _cdRef: ChangeDetectorRef) {}

  @HostListener('click')
  public click(): void {
    this.clickDetached.emit(undefined);
    asapScheduler.schedule(() => this._cdRef.detectChanges());
  }
}
