import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Params, Router, RouterModule } from '@angular/router';
import { ButtonComponent } from '@sap-design-system/components/button/button.component';
import { IconName } from '@sap-design-system/components/icon/icon-types';
import { IconComponent } from '@sap-design-system/components/icon/icon.component';
import { SharedBrand } from '@sap/logic/shared/brand/shared-brand';
import { ModuleScopes } from '@sap/logic/shared/types/permissions';
import { Unsubscribable } from '@sap/shared/classes/unsubscribable';
import { BrandType, SessionStorageKeys } from '@sap/shared/enums';
import { FxThemeCssModule } from '@sap/ui/shared/fx-theme-css/fx-theme-css.module';
import { SetAndDetect, setAndDetect } from '@sap/ui/shared/helpers/set-and-detect';
import { NgForTracker } from '@sap/ui/shared/ng-for-tracker';
import { isUrlExistInVisitedUrls } from '@sap/ui/shared/services/router-places.service';
import { Subject, debounceTime } from 'rxjs';
import { countBetTracerTabs } from './count-bet-tracer-tabs-helper';
import { navigationList } from './navigation-constant';
import { BrandScopeCheckPipe } from './pipes/brand-scope-check.pipe';
import { IsActiveNavItemPipe } from './pipes/is-active-navigation-item.pipe';
import { NavRouterLinkPipe } from './pipes/nav-router-link.pipe';
import { PermissionCheckPipe } from './pipes/permission-check.pipe';
import { ToUpdatedExpandedMenuPipe } from './pipes/to-updated-expanded-menus.pipe';
import { TemplateVarTypedDirective } from './template-var-typed.directive';

export type NavigationItemRoute = {
  commands: string[] | undefined;
  queryParams?: Params | null;
};

export type NavigationItem = {
  title: string;
  url: string | undefined;
  icon: IconName;
  scope: string;
  queryParams?: Params | null;
  children?: NavigationItem[];
  brandsScope?: BrandType[];
  sessionStorageKeyToRemoveOnClose?: SessionStorageKeys;
  preventMultipleTab?: boolean;
};

@Component({
  standalone: true,
  selector: 'sap-left-navigation-drawer',
  templateUrl: './left-navigation-drawer.component.html',
  styleUrls: ['./left-navigation-drawer.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    FxThemeCssModule,
    ButtonComponent,
    TemplateVarTypedDirective,
    IconComponent,
    PermissionCheckPipe,
    BrandScopeCheckPipe,
    IsActiveNavItemPipe,
    ToUpdatedExpandedMenuPipe,
    NavRouterLinkPipe
  ],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class LeftNavigationDrawerComponent extends Unsubscribable implements OnInit {
  public ngForTracker: NgForTracker = new NgForTracker();
  private _set: SetAndDetect<this>;
  private _routerVisitedUrls: NavigationItem[] = [];
  private _currentUrl: string | undefined;
  private _isExpanded: boolean = false;
  private _expandedSubMenus: Record<number, boolean> = {};
  private _userPermissions: ModuleScopes<boolean> | undefined;
  private _brandsFromScopes: BrandType[] = [];

  public navigation: NavigationItem[] = navigationList;
  public initialBrand: BrandType = BrandType.default;

  private _detectChangesSignal$: Subject<void> = new Subject<void>();

  @Input() set brandsFromScopes(value: BrandType[] | undefined) {
    this._brandsFromScopes = value ?? [];
    this._detectChangesSignal$.next(undefined);
  }
  public get brandsFromScopes(): BrandType[] {
    return this._brandsFromScopes;
  }

  @Input() set userPermissions(value: ModuleScopes<boolean> | undefined) {
    this._userPermissions = value;
    this._detectChangesSignal$.next(undefined);
  }
  public get userPermissions(): ModuleScopes<boolean> | undefined {
    return this._userPermissions;
  }

  @Input() set isExpanded(value: boolean) {
    this._isExpanded = value;
    this._detectChangesSignal$.next(undefined);
  }
  public get isExpanded(): boolean {
    return this._isExpanded;
  }

  @Input() set expandedSubMenus(value: Record<number, boolean>) {
    this._expandedSubMenus = value;
    this._detectChangesSignal$.next(undefined);
  }
  public get expandedSubMenus(): Record<number, boolean> {
    return this._expandedSubMenus;
  }

  @Input() set currentUrl(value: string | undefined) {
    this._currentUrl = value;
    this._detectChangesSignal$.next(undefined);
  }
  public get currentUrl(): string | undefined {
    return this._currentUrl;
  }
  @Input() set routerVisitedUrls(value: NavigationItem[]) {
    this._routerVisitedUrls = value;
    this.nextBetTracerTab = countBetTracerTabs(value);
    this._detectChangesSignal$.next(undefined);
  }
  public nextBetTracerTab: number = 0;

  @Output() doToggleMenu: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() doExpandSubMenu: EventEmitter<Record<number, boolean>> = new EventEmitter<Record<number, boolean>>();
  @Output() doLogout: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private _router: Router,
    private _cdRef: ChangeDetectorRef,
    private _sharedBrand: SharedBrand
  ) {
    super();
    this._set = setAndDetect(this, this._cdRef);
  }

  public ngOnInit(): void {
    this._sub = this._sharedBrand.getInitialBrand().subscribe(this._set('initialBrand'));
    this._detectChangesSignal$.pipe(debounceTime(50)).subscribe(() => this._cdRef.detectChanges());
  }

  public openTab(navItemRoute: NavigationItemRoute): void {
    if (navItemRoute.commands) {
      const urlExist: NavigationItem | undefined = isUrlExistInVisitedUrls(
        this._routerVisitedUrls,
        navItemRoute.commands.join('/')
      );
      if (urlExist?.preventMultipleTab) {
        this._router.navigate([urlExist.url], {
          queryParams: urlExist.queryParams ?? null
        });
      } else {
        this._router.navigate(navItemRoute.commands, {
          queryParams: navItemRoute.queryParams ?? null
        });
      }
    }
  }
}
