<sap-bet-tracer-top-filters
  [noAccess]="noAccessByBrands"
  [isLoading]="isLoading"
  [refreshState]="refreshState"
  [calculatedPayouts]="calculatedPayouts"
  [calculatedTurnover]="calculatedTurnover"
  [bettingLocations]="bettingLocations"
  [isLoadingBettingLocations]="isLoadingBettingLocations"
  (doRefresh)="doRefresh$.next($event)"
  (doForceRefresh)="doForceRefresh$.next()"
  (doSelectOddFormat)="doSelectOddFormat$.next($event)"
  (updateDisplayedColumns)="displayedColumns$.next($event)"
  (resetColumns)="doResetColumns$.next($event)"
  (resetOffset)="doDisplayMoreBets$.next(0)"
  (isFiltersSticky)="isFiltersSticky$.next($event)"
></sap-bet-tracer-top-filters>
<sap-bet-tracer-main-data-view
  [isFiltersSticky]="isFiltersSticky"
  [noAccess]="noAccessByBrands"
  [expandedBetRowsIds]="expandedBetRowsIds"
  [selectedOddsFormat]="selectedOddsFormat"
  [indexedMatchComments]="indexedMatchComments"
  [indexedBetComments]="indexedBetComments"
  [isLoading]="isLoading"
  [isLoadingMoreBets]="isLoadingMoreBets"
  [isMoreBetsToDisplay]="isMoreBetsToDisplay"
  [betsNotFound]="betsNotFound"
  [bets]="bets"
  [displayedColumns]="displayedColumns"
  [resetColumns]="doResetColumns$"
  [currentOffset]="currentOffset"
  (displayMoreBets)="doDisplayMoreBets$.next($event)"
  (doSaveExpandedBetRowsIds)="doSaveExpandedBetRowsIds$.next($event)"
  (doSubmitComment)="doSubmitComment$.next($event)"
  (doUpdateCommentForm)="doUpdateCommentForm$.next($event)"
  (doSelectSetIdForComments)="doSelectSetIdForComments$.next($event)"
  (doRefreshStatus)="doRefreshStatus$.next($event)"
></sap-bet-tracer-main-data-view>
