import { BrandType } from '@sap/shared/enums';
import { WithRawJson } from '../interfaces';
import { Bet, BetCurrency, BetType, BetWithSelections } from './bet';
import { BonusInfo } from './bonus-info';

export enum BetslipStatus {
  open = 'open',
  closed = 'closed',
  return = 'return',
  won = 'won'
}

export class Betslip implements WithRawJson {
  constructor(
    public readonly id: number,
    public readonly date: Date | undefined,
    public readonly paid: boolean,
    public readonly brand: BrandType,
    public readonly type: BetType,
    public readonly device: string,
    public readonly customerId: number,
    public readonly customer: string,
    public readonly classification: string,
    public readonly location: string,
    public readonly risk: number,
    public readonly currency: BetCurrency,
    public readonly payout: number,
    public readonly payoutCurrency: number,
    public readonly stake: number,
    public readonly stakeCurrency: number,
    public readonly status: BetslipStatus,
    public readonly statusUpdated: Date | undefined,
    public readonly isClosed: boolean,
    public readonly bonusInfo: BonusInfo | undefined,
    public readonly bets: BetWithSelections[][],
    public readonly rawJson: Record<string, any>
  ) {}

  public static fromJson({ betslip, bets }: Record<string, any>, brand: BrandType): Betslip {
    return new Betslip(
      betslip['betslip_id'],
      new Date(betslip['created']),
      Boolean(betslip['status'] !== BetslipStatus.open),
      brand,
      betslip['betslip_type'] ? betslip['betslip_type'].toLocaleLowerCase() : undefined,
      betslip['device'],
      betslip['client_id'],
      betslip['client_login'],
      betslip['classification'],
      betslip['location'],
      betslip['coupon_risk'],
      betslip['currency'],
      betslip['payout'],
      betslip['payout_currency'],
      betslip['stake'],
      betslip['stake_currency'],
      betslip['status'],
      betslip['status_updated'] ? new Date(betslip['status_updated']) : undefined,
      betslip['is_closed'],
      betslip['bonus_info'] ? BonusInfo.fromJson(betslip['bonus_info']) : undefined,
      Bet.fromJsonArray(bets),
      betslip
    );
  }

  public static fromJsonArray(jsons: Record<string, any>[], brand: BrandType): Betslip[] {
    return jsons.map((json: Record<string, any>) => Betslip.fromJson(json, brand));
  }
}
