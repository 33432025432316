import { Pipe, PipeTransform } from '@angular/core';
import { getMinutes } from 'date-fns';

@Pipe({
  standalone: true,
  name: 'getMinutes'
})
export class GetMinutesPipe implements PipeTransform {
  public transform(date: Date | undefined): number {
    if (!date) {
      return 0;
    }
    return getMinutes(date);
  }
}
