import { NavigationItem } from '../left-navigation-drawer.component';

export const riskManagementMain: NavigationItem = {
  title: 'Risk Management',
  url: undefined,
  icon: 'balance-scale',
  scope: 'riskManagement.main'
};

export const riskManagementMarketClasses: NavigationItem = {
  title: 'Market Classes',
  url: '/market-classes',
  icon: 'layers',
  scope: 'riskManagement.marketClassesSetup',
  queryParams: null,
  preventMultipleTab: true
};

export const riskManagementCreateProfile: NavigationItem = {
  title: 'Create Profile',
  url: '/create-profile',
  icon: 'contact',
  scope: 'riskManagement.profileCreate'
};

export const riskManagementEditProfile: NavigationItem = {
  title: 'Edit Profile',
  url: '/edit-profile',
  icon: 'contact',
  scope: 'riskManagement.profileEdit'
};

export const riskManagementResultingManager: NavigationItem = {
  title: 'Resulting Manager',
  url: '/resulting-manager',
  icon: 'wallet',
  scope: 'riskManagement.resultingManager'
};

export const riskManagementFailedTransactions: NavigationItem = {
  title: 'Failed Transactions',
  url: '/failed-transactions',
  icon: 'credit-card',
  scope: 'riskManagement.failedTransactions',
  preventMultipleTab: true
};

export const riskManagementMaintenanceControl: NavigationItem = {
  title: 'Maintenance Control',
  url: '/maintenance',
  icon: 'applications-settings',
  scope: 'riskManagement.maintenance',
  preventMultipleTab: true
};

export const allRiskManagementChildren: NavigationItem[] = [
  riskManagementMarketClasses,
  riskManagementCreateProfile,
  riskManagementEditProfile,
  riskManagementResultingManager,
  riskManagementFailedTransactions,
  riskManagementMaintenanceControl
];
