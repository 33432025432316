import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LocalStorageKeys } from '@sap/shared/enums';
import { LocalStorageService } from '@sap/shared/services/local-storage.service';
import * as R from 'rambdax';
import { BetTraceColumNames } from './enum';

type ColumnIdentify = { value: BetTraceColumNames; label: string };

const staticBetTracerColumns: ColumnIdentify[] = [{ value: BetTraceColumNames.time, label: 'time' }];

const manageableBetTracerColumns: ColumnIdentify[] = [
  { value: BetTraceColumNames.device, label: 'Device' },
  { value: BetTraceColumNames.clientId, label: 'Client Id' },
  { value: BetTraceColumNames.customer, label: 'Customer' },
  { value: BetTraceColumNames.brand, label: 'Brand' },
  { value: BetTraceColumNames.class, label: 'Class' },
  { value: BetTraceColumNames.event, label: 'Event' },
  { value: BetTraceColumNames.starting, label: 'Starting' },
  { value: BetTraceColumNames.sportsGroups, label: 'Sport / Country / League' },
  { value: BetTraceColumNames.couponId, label: 'Coupon Id' },
  { value: BetTraceColumNames.eventIdAndMarketId, label: 'Event ID / Market ID' },
  { value: BetTraceColumNames.selection, label: 'Selection' },
  { value: BetTraceColumNames.odds, label: 'Odds' },
  { value: BetTraceColumNames.stake, label: 'Stake' },
  { value: BetTraceColumNames.risk, label: 'Risk' },
  { value: BetTraceColumNames.payout, label: 'Payout' },
  { value: BetTraceColumNames.type, label: 'Type' },
  { value: BetTraceColumNames.paid, label: 'Paid' },
  { value: BetTraceColumNames.bonus, label: 'Bonus' },
  { value: BetTraceColumNames.betStatus, label: 'Bet Status' },
  { value: BetTraceColumNames.selectionStatus, label: 'Selection Status' },
  { value: BetTraceColumNames.statusUpdate, label: 'Status Update' },
  { value: BetTraceColumNames.forceStatus, label: 'Force Status' },
  { value: BetTraceColumNames.actions, label: 'Actions' }
];

export const staticBetTracerColumnsIndexes: BetTraceColumNames[] = staticBetTracerColumns.map(
  (columnIdentify: ColumnIdentify) => columnIdentify.value
);
export const manageableBetTracerColumnsIndexes: BetTraceColumNames[] = manageableBetTracerColumns.map(
  (columnIdentify: ColumnIdentify) => columnIdentify.value
);

@Component({
  selector: 'sap-bet-tracer-table-tool',
  templateUrl: './bet-tracer-table-tool.component.html',
  styleUrls: ['./bet-tracer-table-tool.component.scss']
})
export class BetTracerTableToolComponent implements OnInit {
  public manageableDefaultColumnOptions: ColumnIdentify[] = manageableBetTracerColumns;
  public selectedOptions: ColumnIdentify[] = [];

  private _displayedColumns: BetTraceColumNames[] = [];
  private _defaultColumnList: BetTraceColumNames[] = [
    ...staticBetTracerColumnsIndexes,
    ...manageableBetTracerColumnsIndexes
  ];

  @Output()
  public updateDisplayedColumns: EventEmitter<BetTraceColumNames[]> = new EventEmitter<BetTraceColumNames[]>();
  @Output()
  public resetColumns: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private _localStorage: LocalStorageService,
    private _cdRef: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._setDisplayedColumns();
  }

  private _setDisplayedColumns(): void {
    const displayedColumnsFromLS: BetTraceColumNames[] | undefined = this._localStorage.getJson(
      LocalStorageKeys.displayColumnsBetTracer
    );

    if (displayedColumnsFromLS) {
      const filteredColumns: BetTraceColumNames[] = R.filter(
        (column: BetTraceColumNames) => this._defaultColumnList.includes(column),
        displayedColumnsFromLS
      );

      if (filteredColumns.length !== displayedColumnsFromLS.length) {
        this._localStorage.setJson(LocalStorageKeys.displayColumnsBetTracer, filteredColumns);
      }

      this._displayedColumns = filteredColumns;
    } else {
      this._displayedColumns = this._defaultColumnList;
    }

    this._updateSelectedOptions(this._displayedColumns);

    if (this._displayedColumns.length === 0) {
      this._displayedColumns = staticBetTracerColumnsIndexes;
    }
    this.updateDisplayedColumns.emit(this._displayedColumns);
    this._cdRef.detectChanges();
  }

  public handleColumnsChange(columns: BetTraceColumNames[]): void {
    this.updateDisplayedColumns.emit([...staticBetTracerColumnsIndexes, ...columns]);
    this._localStorage.setJson(LocalStorageKeys.displayColumnsBetTracer, columns);
  }

  public handleResetColumns(): void {
    this._displayedColumns = this._defaultColumnList;
    this._localStorage.setJson(LocalStorageKeys.displayColumnsBetTracer, this._displayedColumns);
    this._updateSelectedOptions(this._displayedColumns);
    this.updateDisplayedColumns.emit(this._displayedColumns);
    this.resetColumns.emit(undefined);
  }

  private _updateSelectedOptions(displayedColumns: BetTraceColumNames[]): void {
    this.selectedOptions = R.piped(
      displayedColumns,
      R.map(
        (index: BetTraceColumNames) =>
          R.find<ColumnIdentify>(R.propEq('value', index), this.manageableDefaultColumnOptions)!
      ),
      R.filter(Boolean)
    ) as ColumnIdentify[];
  }
}
