import { Pipe, PipeTransform } from '@angular/core';
import { MultiSelectOption } from '@sap/logic/rate-boost/bloc/rate-boost/types';
import { BetWithSelections } from '@sap/logic/shared/models/bet';

export const forceStatusesOptions: MultiSelectOption[] = [
  { id: 'invalid', name: 'invalid' },
  { id: 'force_open', name: 'force open' },
  { id: 'force_won', name: 'force won' },
  { id: 'force_lost', name: 'force lost' },
  { id: 'force_half_lost', name: 'force half lost' },
  { id: 'force_half_won', name: 'force half won' },
  { id: 'force_return', name: 'force return' },
  { id: 'unlock', name: 'unlock' }
];

export const toUnlockStatuses: string[] = ['void', 'invalid', 'return '];

@Pipe({ name: 'betForceStatusesOptions' })
export class BetForceStatusesOptionsPipe implements PipeTransform {
  public transform(bet: BetWithSelections): MultiSelectOption[] {
    if (bet.earlyCashoutOdds) {
      if (toUnlockStatuses.includes(bet.status)) {
        return [{ id: 'unlock', name: 'unlock' }];
      }
      return [{ id: 'invalid', name: 'invalid' }];
    }
    const toReturn: MultiSelectOption[] = [];
    return toReturn;
  }
}
