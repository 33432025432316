import { NavigationItem } from '../left-navigation-drawer.component';

// paths that not included in menu but available in router

export const otherEventView: NavigationItem = {
  title: 'Event View',
  url: '/event-view',
  icon: 'newspaper',
  scope: 'eventView.main'
};

export const otherUsersResetPassword: NavigationItem = {
  title: 'Reset Password',
  url: '/reset-password',
  icon: 'recycle',
  scope: 'users.resetPassword'
};

export const otherUsersCreateRole: NavigationItem = {
  title: 'Create Role',
  url: '/permissions/create-role',
  icon: 'plus',
  scope: 'users.roles'
};

export const otherUsersEditRole: NavigationItem = {
  title: 'Edit Role',
  url: '/permissions/edit-role',
  icon: 'pencil',
  scope: 'users.roles'
};

export const otherUsersViewRole: NavigationItem = {
  title: 'View Role',
  url: '/permissions/view-role',
  icon: 'monitor',
  scope: 'users.roles'
};

export const otherUsersEditUser: NavigationItem = {
  title: 'Edit User',
  url: '/edit-user',
  icon: 'monitor',
  scope: 'users.edit'
};

export const otherCustomerDetails: NavigationItem = {
  title: 'Customer Details',
  url: '/customer-details',
  icon: 'search',
  scope: 'customers.main'
};
