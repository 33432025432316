import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, OnDestroy, Output } from '@angular/core';
import { rxDone } from '@sap/shared/helpers/rx-helpers';
import { Subject, fromEvent, mapTo, merge, takeUntil } from 'rxjs';

/** THIS IS DIFFRENT VERSION OF STANDARD FOR-SUBMIT.DIRECTIVE
 * This is needed because we have custom component as button, and setting on it type="submit" doesnt work
 */

@Directive({
  standalone: true,
  selector: '[dsFormSubmit]'
})
export class DsFormSubmitDirective implements AfterViewInit, OnDestroy {
  @Output() dsFormSubmit: EventEmitter<void> = new EventEmitter<void>();

  private _isFocused: boolean = false;
  private _destroy$: Subject<void> = new Subject<void>();

  constructor(private _elementRef: ElementRef) {}

  @HostListener('submit', ['$event'])
  public submit(event: Event): void {
    event.preventDefault();
    this.dsFormSubmit.emit(undefined);
  }

  @HostListener('keydown', ['$event'])
  public doOnKeydown(event: KeyboardEvent): void {
    if (this._isFocused && event.code === 'Enter') {
      this.dsFormSubmit.emit(undefined);
    }
  }

  @HostListener('click', ['$event'])
  public doOnClick(event: PointerEvent): void {
    const clickedElement: HTMLElement | undefined = event.target as HTMLElement | undefined;

    const isCustomButtonElement: boolean = Boolean(
      clickedElement?.tagName !== 'button' &&
        clickedElement?.hasAttribute('type') &&
        clickedElement.getAttribute('type') === 'submit'
    );

    if (isCustomButtonElement) {
      this.dsFormSubmit.emit(undefined);
    }
  }

  public ngAfterViewInit(): void {
    for (const input of this._elementRef.nativeElement.children as HTMLInputElement[]) {
      if (input.tagName.toLocaleLowerCase().includes('input')) {
        merge(fromEvent(input, 'focus').pipe(mapTo(true)), fromEvent(input, 'blur').pipe(mapTo(false)))
          .pipe(takeUntil(this._destroy$))
          .subscribe((state: boolean) => (this._isFocused = state));
      }
    }
  }

  public ngOnDestroy(): void {
    rxDone(this._destroy$);
  }
}
