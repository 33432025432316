import { Injectable } from '@angular/core';
import { SportsGroupsLoader } from '@sap/logic/database/sports-groups-loader';
import { mapToUserSettingsFavoriteSportsIds } from '@sap/logic/firestore-database/rx-operators';
import { UserDatabase } from '@sap/logic/firestore-database/user-database';
import { SportsGroup } from '@sap/logic/shared/models/sports-group';
import { combineLatest, from, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, mergeMap, shareReplay, startWith } from 'rxjs/operators';
import { mapToSportGroupsWithUnassignedBrandsFilter } from '../filters/pure-utils';
import { mapToDisciplinesWithUnassignedBrands } from '../impure-utils/to-disciplines-with-unnasigned-brands';
import { mapToObjDisciplinesRegionsAndLeagues } from '../pure-utils/to-obj-disciplines-regions-leagues';
import { mapToObjDisciplinesWithChildren } from '../pure-utils/to-obj-disciplines-with-children';
import { mapSportGroupSortByName, mapToDisciplines } from '../rxjs/sport-groups-operators';

@Injectable({ providedIn: 'root' })
export class SharedSportsGroups {
  public isSportsGroupsLoaded$: ReplaySubject<boolean> = this._sportsGroupsLoader.isLoaded$;
  public lastTimeSportsGroupsLoaded$: ReplaySubject<Date> = this._sportsGroupsLoader.lastTimeLoaded$;
  public didRefreshDB$: Subject<void> = this._sportsGroupsLoader.didRefreshDB$;

  private _disciplinesShareRef$!: Observable<SportsGroup[]>;
  private _disciplinesIdsWithUnassigned$!: Observable<number[]>;
  private _favoriteSportsIds$!: Observable<number[]>;

  constructor(
    private _sportsGroupsLoader: SportsGroupsLoader,
    private _userDatabase: UserDatabase
  ) {}

  public getFavoriteIndexedSportsIds(): Observable<Record<string, boolean>> {
    return this.getFavoriteSportsIds().pipe(
      map((favoriteSportsIds: number[]) => {
        const toReturn: Record<string, boolean> = {};
        favoriteSportsIds.forEach((id: number) => (toReturn[id] = true));
        return toReturn;
      })
    );
  }

  public getFavoriteSportsIds(): Observable<number[]> {
    return (this._favoriteSportsIds$ ??= this._userDatabase
      .getUserSettings()
      .pipe(mapToUserSettingsFavoriteSportsIds()));
  }

  public getUnassignedBrandFilter(): Observable<boolean> {
    return from(this._sportsGroupsLoader.getUnassignedBrandFilter());
  }

  public getIndexesBySportGroupId(): Observable<Record<number, number>> {
    return from(this._sportsGroupsLoader.getIndexesBySportGroupId());
  }

  public getSportsGroups(): Observable<SportsGroup[]> {
    return this._sportsGroupsLoader.didRefreshDB$.pipe(
      startWith(undefined),
      mergeMap(() => this._sportsGroupsLoader.getAll())
    );
  }

  public getDisciplinesSorted(): Observable<SportsGroup[]> {
    return (this._disciplinesShareRef$ ??= this.getSportsGroups().pipe(
      mapSportGroupSortByName,
      mapToDisciplines,
      shareReplay(1)
    ));
  }

  public getDisciplinesIdsWithUnassigned(): Observable<number[]> {
    return (this._disciplinesIdsWithUnassigned$ ??= combineLatest([
      this.getDisciplinesSorted(),
      this.getDisciplinesWithChildren()
    ]).pipe(map(mapToDisciplinesWithUnassignedBrands), shareReplay(1)));
  }

  public getDisciplinesSortedAndUnassigned(): Observable<SportsGroup[]> {
    return combineLatest([
      this.getDisciplinesSorted(),
      this.getDisciplinesIdsWithUnassigned(),
      this.getUnassignedBrandFilter()
    ]).pipe(map(mapToSportGroupsWithUnassignedBrandsFilter));
  }

  public getDisciplinesWithChildren(): Observable<Record<number, SportsGroup[]>> {
    return this.getSportsGroups().pipe(map(mapToObjDisciplinesRegionsAndLeagues), map(mapToObjDisciplinesWithChildren));
  }

  public getByIds(ids: number[]): Observable<SportsGroup[]> {
    return from(this._sportsGroupsLoader.getByIds(ids));
  }
}
