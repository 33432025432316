import { Pipe, PipeTransform } from '@angular/core';
import { NavigationItem } from '../left-navigation-drawer.component';

@Pipe({ name: 'isActiveNavItem', standalone: true })
export class IsActiveNavItemPipe implements PipeTransform {
  public transform(navItem: NavigationItem, currentUrl: string | undefined): boolean {
    if (!currentUrl) {
      return false;
    }
    if (navItem.children) {
      return navItem.children.some((item: NavigationItem) => (item.url ? currentUrl.includes(item.url) : false));
    }

    return currentUrl.includes(navItem.url!);
  }
}
