import { Pipe, PipeTransform } from '@angular/core';
import { BetWithSelections } from '@sap/logic/shared/models/bet';
import { isDefined } from '@sap/logic/shared/utils/pure-utils';
import { toDouble } from '@sap/shared/miscs/utils';
import { OddsFormat } from '../containers/bet-tracer-top-filters/enums';

@Pipe({ name: 'earlyCashoutOddsFormat' })
export class EarlyCashoutOddsFormatPipe implements PipeTransform {
  public transform(
    bet: BetWithSelections | undefined,
    isExpendable: boolean,
    oddsFormat: OddsFormat
  ): string | undefined {
    if (!isDefined(bet)) {
      return undefined;
    }
    if (oddsFormat === OddsFormat.decimal) {
      return toDouble(bet.earlyCashoutOdds).toFixed(2) || '0.00';
    }
    if (oddsFormat === OddsFormat.american) {
      return String(bet.earlyCashoutAmericanOdds);
    }
    if (oddsFormat === OddsFormat.fractional) {
      return String(bet.earlyCashoutFractionalOdds);
    }
    return '0';
  }
}
