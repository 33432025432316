import { subSeconds } from 'date-fns';

export class AuthToken {
  constructor(
    public readonly accessToken: string,
    public readonly refreshToken: string,
    public readonly expiresAt: Date,
    public readonly refreshTokenExpiresAt: Date,
    public readonly brandsScopes?: Record<string, string[]>,
    public readonly allowedModules?: string[]
  ) {}

  public static fromJson(json: Record<string, any>): AuthToken {
    return new AuthToken(
      json['access_token'],
      json['refresh_token'],
      subSeconds(new Date(json['access_token_expires_utc']), 15),
      subSeconds(new Date(json['refresh_token_expires_utc']), 15),
      json['brands_scopes'],
      json['allowed_modules']
    );
  }

  public toPayload(): Record<string, string | Date> {
    return {
      accessToken: this.accessToken,
      refreshToken: this.refreshToken,
      expiresAt: this.expiresAt,
      refreshTokenExpiresAt: this.refreshTokenExpiresAt
    };
  }

  public static fromPayload(payload: Record<string, string | Date>): AuthToken {
    return new AuthToken(
      payload['accessToken'].toString(),
      payload['refreshToken'].toString(),
      new Date(payload['expiresAt']),
      new Date(payload['refreshTokenExpiresAt'])
    );
  }
}
