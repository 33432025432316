import { SortMode } from '@sap/logic/shared/enums';
import { UserDatabaseSettings } from '@sap/logic/shared/models/user-database-settings';
import { sortDataByDate } from '@sap/logic/shared/utils/sort-pure-utils';
import { map, OperatorFunction } from 'rxjs';

export const mapToCommentsSortedByCreated = <T extends { createdAt: Date }>(): OperatorFunction<T[], T[]> =>
  map((comments: T[]) => sortDataByDate(comments, SortMode.desc, 'createdAt'));

export const mapToUserSettings = (): OperatorFunction<Record<string, any>, UserDatabaseSettings> =>
  map(UserDatabaseSettings.fromJson);

export const mapToUserSettingsFavoriteSportsIds = (): OperatorFunction<UserDatabaseSettings, number[]> =>
  map((userSettings: UserDatabaseSettings) => userSettings.favoriteSportsIds);
