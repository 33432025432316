import { Pipe, PipeTransform } from '@angular/core';
import * as R from 'rambdax';

@Pipe({ name: 'arrayIncludesObj', standalone: true })
export class ArrayIncludesObjPipe implements PipeTransform {
  public transform<T>(arr: T[] | undefined, element: T): boolean {
    if (!arr) {
      return false;
    }
    return arr.some((object: T) => R.equals(object, element));
  }
}
