import { Injectable } from '@angular/core';
import { MessageError } from '@sap/logic/api-access/errors/message-error';
import { AuthRepository } from '@sap/logic/api-access/repository/auth-repository';
import { AuthToken } from '@sap/logic/shared/models/auth-tokens';
import { FirestoreDatabase } from '@sap/shared-platform/providers/firestore/firestore-database';
import { Unsubscribable } from '@sap/shared/classes/unsubscribable';
import { zonedTimeToUtc } from '@sap/shared/miscs/utils';
import { isBefore } from 'date-fns';
import { combineLatest, from, Observable, take } from 'rxjs';
import { UserAuthState } from '../enums';
import { AuthState } from './auth-state';
import { AuthTokenStorage } from './auth-token-storage';

@Injectable({ providedIn: 'root' })
export class AuthChecker extends Unsubscribable {
  constructor(
    private _authTokenStorage: AuthTokenStorage,
    private _authState: AuthState,
    private _authRepository: AuthRepository,
    private _firestoreDatabase: FirestoreDatabase
  ) {
    super();
  }

  public init(): void {
    this._checkIfTokenExpires();
    this._signInToFirestore();
  }

  public checkIfNotTokenExpired(): boolean[] {
    const authToken: AuthToken | undefined = this._authTokenStorage.getAuthToken();
    const isNotExpiresToken: boolean = authToken ? isBefore(zonedTimeToUtc(new Date()), authToken.expiresAt) : false;
    const isNotExpiresRefreshToken: boolean = authToken
      ? isBefore(zonedTimeToUtc(new Date()), authToken.refreshTokenExpiresAt)
      : false;
    return [isNotExpiresToken, isNotExpiresRefreshToken];
  }

  public logout(): void {
    this._authState.changeAuthState(UserAuthState.isNotLoggedIn);
  }

  public doRefreshToken(): Observable<AuthToken | MessageError> {
    return from(this._authRepository.authRefresh());
  }

  public doOnRefreshToken(result: AuthToken | MessageError): void {
    if (result instanceof AuthToken) {
      this._authTokenStorage.saveAuthToken(result);
      this._authState.setAuthToken(result);
      this._authState.changeAuthState(UserAuthState.isLoggedIn);
    } else {
      this._authTokenStorage.clearAuthToken();
      this._authState.changeAuthState(UserAuthState.isNotLoggedIn);
    }
  }

  private _checkIfTokenExpires(): void {
    const authToken: AuthToken | undefined = this._authTokenStorage.getAuthToken();
    const [isNotExpiresToken, isNotExpiresRefreshToken]: boolean[] = this.checkIfNotTokenExpired();
    if (isNotExpiresToken) {
      this._authState.setAuthToken(authToken!);
      this._authState.changeAuthState(UserAuthState.isLoggedIn);
      return;
    }
    if (isNotExpiresRefreshToken) {
      this._sub = this.doRefreshToken()
        .pipe(take(1))
        .subscribe((result: AuthToken | MessageError) => this.doOnRefreshToken(result));
      return;
    }
    this.logout();
  }

  private _signInToFirestore(): void {
    this._sub = combineLatest([this._authState.getAuthToken(), this._authState.getUserEmail()]).subscribe(
      ([authToken, userEmail]: [AuthToken | undefined, string]) => {
        if (authToken) {
          return this._firestoreDatabase.signInToFirebaseWithCustomToken(authToken.accessToken, userEmail);
        }
        return;
      }
    );
  }
}
