import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BrandInfo } from '@sap/logic/shared/models/brand-info';
import { Provider } from '@sap/logic/shared/models/provider';
import { SharedSettings } from '@sap/shared-settings/shared-settings';
import { lastValueFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiConfig } from '../api-config';
import { ApiForbiddenDetector } from '../detectors/api-forbidden-detector';

@Injectable({ providedIn: 'root' })
export class InfoApi {
  constructor(
    private _sharedSettings: SharedSettings,
    private _http: HttpClient,
    private _apiConfig: ApiConfig,
    private _apiForbiddenDetector: ApiForbiddenDetector
  ) {}

  public getBrands(): Promise<BrandInfo[]> {
    return lastValueFrom(
      this._http
        .get<Record<string, any>[]>(`${this._sharedSettings.environment.offerMgrUrl}offer-mgr/v4/brands/`)
        .pipe(map(BrandInfo.fromJsonArray), catchError(this._apiForbiddenDetector.detectOnHttpError()))
    );
  }

  public getProviders(): Promise<Provider[]> {
    return lastValueFrom(
      this._http
        .get<Record<string, any>[]>(`${this._sharedSettings.environment.offerMgrUrl}offer-mgr/v4/providers/`)
        .pipe(map(Provider.fromJsonArray), catchError(this._apiForbiddenDetector.detectOnHttpError()))
    );
  }
}
