import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from '@sap-design-system/components/button/button.component';
import { IconComponent } from '@sap-design-system/components/icon/icon.component';
import { TextOverflowComponent } from '@sap/ui/shared/components/text-overflow/text-overflow.component';
import { CopyToClipboardDirective } from '@sap/ui/shared/directives/copy-to-clipboard.directive';
import { DoOnValueChangeDirective } from '@sap/ui/shared/directives/do-on-value-change.directive';
import { SelectChangeDirective, SelectChangeValueDirective } from '@sap/ui/shared/directives/select-change.directive';
import { TooltipDirective } from '@sap/ui/shared/directives/tooltip.directive';
import { FxThemeCssModule } from '@sap/ui/shared/fx-theme-css/fx-theme-css.module';
import { ArrayLengthPipe } from '@sap/ui/shared/pipes/array-length.pipe';
import { ArrayToStringPipe } from '@sap/ui/shared/pipes/array-to-string.pipe';
import { DateObjFormatPipe } from '@sap/ui/shared/pipes/date-obj-format.pipe';
import { DateUtcTitlePipe } from '@sap/ui/shared/pipes/date-utc-title.pipe';
import { CommentCreateModule } from '../../components/bet-tracer-comment/comment-create.module';
import { BetTracerItemComponent } from '../../components/bet-tracer-item.component/bet-tracer-item.component';
import { HiddenColumnsDirective } from '../../directives/column-hidden/column-hidden.directive';
import { ColumnResizeDirective } from '../../directives/column-resize/column-resize.directive';
import { BetForceStatusesOptionsPipe } from '../../pipes/bet-force-statuses-options.pipe';
import { BetKindWithClassPipe } from '../../pipes/bet-kind.pipe';
import { BetOddsFormatPipe } from '../../pipes/bet-odds-format.pipe';

import { BetSportGroupsDataToDisplayPipe } from '../../pipes/bet-sport-gorups-data-to-display.pipe';
import { EarlyCashoutOddsFormatPipe } from '../../pipes/early-cashout-odds-format.pipe';
import { IsSystemBetPipe } from '../../pipes/is-system-bet.pipe';
import { OddsFormatPipe } from '../../pipes/odds-format.pipe';
import { BetTracerMainDataViewComponent } from './bet-tracer-main-data-view.component';
import { StoreScrollDirective } from './store-scroll.directive';
import { TemplateVarTypedDirective } from './template-var-typed.directive';

@NgModule({
  declarations: [
    BetTracerMainDataViewComponent,
    BetTracerItemComponent,
    TemplateVarTypedDirective,
    IsSystemBetPipe,
    ColumnResizeDirective,
    BetOddsFormatPipe,
    EarlyCashoutOddsFormatPipe,
    OddsFormatPipe,
    BetSportGroupsDataToDisplayPipe,
    BetKindWithClassPipe,
    HiddenColumnsDirective,
    BetForceStatusesOptionsPipe
  ],
  exports: [BetTracerMainDataViewComponent],
  imports: [
    CommonModule,
    RouterModule,
    FxThemeCssModule,
    SelectChangeValueDirective,
    DoOnValueChangeDirective,
    TextOverflowComponent,
    ButtonComponent,
    CommentCreateModule,
    IconComponent,
    ArrayToStringPipe,
    DateObjFormatPipe,
    DateUtcTitlePipe,
    SelectChangeDirective,
    CopyToClipboardDirective,
    TooltipDirective,
    ArrayLengthPipe,
    StoreScrollDirective
  ]
})
export class BetTracerMainDataViewModule {}
