import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Directive, ElementRef, HostBinding, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Unsubscribable } from '@sap/shared/classes/unsubscribable';
import { LocalStorageKeys } from '@sap/shared/enums';
import { rxDone } from '@sap/shared/helpers/rx-helpers';
import { LocalStorageService } from '@sap/shared/services/local-storage.service';
import { Observable, Subject, fromEvent } from 'rxjs';
import { distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';

type WidthAndRightFromDOMRect = { width: number; right: number };
type ColumnWidth = {
  index: number | undefined;
  style: Record<string, string> | undefined;
};

function getWidthAndRightFromDOMRect(elementRef: ElementRef<HTMLElement>): WidthAndRightFromDOMRect {
  const { width = 0, right = 0 }: Partial<DOMRect> = elementRef.nativeElement
    .closest('th')
    ?.getBoundingClientRect() || {
    width: 0,
    right: 0
  };
  return { width, right };
}

@Directive({
  selector: '[columnResize]'
})
export class ColumnResizeDirective extends Unsubscribable implements OnInit, OnDestroy {
  @HostBinding('style')
  public style: Record<string, string> | undefined;

  @Input() public maxColWidthInPx: number = 350;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('columnResizeReset') public resetSignal$!: Observable<void>;

  private _destroy$: Subject<void> = new Subject<void>();
  private _updatedColumnIndex: number | undefined;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _elementRef: ElementRef<HTMLElement>,
    private _cdRef: ChangeDetectorRef,
    private _localStorage: LocalStorageService
  ) {
    super();
  }

  public ngOnInit(): void {
    this._updatedColumnIndex = Array.from(this._elementRef.nativeElement.parentElement!.children).findIndex(
      (el: Element) => el === this._elementRef.nativeElement
    );
    this._restoreColumnWidthsFromStorage();
    this._sub = fromEvent<MouseEvent>(this._elementRef.nativeElement, 'mousedown')
      .pipe(
        takeUntil(this._destroy$),
        tap((event: MouseEvent) => event.preventDefault()),
        map(() => getWidthAndRightFromDOMRect(this._elementRef)),
        switchMap(({ width, right }: WidthAndRightFromDOMRect) =>
          fromEvent<MouseEvent>(this._document, 'mousemove').pipe(
            map(({ clientX }: MouseEvent) => width + clientX - right),
            distinctUntilChanged(),
            takeUntil(fromEvent<MouseEvent>(this._document, 'mouseup').pipe(tap(() => this._cdRef.detectChanges())))
          )
        )
      )
      .subscribe((width: number) => {
        if (width <= this.maxColWidthInPx) {
          this.style = {
            minWidth: `${width}px`,
            maxWidth: `${width}px`,
            width: `${width}px`
          };
          this._cdRef.detectChanges();
          this._saveColumnWidthInLocalStorage();
        }
      });

    this.resetSignal$.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this._localStorage.remove(LocalStorageKeys.displayColumnsWidthBetTracer);
      this.style = undefined;
      this._cdRef.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    rxDone(this._destroy$);
  }

  private _restoreColumnWidthsFromStorage(): void {
    const displayedColumnsWidthBetTracerFromLS: string | undefined = this._getDisplayedColumnsWidthBetTracerFromLS();
    if (displayedColumnsWidthBetTracerFromLS) {
      const displayedColumnsWidthBetTracerFromLSArray: ColumnWidth[] = JSON.parse(displayedColumnsWidthBetTracerFromLS);
      const savedColumnWidth: ColumnWidth | undefined = displayedColumnsWidthBetTracerFromLSArray.find(
        (storedColumnWidth: ColumnWidth) => storedColumnWidth.index === this._updatedColumnIndex
      );
      if (savedColumnWidth) {
        this.style = savedColumnWidth.style;
      }
    }
    this._cdRef.detectChanges();
  }

  private _saveColumnWidthInLocalStorage(): void {
    const displayedColumnsWidthBetTracerFromLS: string | undefined = this._getDisplayedColumnsWidthBetTracerFromLS();
    let columnWidthToStore: ColumnWidth[] = [{ index: this._updatedColumnIndex, style: this.style }];

    if (displayedColumnsWidthBetTracerFromLS) {
      let displayedColumnsWidthBetTracerFromLSArray: ColumnWidth[] = JSON.parse(displayedColumnsWidthBetTracerFromLS);
      const alreadyExist: boolean = displayedColumnsWidthBetTracerFromLSArray.some(
        (storedColumnWidth: ColumnWidth) => storedColumnWidth.index === this._updatedColumnIndex
      );
      if (alreadyExist) {
        displayedColumnsWidthBetTracerFromLSArray = displayedColumnsWidthBetTracerFromLSArray.filter(
          (storedColumnWidth: ColumnWidth) => storedColumnWidth.index !== this._updatedColumnIndex
        );
      }
      columnWidthToStore = [...displayedColumnsWidthBetTracerFromLSArray, ...columnWidthToStore];
    }
    this._localStorage.setJson(LocalStorageKeys.displayColumnsWidthBetTracer, JSON.stringify(columnWidthToStore));
  }

  private _getDisplayedColumnsWidthBetTracerFromLS(): string | undefined {
    return this._localStorage.getJson(LocalStorageKeys.displayColumnsWidthBetTracer);
  }
}
