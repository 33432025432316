import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { lazyRenderSource } from '@sap/shared/helpers/lazy-render.helpers';
import { rxDone } from '@sap/shared/helpers/rx-helpers';
import { ReplaySubject, Subject, merge } from 'rxjs';

@Pipe({
  name: 'lazyOptions',
  pure: false,
  standalone: true
})
export class LazyOptionsPipe<T> implements PipeTransform, OnDestroy {
  private _isSubscribed: boolean = false;

  private _options$: ReplaySubject<T[]> = new ReplaySubject<T[]>(1);
  private _destroy$: Subject<void> = new Subject<void>();
  private _reset$: Subject<void> = new Subject<void>();

  private _valueToReturn: T[] = [];
  private _previousFilterBy: string | undefined;

  constructor(private _cdRef: ChangeDetectorRef) {}

  public transform(
    options: T[] | undefined,
    filterBy: string | undefined = '',
    bufferSize?: number,
    limit: number = 8000
  ): T[] | undefined {
    if (typeof options === 'undefined') {
      return options;
    }

    if (this._previousFilterBy !== filterBy) {
      this._isSubscribed = false;
      this._previousFilterBy = filterBy;
      this._reset$.next(undefined);
      this._options$.next(options.slice(0, limit));
    }

    if (!this._isSubscribed) {
      this._isSubscribed = true;
      this._subscribe(bufferSize);
    }

    return this._valueToReturn;
  }

  private _subscribe(bufferSize?: number): void {
    lazyRenderSource(this._options$, merge(this._destroy$, this._reset$), bufferSize).subscribe((options: T[]) => {
      this._valueToReturn = options;
      this._cdRef.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    rxDone(this._destroy$);
  }
}
