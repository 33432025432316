<!-- Time -->
<td>
  <div>
    <sapds-icon
      *ngIf="isExpendable"
      class="icon icon-custom-size u-clickable"
      [class.u-text-warning]="isExpanded"
      [iconName]="isExpanded ? 'chevron-circle-down-alt' : 'chevron-circle-right-alt'"
      (click)="doToggleBetId.emit(betslipId)"
    />
    <span *ngIf="betslip.date as betDate" [title]="betDate | dateUtcTitle">
      {{ betDate | dateObjFormat }}
    </span>
    <span [tooltip]="commentsLength ? tooltipTemplate : undefined">
      <sapds-button
        *ngIf="isCoupon && commentsLength"
        variant="info"
        size="small"
        title="View or add Comments"
        (click)="addComment.emit(betslip.id)"
      >
        <ng-container *ngIf="commentsLength"> Note ({{ commentsLength }}) </ng-container>
      </sapds-button>
    </span>
  </div>
</td>
<ng-template #tooltipTemplate>
  <ng-container *ngFor="let comment of indexedBetComments; let index = index">
    <div class="comment comment_tooltip" [class.mt-2]="index > 0">
      <div class="comment--head mb-2">
        <span class="comment--head__date">{{ comment.createdAt | dateObjFormat }}</span>
        <span class="comment--head__author">{{ comment.author }}</span>
      </div>
      <div class="comment--body">{{ comment.message }}</div>
    </div>
  </ng-container>
</ng-template>
<!-- Device -->
<td>
  <ng-container *ngIf="betslip.device; else noDataTemplate">
    <span class="u-copyable" [copyToClipboard]="betslip.device">{{ betslip.device }}</span>
  </ng-container>
</td>
<!-- Client ID -->
<td>
  <span class="u-copyable" [copyToClipboard]="betslip.customerId">{{ betslip.customerId }}</span>
</td>
<!-- Customer -->
<td>
  <a
    class="custom-link"
    [title]="
      betslip.classification === 'terminal' || betslip.device === 'terminal' ? betslip.location : betslip.customer
    "
    [attr.data-title]="
      betslip.classification === 'terminal' || betslip.device === 'terminal' ? betslip.location : betslip.customer
    "
    [routerLink]="['/customer-details']"
    [queryParams]="{ customerDetailsId: betslip.customerId, brand: betslip.brand }"
    >{{
      betslip.classification === 'terminal' || betslip.device === 'terminal' ? betslip.location : betslip.customer
    }}</a
  >
</td>
<!-- Brand -->
<td>
  <span class="u-copyable" [copyToClipboard]="betslip.brand">{{ betslip.brand }}</span>
</td>
<!-- Class -->
<td>
  <span
    *ngIf="ClassificationValuesReadable[betslip.classification] || betslip.classification as classValue"
    class="u-copyable"
    [copyToClipboard]="classValue"
    >{{ classValue }}
  </span>
</td>
<!-- Event or betslip type -->
<td>
  <ng-container *ngIf="!isExpendable; else betslipTypeTemplate">
    <div>
      <span class="u-copyable" [copyToClipboard]="bet.matchName" [title]="bet.matchName"> {{ bet.matchName }}</span>
      <span
        *ngIf="indexedMatchComments[bet.matchId]"
        class="comments-icon u-info"
        [tooltip]="indexedMatchComments[bet.matchId] ? tooltipTemplate : undefined"
      >
        <span>i</span>
      </span>
    </div>
    <ng-template #tooltipTemplate>
      <ng-container *ngFor="let comment of indexedMatchComments[bet.matchId]; let index = index">
        <div class="comment comment_tooltip" [class.mt-2]="index > 0">
          <div class="comment--head mb-2">
            <span class="comment--head__date">{{ comment.createdAt | dateObjFormat }}</span>
            <span class="comment--head__author">{{ comment.author }}</span>
          </div>
          <div class="comment--body">{{ comment.message }}</div>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
</td>

<!-- Starting -->
<td>
  <ng-container *ngIf="!isExpendable; else noDataTemplate">
    <div class="u-copyable" [copyToClipboard]="bet.matchDate | dateObjFormat" [title]="bet.matchDate | dateUtcTitle">
      {{ bet.matchDate | dateObjFormat }}
    </div>
  </ng-container>
</td>
<!-- Sport / Country / League -->
<td>
  <ng-container *ngIf="!isExpendable; else noDataTemplate">
    <div class="u-copyable" *ngIf="bet | betSportGroupsDataToDisplay as dataToDisplay">
      <span *ngIf="dataToDisplay.region" [copyToClipboard]="dataToDisplay.discipline" [title]="dataToDisplay.tooltip">
        {{ dataToDisplay.discipline }}
      </span>
      <span *ngIf="dataToDisplay.region" [copyToClipboard]="dataToDisplay.region" [title]="dataToDisplay.tooltip">
        / {{ dataToDisplay.region }}
      </span>
      <span
        *ngIf="dataToDisplay.competition"
        [copyToClipboard]="dataToDisplay.competition"
        [title]="dataToDisplay.tooltip"
      >
        / {{ dataToDisplay.competition }}
      </span>
    </div>
  </ng-container>
</td>
<!-- Coupon ID -->
<td>
  <span class="u-copyable" [copyToClipboard]="betslip.id">{{ betslip.id }}</span>
  <span *ngIf="bet" class="u-copyable" [copyToClipboard]="bet.id">#{{ bet.id }}</span>
  <span *ngIf="bet && bet.virtualId" class="u-copyable" [copyToClipboard]="bet.virtualId"
    >(virt: {{ bet.virtualId }} )</span
  >
</td>
<!-- Event ID/ Market ID -->
<td>
  <ng-container *ngIf="!isExpendable; else noDataTemplate">
    <div class="u-copyable" [copyToClipboard]="bet.matchId">{{ bet.matchId }}</div>
    <div *ngIf="bet.marketId as marketId" class="u-copyable" [copyToClipboard]="marketId">{{ marketId }}</div>
  </ng-container>
</td>
<!-- Selection -->
<td>
  <ng-container *ngIf="!isExpendable; else noDataTemplate">
    <div
      class="u-copyable"
      *ngIf="[bet.marketName, bet.selectionName, bet.selectionId] | arrayToString: ' / ' as tooltip"
    >
      <span [copyToClipboard]="bet.marketName" [title]="tooltip">{{ bet.marketName }}</span>
      <span [copyToClipboard]="bet.selectionName" [title]="tooltip"> / {{ bet.selectionName }}</span>
      <span [copyToClipboard]="bet.selectionId" [title]="tooltip"> / {{ bet.selectionId }}</span>
    </div>
  </ng-container>
</td>
<!-- Odds -->
<td>
  <ng-container *ngIf="bet | isSystemBet: isExpendable; else betOddsTemplate">
    <ng-container *ngIf="betslip.risk / betslip.stake as systemCalc; else noDataTemplate">
      {{ systemCalc + 1 | oddsFormat: oddsFormat }}
    </ng-container>
  </ng-container>
</td>
<!-- Stake / Stake [€] -->
<td>
  <div class="flex">
    <ng-container
      [ngTemplateOutlet]="stakeTemplate"
      [ngTemplateOutletContext]="{ templateItem: (bet | isSystemBet: isExpendable) ? betslip : bet }"
    >
    </ng-container>
  </div>
</td>
<!-- Risk -->
<td>
  <ng-container
    [ngTemplateOutlet]="riskTemplate"
    [ngTemplateOutletContext]="{ templateItem: (bet | isSystemBet: isExpendable) ? betslip : bet }"
  >
  </ng-container>
</td>
<!-- Payout / Payout [€] -->
<td>
  <ng-container
    [ngTemplateOutlet]="payoutTemplate"
    [ngTemplateOutletContext]="{ templateItem: (bet | isSystemBet: isExpendable) ? betslip : bet }"
  >
  </ng-container>
</td>
<!-- Type -->
<td>
  <ng-container *ngIf="bet">
    <span
      *ngIf="bet | betKindWithClass: ['badge_red', 'badge_blue', 'badge_yellow'] : isExpendable as betType"
      class="badge px-2 py-1 rounded-pill"
      [class]="betType.class"
    >
      {{ betType.text }}
    </span>
  </ng-container>
</td>
<!-- Paid -->
<td>{{ betslip.paid ? 'Yes' : 'No' }}</td>
<!-- Bonus info-->
<td>
  <ng-container [ngSwitch]="betslip.bonusInfo?.type">
    <ng-container
      *ngSwitchCase="'multiplier'"
      [ngTemplateOutlet]="multiplierBonusTemplate"
      [ngTemplateOutletContext]="{ bonusInfo: betslip.bonusInfo }"
    >
    </ng-container>
    <ng-container
      *ngSwitchCase="'rateBoost'"
      [ngTemplateOutlet]="rateBoostBonusTemplate"
      [ngTemplateOutletContext]="{ bonusInfo: betslip.bonusInfo }"
    >
    </ng-container>
    <ng-container
      *ngSwitchCase="'lowerTax'"
      [ngTemplateOutlet]="lowerTaxBonusTemplate"
      [ngTemplateOutletContext]="{ bonusInfo: betslip.bonusInfo }"
    >
    </ng-container>
    <ng-container *ngSwitchDefault [ngTemplateOutlet]="noDataTemplate"> </ng-container>
  </ng-container>
</td>
<!-- Bet Status -->
<td>
  <span
    *ngIf="isExpendable ? betslip.status : bet.status as betStatusValue"
    class="u-copyable"
    [copyToClipboard]="betStatusValue"
    >{{ betStatusValue }}</span
  >
</td>
<!-- Selection Status -->
<td>
  <ng-container *ngIf="!isExpendable; else noDataTemplate">
    <span
      *ngIf="bet.betItemStatus || bet.selectionStatus as betItemStatusValue"
      class="u-copyable"
      [copyToClipboard]="betItemStatusValue"
      >{{ betItemStatusValue }}</span
    >
  </ng-container>
</td>
<!-- Status Update -->
<td class="u-copyable">
  <span [copyToClipboard]="betslip.statusUpdated | dateObjFormat" [title]="betslip.statusUpdated | dateUtcTitle">
    {{ betslip.statusUpdated | dateObjFormat }}
  </span>
  <sapds-button
    *ngIf="isCoupon && betslip.isClosed && betslip.bonusInfo?.hasBetMode"
    class="text-uppercase"
    variant="warning"
    size="small"
    title="Resend status"
    (click)="doRefreshStatus.emit([betslip.id, betslip.brand])"
  >
    resend
  </sapds-button>
</td>
<!-- Force / Invalid Status -->
<td>
  <select
    class="form-select form-select-sm u-disabled"
    [watchSelectValueChange]="false"
    [enableInitialEmitting]="false"
    (selectChange)="(undefined)"
  >
    <option [selectChangeValue]="undefined" hidden>Action to do</option>
    <!-- <ng-container
      *ngFor="let status of isExpendable ? (betslip | betslipForceStatusesOptions) : (bet | betForceStatusesOptions)"
    >
      <option [selectChangeValue]="status.id">{{ status.name }}</option>
    </ng-container> -->
  </select>
</td>
<!-- Event / Resulting Manager / Actions -->
<td class="btn-position">
  <ng-container *ngIf="!isExpendable && bet.matchId">
    <div class="d-flex gap-1 mb-1 custom-links">
      <a
        class="button button_success button_small"
        title="Market view"
        [attr.data-title]="bet.matchName"
        [routerLink]="['/event-view', bet.matchId]"
        [queryParams]="{ marketId: bet.marketId, tabTitle: bet.matchName }"
        >MV</a
      >

      <a
        class="button button_success button_small"
        title="Event view"
        [attr.data-title]="bet.matchName"
        [routerLink]="['/event-view', bet.matchId]"
        [queryParams]="{ tabTitle: bet.matchName }"
        >EV</a
      >
      <sapds-button
        *ngIf="isCoupon && !commentsLength"
        variant="info"
        size="small"
        iconName="plus"
        title="Add comment"
        (click)="addComment.emit(betslip.id)"
      >
      </sapds-button>
    </div>
    <div class="d-flex gap-1 mb-1 custom-links">
      <a
        class="button button_warning button_small"
        title="Resulting manager - Event"
        [attr.data-title]="bet.matchName"
        [routerLink]="['/resulting-manager', bet.matchId]"
        [queryParams]="{ tabTitle: bet.matchName }"
      >
        RE
      </a>

      <a
        class="button button_warning button_small"
        title="Resulting manager - Market"
        [attr.data-title]="bet.matchName"
        [routerLink]="['/resulting-manager', bet.matchId]"
        [queryParams]="{ marketId: bet.marketId, tabTitle: bet.matchName }"
      >
        RM
      </a>
      <a
        class="button button_warning button_small"
        title="Resulting manager - Selection"
        [attr.data-title]="bet.matchName"
        [routerLink]="['/resulting-manager', bet.matchId]"
        [queryParams]="{ selectionId: bet.selectionId, tabTitle: bet.matchName }"
      >
        RS
      </a>
    </div>

    <!-- <a
    class="badge bg-warning u-disabled custom-link"
    title="TODO: Put request not avaiable for now"
  >MTB</a>
  <a
    class="badge bg-caution u-disabled u-text-white"
    title="TODO: Put request not avaiable for now"
  >IA</a> -->
  </ng-container>
  <ng-container *ngIf="isExpendable">
    <sapds-button
      *ngIf="isCoupon && !commentsLength"
      variant="info"
      size="small"
      iconName="plus"
      title="Add comment"
      (click)="addComment.emit(betslip.id)"
    >
    </sapds-button>
  </ng-container>
</td>

<!-- templates -->
<ng-template #noDataTemplate> - </ng-template>

<ng-template #betOddsTemplate>
  {{ bet | betOddsFormat: isExpendable : oddsFormat }}
  <span *ngIf="bet.earlyCashoutOdds" class="u-text-no-wrap">
    / <br />
    EC: {{ bet | earlyCashoutOddsFormat: isExpendable : oddsFormat }}
  </span>
</ng-template>

<ng-template #stakeTemplate let-templateItem="templateItem">
  <div>
    <div class="u-copyable" [copyToClipboard]="templateItem.stakeCurrency">
      {{ templateItem.stakeCurrency }} {{ templateItem.currency }} /
    </div>
    <div class="u-copyable" [copyToClipboard]="templateItem.stake">{{ templateItem.stake }} €</div>
  </div>
</ng-template>

<ng-template #riskTemplate let-templateItem="templateItem">
  <div>
    {{ templateItem.risk }}
  </div>
</ng-template>

<ng-template #payoutTemplate let-templateItem="templateItem">
  <div
    *ngIf="bet ? bet.status : betslip.status as betStatus"
    class="stake-badge"
    [class.badge_blue]="betStatus === 'return' || betStatus === 'void'"
    [class.badge_green]="betStatus === 'paid' || betStatus === 'won'"
    [class.badge_red]="betStatus === 'lost'"
  >
    <span *ngIf="templateItem.paid === false">0</span>
    <div *ngIf="templateItem.paid !== false">
      <div class="u-copyable" [copyToClipboard]="templateItem.payoutCurrency">
        {{ templateItem.payoutCurrency }} {{ templateItem.currency }} /
      </div>
      <div class="u-copyable" [copyToClipboard]="templateItem.payout">{{ templateItem.payout }} €</div>
    </div>
  </div>
</ng-template>

<ng-template #betslipTypeTemplate>
  <span
    *ngIf="bet ? bet.type : betslip.type as betType"
    [class.badge_yellow]="betType === BetType.combi"
    [class.badge_green]="betType === BetType.system"
    class="badge rounded-pill text-uppercase u-text-white"
    (click)="doToggleBetId.emit(betslipId)"
    >{{ betType }}</span
  >
</ng-template>

<ng-template #multiplierBonusTemplate let-bonusInfo="bonusInfo">
  <div
    *ngIf="
      [bonusInfo.code, 'x', bonusInfo.winMultiplier, bonusInfo.category ? 'Category: ' + bonusInfo.category : undefined]
        | arrayToString: ' ' as tooltip
    "
  >
    <span class="u-info" [tooltip]="tooltip">{{ bonusInfo.code }}</span>
    x {{ bonusInfo.winMultiplier }}
    <br />
    <ng-container
      *ngIf="bonusInfo.category"
      [ngTemplateOutlet]="categoryBonusTemplate"
      [ngTemplateOutletContext]="{ category: bonusInfo.category }"
    >
    </ng-container>
  </div>
</ng-template>
<ng-template #rateBoostBonusTemplate let-bonusInfo="bonusInfo">
  <div
    *ngIf="
      ['Rate boost:', bonusInfo.rateBoostValue, bonusInfo.category ? 'Category: ' + bonusInfo.category : undefined]
        | arrayToString: ' ' as tooltip
    "
  >
    <span class="u-info" [tooltip]="tooltip">
      {{ tooltip }} <br />
      <ng-container
        *ngIf="bonusInfo.category"
        [ngTemplateOutlet]="categoryBonusTemplate"
        [ngTemplateOutletContext]="{ category: bonusInfo.category }"
      >
      </ng-container>
    </span>
  </div>
</ng-template>
<ng-template #lowerTaxBonusTemplate let-bonusInfo="bonusInfo">
  <div
    *ngIf="
      [
        'Lower tax:',
        (bonusInfo.lowerTaxValue | percent)?.toString(),
        bonusInfo.category ? 'Category: ' + bonusInfo.category : undefined
      ] | arrayToString: ' ' as tooltip
    "
  >
    <span class="u-info" [tooltip]="tooltip">
      {{ tooltip }} <br />
      <ng-container
        *ngIf="bonusInfo.category"
        [ngTemplateOutlet]="categoryBonusTemplate"
        [ngTemplateOutletContext]="{ category: bonusInfo.category }"
      >
      </ng-container>
    </span>
  </div>
</ng-template>
<ng-template #categoryBonusTemplate let-category="category">
  <span>Category: {{ category }}</span>
</ng-template>
