import { Injectable } from '@angular/core';
import { BetInternalComment } from '@sap/logic/shared/models/bet-internal-comment';
import { FirestoreDatabase } from '@sap/shared-platform/providers/firestore/firestore-database';
import { from, map, mergeMap, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { mapToBetInternalComment } from './pure-utils';
import { mapToCommentsSortedByCreated } from './rx-operators';
import { IndexedBetComments } from './types';

@Injectable()
export class BetInternalCommentDatabase {
  private _didUpdatedCollection$: Subject<void> = new Subject();

  constructor(private _firestoreDatabase: FirestoreDatabase) {}

  public getIndexedBetComments(): Observable<IndexedBetComments> {
    return this._didUpdatedCollection$.pipe(
      startWith(undefined),
      mergeMap(() =>
        from(this._firestoreDatabase.getCollectionWithArrayField('bets', 'comments')).pipe(map(mapToBetInternalComment))
      )
    );
  }

  public getBetComments(matchId: string): Observable<BetInternalComment[]> {
    return this._firestoreDatabase
      .getIsUserSigned()
      .pipe(
        mergeMap(() =>
          this._firestoreDatabase
            .getArrayData('bets', 'comments', matchId)
            .pipe(
              takeUntil(this._firestoreDatabase.userSignOutSignal()),
              map(BetInternalComment.fromJsonArray),
              mapToCommentsSortedByCreated()
            )
        )
      );
  }

  public addComment(betId: number, comment: string, userEmail: string): void {
    this._firestoreDatabase
      .setDataInArray('bets', betId.toString(), 'comments', {
        date: new Date().getTime(),
        message: comment,
        user: userEmail
      })
      .then(() => this._didUpdatedCollection$.next(undefined));
  }
}
