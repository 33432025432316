import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconComponent } from '@sap-design-system/components/icon/icon.component';
import { ClickOutsideDirective } from '../directives/click-outside.directive';
import { KeyupTargetValueDirective } from '../directives/inputs/keyup-target-value.directive';
import { FxThemeCssModule } from '../fx-theme-css/fx-theme-css.module';
import { ArrayIncludesObjPipe } from '../pipes/array-includes-obj.pipe';
import { MultiSelectComponent } from './multi-select.component';
import { SearchSelectComponent } from './search-select.component';
import { LazyOptionsPipe } from './shared/lazy-options.pipe';
import { OptionTitlePie } from './shared/option-title.pipe';
import { RenderVisibleDirective } from './shared/render-visible-in-viewport.directive';
import { SimpleSelectComponent } from './simple-select.component';

@NgModule({
  declarations: [
    MultiSelectComponent,
    SearchSelectComponent,
    SimpleSelectComponent,
    RenderVisibleDirective,
    OptionTitlePie
  ],
  exports: [MultiSelectComponent, SearchSelectComponent, SimpleSelectComponent],
  imports: [
    CommonModule,
    KeyupTargetValueDirective,
    FxThemeCssModule,
    LazyOptionsPipe,
    IconComponent,
    ArrayIncludesObjPipe,
    ClickOutsideDirective
  ]
})
export class CoreuiComponentsModule {}
