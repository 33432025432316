import { SessionStorageKeys } from '@sap/shared/enums';
import { SessionStorageService } from '@sap/shared/services/session-storage.service';

type InputConfig = {
  sessionStorage: SessionStorageService;
  storageKey: SessionStorageKeys;
};

export function doSaveExpandedBetsToStorage(config: InputConfig) {
  return (expandedRows: Record<number, boolean>): void => {
    config.sessionStorage.setJson(config.storageKey, expandedRows);
  };
}
