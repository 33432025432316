import * as R from 'rambdax';

type RankResult = { rankMatching: number };

export function searchAndSortByRankMatching<T>(data: T[], propString: string, searchPhrase: string): T[] {
  return R.piped(
    data,
    R.reduce((acc: (T & RankResult)[], value: T) => {
      const hasSearchValue: boolean = searchPhrase
        .split(' ')
        .every((searchKey: string) => value[propString].toLowerCase().includes(searchKey));
      if (hasSearchValue) {
        let rankMatching: number = 0;
        if (value[propString] === searchPhrase) {
          rankMatching += 2;
        }
        if (value[propString].toLowerCase().startsWith(searchPhrase)) {
          rankMatching += 1;
        }
        value = { ...value, rankMatching };
        acc.push(value as T & RankResult);
      }
      return acc;
    }, []),
    R.sort((a: T & RankResult, b: T & RankResult) => b.rankMatching - a.rankMatching)
  );
}
